<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
            <div class="footer-text">
              <router-link to="/provider-list" target="_blank">
                <div>
                  <h2>Need a Dentist? Great! We have a few</h2>
                  <p>Our plans are accepted by more than <strong>75%</strong> of dentist nationwide.</p>
                </div>
                <div class="arrow-icon">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </router-link>
            </div>
          </div>
      </div>
    </div>
    <div v-if="siteUrl === 'true'">
      <QuestionCall :phone="phone" />
    </div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-xxl-6 col-xl-6 col-lg-7 col-md-8 col-12 mb-4">
            <div class="foot-logo mb-3">
              <img src="../assets/images/foot-logo.png" alt="" />
            </div>
            <p v-if="siteUrl === 'false'">
              Access to the insurance plans and large group pricing on this
              website is predicted upon joining Elevate Wellness, a national
              association with over 100,000 members
            </p>
            <ul>
              <li>
                Phone: <a href="javascript:void(0)">(929) 999-8535</a>
              </li>
              <li>
                Email: <a href="javascript:void(0)">info@smartdentalvision.com</a>
              </li>
            </ul>
            <p>Do you want to work with us? <br>
              <a class="ticket-btn my-2" target="_blank" @click="openModel()">Connect with US</a> <br>For more information about becoming a Rep for NuEra Benefits.</p>
            <br>
            <p>Register to become a Representative <br>
              <a class="ticket-btn my-2" target="_blank" v-bind:href="registrationLink">Register Now</a></p>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-5 col-md-4 col-12 mb-4">
            <p>Download The Member App</p>
            <div class="app-icon">
              <a v-bind:href="iosLink" target="_blank"
                ><img :src="iosImgLink" alt="iOS App" class="img-fluid"
              /></a>
              <a
                v-bind:href="androidLink"
                v-if="googlePlay == true"
                target="_blank"
                ><img :src="androidImgLink" alt="Android App" class="img-fluid"
              /></a>
            </div>
            <p>Click the Appropriate App</p>
            <p>
              For assistance or to report an issue, please submit a support request:
            </p>
            <a v-bind:href="ticket" class="ticket-btn" target="_blank"
              >Submit Support Request</a
            >
          </div>
          <div class="col-xxl-12">
            <p>Copyright &copy; {{getCurrentYear()}} | All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
    <!-- Access Code Pop up -->
    <b-modal
      id="accessCode-pop"
      centered
      :size="showRepConformation ? 'lg' : 'md'"
      v-model="accssShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4 v-if="!showRepConformation">Access Code</h4>
          <b-button variant="close-btn" @click="accssShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showRepConformation">
          <div class="form-group mb-3">
            <label>Rep Access Code</label>
            <input
              type="text"
              v-model="repAccessCode"
              @input="removeGroupErrorMessage()"
              :class="{
                'form-control is-invalid':
                  $v.repAccessCode.$error || repErrorFlag,
              }"
              class="custom-input"
              placeholder="Enter Rep Access Code Eg: ABCD1212"
            />
            <div
              class="form-group__message text-left ml-3 mt-2"
              v-if="!$v.repAccessCode.required && $v.repAccessCode.$error"
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="repErrorFlag === true">
              Invalid Rep Access Code
            </div>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkRepEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showRepConformation">
          <div class="text-left mb-3">
            <div class="rep-confirm-header">
              <i class="fa fa-unlock-alt"></i
              ><strong class="ms-2"
                >Please confirm that you are accessing the site with the correct
                representative access code.</strong
              >
            </div>
            <div class="mt-4">
              <strong>{{ repInfo.first_name }} {{ repInfo.last_name }}</strong>
            </div>
            <div>{{ repInfo.agent_code }}</div>
            <div>{{ repInfo.email }}</div>
            <div class="mb-4">{{ repInfo.phone }}</div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToRepEnroll">Yes</button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetRepEnroll">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <!-- Employee Enrollment pop up -->
    <b-modal
      id="employeeEnrollment-pop"
      centered
      size="md"
      v-model="enrollmentShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4>Employee Enrollment</h4>
          <b-button variant="close-btn" @click="enrollmentShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showProceedConformation">
          <div class="form-group mb-3">
            <label>Group Enrollment Code</label>
            <input
              type="text"
              v-model="groupEnrollmentCode"
              @input="removeGroupErrorMessage()"
              :class="{
                'form-control is-invalid':
                  $v.groupEnrollmentCode.$error || groupErrorFlag,
              }"
              class="custom-input"
              placeholder="Enter Group Enrollment CodeEg: ABCD1212"
            />
            <div
              class="form-group__message text-left ml-3 mt-2"
              v-if="
                !$v.groupEnrollmentCode.required &&
                $v.groupEnrollmentCode.$error
              "
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="groupErrorFlag === true">
              Invalid Group Enrollment Code
            </div>
          </div>
          <div class="form-group semiText">
            <p>
              If you don't know your group enrollment code please contact your
              company's human resource department.
            </p>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkGroupEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showProceedConformation">
          <div class="text-left mb-3">
            <div class="mt-2">
              The company that you will be enrolling under is:<strong
                style="color: #28a745"
              >
                {{ groupInfo.name }}</strong
              >
            </div>
            <div
              class="mt-2 group-not-employer-container"
              @click="backToEnrollmentCode"
            >
              <p>
                If this is NOT the your employer please click here, to re-enter
                your employer enrollment code.
              </p>
              <p>
                If you have questions please contact your Employer for correct
                code.
              </p>
            </div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToGroupEnroll">
                  Yes
                </button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetEnrollGroup">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <!-- Video Goenroll pop up -->
    <b-modal id="howToEnrollVideo" centered size="lg" v-model="show">
      <template #modal-header>
        <div class="popUp-header">
          <b-button variant="close-btn" @click="show = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="videoPlay">
          <video
            controls
            autoplay
            width="100%"
            height="350"
            title="Goenroll123"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
            <source :src="videoLink" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </b-container>
    </b-modal>

    <!-- SignUp pop up -->
    <b-modal id="footer-sign-up" centered size="md" v-model="signup">
      <template #modal-header>
        <div class="popUp-header">
          <h2>Sign Up</h2>
          <b-button variant="close-btn" @click="signup = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div>
          <div class="form-group mb-3">
            <label>Full Name <span class="required">*</span></label>
            <input type="text" class="custom-input"
                   placeholder="Enter Full Name"
                   id="fname"
                   @input="$v.connectWithUsInfo.name.$touch(); removeError();"
                   :class="{'form-control is-invalid':$v.connectWithUsInfo.name.$error || formErrors['name_message']}"
                   v-model="connectWithUsInfo.name" value="">
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.connectWithUsInfo.name.required && $v.connectWithUsInfo.name.$error">
              Field is required
            </div>
            <div class="form-group__message  ml-3 mt-2" v-if="$v.connectWithUsInfo.name.required && $v.connectWithUsInfo.name.$error">
              Enter valid name
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['name_message']}}</div>
          </div>
          <div class="form-group mb-3">
            <label>State <span class="required">*</span></label>
            <b-form-select name=""
                           class="custom-input"
                           @input="$v.selectedState.$touch(); removeError();"
                           :class="{'form-control is-invalid':$v.selectedState.$error || formErrors['state_message']}"
                           v-model="selectedState"
                           id="">
              <option value="null" disabled>Select State</option>
              <option v-for="state in states" :value="state.abbrev" :key="state.abbrev">{{state.name}} ({{state.abbrev}})</option>
            </b-form-select>
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.selectedState.required && $v.selectedState.$error">
              Field is required
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['state_message']}}</div>
          </div>
          <div class="form-group mb-3">
            <label>Phone Number <span class="required">*</span></label>
            <input type="text" class="custom-input"
                   placeholder="Enter phone Number"
                   @input="$v.connectWithUsInfo.phone.$touch(); removeError();"
                   :class="{'form-control is-invalid':$v.connectWithUsInfo.phone.$error || formErrors['phone_message'] }"
                   v-model="connectWithUsInfo.phone"
                   value=""
                   maxlength="10" ref="phone" >
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.connectWithUsInfo.phone.required && $v.connectWithUsInfo.phone.$error">
              Field is required
            </div>
            <div class="form-group__message text-left ml-3 mt-2" v-if="$v.connectWithUsInfo.phone.required && $v.connectWithUsInfo.phone.$invalid">
              Invalid phone number
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['phone_message']}}</div>
          </div>
          <div class="form-group mb-3">
            <label>Email <span class="required">*</span></label>
            <input type="text"
                   class="custom-input"
                   placeholder="Enter Email"
                   :class="{'form-control is-invalid': $v.connectWithUsInfo.email.$error || formErrors['email_message']}"
                   @input="$v.connectWithUsInfo.email.$touch(); removeError();"
                   v-model="connectWithUsInfo.email"
                   value="" />
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.connectWithUsInfo.email.required && $v.connectWithUsInfo.email.$error">
              Field is required
            </div>
            <div class="form-group__message  ml-3 mt-2" v-if="$v.connectWithUsInfo.email.required && $v.connectWithUsInfo.email.$invalid">
              Enter valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="form-group mb-3">
            <button class="univ-btn" @click="submitApi()">Sign Up</button>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import QuestionCall from "./QuestionCall.vue";
import { required, email } from "vuelidate/lib/validators";
import {validName, checkValidPhoneNumber} from "../utils/validators";
export default {
  name: "SiteFooter",
  components: {
    QuestionCall,
  },
  data () {
    return {
      phone: "Loading..",
      email: "Loading..",
      enrollmentShow: false,
      accssShow: false,
      ticket: "",
      registrationLink: "https://reps.purenroll.com/registration?repCode=5BHNJ457",
      footerIcon: "",
      iosImgLink:
        "https://benefit-store-assets.s3.us-east-2.amazonaws.com/ios_icon.png",
      androidImgLink:
        "https://benefit-store-assets.s3.us-east-2.amazonaws.com/google_icon.png",
      iosLink: "https://apps.apple.com/us/app/corenroll-member/id1539841827",
      androidLink:
        "https://play.google.com/store/apps/details?id=com.neura.corenroll_member&hl=en&gl=US",
      // ios: 'https://apps.apple.com/us/app/corenroll-reps/id1527267392',
      // android: 'https://play.google.com/store/apps/details?id=com.neura.corenroll',
      open_type: "",
      group_id: "",
      agent_id: "",
      googlePlay: true,
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      showProceedConformation: false,
      groupEnrollmentCode: null,
      groupRouteName: null,
      groupErrorFlag: false,
      showRepConformation: false,
      repAccessCode: null,
      repRouteName: null,
      repErrorFlag: false,
      routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
      repInfo: {
        firstName: "",
        lastName: "",
      },
      groupInfo: {
        name: "",
      },
      menuLink: {
        routeToAdmin: process.env.VUE_APP_ADMIN,
        routeToMessageCenter: process.env.VUE_APP_MESSAGE_CENTER,
        routeToFileManagement: process.env.VUE_APP_FILE_MANAGEMENT,
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToGroupDashboard: process.env.VUE_APP_GROUP_DASHBOARD,
        routeToTicketManagement: process.env.VUE_APP_TICKET_MANAGEMENT,
        routeToSos: process.env.VUE_APP_SOS,
        routeToRepRegistration: process.env.VUE_APP_REP_REGISTRATION,
        routeToMemberRegistration: process.env.VUE_APP_MEMEBER_REGISTRATION,
        routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      },
      show: false,
      videoLink: "",
      modelId: "",
      signup: false,
      formErrors: [],
      formErrorFlag: false,
      connectWithUsInfo: {
        name: null,
        phone: null,
        email: null
      },
      selectedState: null,
      states: []
    };
  },
  validations: {
    connectWithUsInfo: {
      name: {
        required,
        validName
      },
      phone: {
        required,
        checkValidPhoneNumber
      },
      email: {
        required,
        email
      }
    },
    selectedState: {
      required
    },
    repAccessCode: {
      required,
    },
    groupEnrollmentCode: {
      required,
    },
  },
  mounted() {
    let app = this;
    app.group_id = window.localStorage.getItem("group_id");
    app.agent_id = window.localStorage.getItem("agent_id");
    app.open_type = window.localStorage.getItem("open_type");
    app.eprocess = window.localStorage.getItem("eprocess");
    if (app.open_type === "group" || app.eprocess === "group") {
      app.getGroupHeaderInfo();
    } else {
      //open landing pages
      app.getAgentHeaderInfo();
    }
    if (
      app.$device.ios ||
      app.$device.iphone ||
      app.$device.iphoneX ||
      app.$device.iPhoneXR ||
      app.$device.iPhoneXSMax ||
      app.$device.ipod ||
      app.$device.ipad ||
      navigator.platform.indexOf("Mac") > -1
    ) {
      app.googlePlay = false;
    }
    app.$root.$on("infoLanding", (info) => {
      app.phone = info.phone;
      app.email = info.email;
      app.getTicketLink();
    });
    app.fetchCatchInfo();
    if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
      app.getTicketLink();
    }
    app.getStates();
  },
  methods: {
    submitApi () {
      let app = this
      app.$v.connectWithUsInfo.$touch();
      app.$v.selectedState.$touch();
      if (app.$v.connectWithUsInfo.$invalid || app.$v.selectedState.$invalid) {
        return false
      } else {
         let params = app.connectWithUsInfo
         params['state'] = app.selectedState
          axios.post(`${process.env.VUE_APP_PLAN_COVERAGE_API_V2_QA}/agent-registration-query`, params)
            .then ((response) => {
              if (response.data.status == 'success') {
                app.signup = false
                app.connectWithUsInfo = {}
                app.selectedState = null
                app.$notify({
                  title: response.data.message,
                  icon: 'ti-alert',
                  horizontalAlign: 'right',
                  verticalAlign: 'top',
                  type: 'success'
                })
              }
          }).catch(function (error) {
            console.log('error', error.response);
            console.log('error', error.response.status);
            if (error.response.status == 422) {
              error.response.data.data.forEach(function (item) {
                app.formErrorFlag = true;
                app.formErrors = []
                app.formErrors[item.target_element + '_message'] = item.error_message;
                console.log(app.formErrors[item.target_element + '_message']);
              });
            }
            app.$notify({
              title: error.response.data.message,
              icon: 'ti-alert',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'error'
            })
          });
      }
    },
    openModel () {
      let app = this
      app.signup = true
      app.formErrorFlag = false
      app.formErrors = []
      app.connectWithUsInfo = {}
      app.selectedState = null
      app.$v.$reset()
    },
    getTicketLink: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-ticket-link")
        .then(function (response) {
          app.ticket = response.data.data["ticket"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAgentHeaderInfo: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-agent-landing-page-info", {
          params: {
            agent_id: app.agent_id,
          },
        })
        .then(function (response) {
          window.localStorage.setItem(
            "agent_code",
            response.data.data.agent_code
          );
          app.menuLink.routeToRepRegistration = `${
            process.env.VUE_APP_REP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.menuLink.routeToGroupRegistration = `${
            process.env.VUE_APP_GROUP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.phone = response.data.data.display_phone;
          app.email = response.data.data.display_email;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getGroupHeaderInfo: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-group-landing-page-info", {
          params: {
            group_id: app.group_id,
          },
        })
        .then(function (response) {
          window.localStorage.setItem(
            "agent_code",
            response.data.data.agent_code
          );
          app.menuLink.routeToRepRegistration = `${
            process.env.VUE_APP_REP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.menuLink.routeToGroupRegistration = `${
            process.env.VUE_APP_GROUP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.phone = response.data.data.display_phone;
          app.email = response.data.data.display_email;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(
        unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN))
      );
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`;
      caches
        .open("fetch_color_cache")
        .then((cache) => {
          cache.match(url).then((response) => {
            response.json().then((data) => {
              let getCatchColor = [];
              let newObj = {};
              getCatchColor = data;
              getCatchColor.map((item) => {
                newObj[item.key] = item.value;
              });
              this.footerIcon = newObj["VUE_APP_FOOTER_LOGO"];
              this.iosImgLink = newObj["VUE_APP_IOS_ICON"];
              this.androidImgLink = newObj["VUE_APP_ANDROID_ICON"];
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-group", {
            params: {
              group_code: app.groupEnrollmentCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.groupInfo.name = response.data.data.name;
              app.showProceedConformation = true;
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.enrollmentShow = false;
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
    routeToGroupEnroll() {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-group", {
          params: {
            group_code: app.groupEnrollmentCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            let responseData = response.data.data;
            app.groupRouteName = responseData.landing_url;
            app.enrollmentShow = false;
            let enrollType = "Group";
            if (
              responseData.landing_url == null ||
              responseData.landing_url == ""
            ) {
              app.$router.push("/landing-config-error/" + enrollType);
            } else {
              app.$router.push(`/${app.groupRouteName}`);
              location.reload();
            }
          }
          if (response.status == 204) {
            app.groupErrorFlag = true;
            app.showProceedConformation = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
    },
    checkRepEnrollmentCode() {
      let app = this;
      app.$v.repAccessCode.$touch();
      if (app.$v.repAccessCode.$invalid) {
        return false;
      } else {
        let app = this;
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
            params: {
              agent_code: app.repAccessCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.showRepConformation = true;
              app.repInfo = { ...response.data.data };
              let formatted_phone = `(${app.repInfo.phone.slice(
                0,
                3
              )}) ${app.repInfo.phone.slice(3, 6)}-${app.repInfo.phone.slice(
                6,
                10
              )}`;
              app.repInfo.phone = formatted_phone;
            }
            if (response.status == 204) {
              app.repErrorFlag = true;
              app.showRepConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    routeToRepEnroll() {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
          params: {
            agent_code: app.repAccessCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            let responseData = response.data.data;
            app.repRouteName = responseData.landing_url;
            app.enrollmentShow = false;
            let enrollType = "Agent";
            if (
              responseData.landing_url == null ||
              responseData.landing_url == ""
            ) {
              app.$router.push("/landing-config-error/" + enrollType);
            } else {
              app.$router.push(`/${app.repRouteName}`);
              location.reload();
            }
          }
          if (response.status == 204) {
            app.repErrorFlag = true;
            app.showRepConformation = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    resetRepEnroll() {
      let app = this;
      app.showRepConformation = false;
      app.accssShow = false;
      app.repAccessCode = null;
      app.repErrorFlag = false;
      app.$v.$reset();
    },
    routeToNotMySITE() {
      let app = this;
      localStorage.clear();
      app.$router.push("/");
      location.reload();
    },
    getVideoLink(link) {
      let app = this;
      if (link === "howToEnrollVideo") {
        app.videoLink =
          "https://benefit-store-assets.s3.us-east-2.amazonaws.com/Member_How_to_GoEnroll123com_3.mp4";
      } else {
        app.videoLink =
          "https://benefit-store-assets.s3.us-east-2.amazonaws.com/Employer_Group_Goenroll123_V3.mp4";
      }
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    },
    removeError () {
      let app = this
      app.formErrorFlag = false
      app.formErrors = []
    },
    getStates: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-states')
          .then(function (response) {
            if (response.data.status == 'success') {
              app.states = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getCurrentYear () {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      return currentYear
    }
  },
  listener: {
    FromDirect() {
      let app = this;
      app.group_id = window.localStorage.getItem("group_id");
      app.agent_id = window.localStorage.getItem("agent_id");
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
    externalsite() {
      let app = this;
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
  },
};
</script>
<style lang="scss">
.group-not-employer-container {
  p {
    color: #dc3545;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 14px;
  }
  &:hover {
    text-decoration: underline;
    color: #dc3545;
  }
}
.rep-confirm-header {
  font-size: 25px;
}
.popUp-header h2{
  font-size: 18px;
  font-weight: 600;
  color: #3e7b98;
}
.custom-input {
  background-color: #FFFFFF;
  color: #2c3f58;
}


</style>

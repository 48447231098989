<template>
  <section class="banner">
    <div class="en_container ag_home ben_home">
      <div class="bn_image">
        <div class="caption">
          <div class="caption_container">
            <div class="caption_boxes">
              <h1><span>Welcome to the Benefit Store </span><br>Let's Get Started...</h1>
              <p>Who do you want to enroll for?</p>
              <div class="links">
                <ul>
                  <li>
                    <a class="new_user" href="#" id="newMember" @click="showPop">New Member</a>
                  </li>
                  <li>
                    <a class="new_user" href="#" id="currentMember" @click="memberPage">Current Member</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <img src="/images/ben_banner.svg" alt="Corenroll">
      </div>

        <!-- group popup -->
        <div  v-show="visible" class="popups">
        <div class="popup_container">
        <a @click="closePop" class="close" href="#">x</a>
        <h2>Choose an option to enroll a new member:</h2>
        <div class="content grey_box">
          <form class="" action="" method="post">

            <div class="button_box">
              <button class="submit" type="button" id="startDirectEnroll" name="button" @click="nextPageDefault">Start Direct Enrollment</button>
            </div>
            <div class="button_box">
              <!-- <button class="submit" type="button" name="button" @click="nextPage">Enroll</button> -->
            </div>
            <div class="button_box">
              OR
            </div>
            <div class="button_box">
              <!-- <button class="submit" type="button" name="button" @click="nextPage">Enroll</button> -->
            </div>
            <div class="input_box" style="margin-top: 15px">
              <label for="">Choose a Group and Enroll</label>
              <select class="" v-model="group_id" id="group_id">
                <option v-for="item in groupData" :value="item.gid" v-bind:key="item.gid">{{item.gname}}</option>
              </select>
              <p style="color:red;" v-if="groupErr['error'] == true">{{groupErr['message']}}</p>
            </div>
            <div class="button_box">
                  <button class="submit" type="button" id="startEnroll" name="button" @click="nextPage"  style="margin-top: 15px">Enroll</button>
            </div>
          </form>
        </div>
        </div>
        </div>

<!--platform popup-->
      <div  v-show="platformPopup" class="popups">
        <div class="popup_container">
          <a @click="closePopPlatforms" class="close" href="#">x</a>
          <h2>Choose an option to enroll a new member</h2>
          <div class="content grey_box">
            <form class="" action="" method="post">

              <div class="input_box">
                <label for="">Choose Enrollment Portal</label>
                <select class="" v-model="platformId" id="platformId">
                  <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                </select>
                <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>
              </div>
              <div class="button_box">
                <button class="submit" type="button" name="button" id="choosePortal" @click="choosePortal">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
export default {
    name: "AgentHome",
    data: function () {
        return {
            groupData: [],
            agent_id: '',
            group_id: '',
            visible: false,
            groupErr: [],
            platforms: [],
            platformError: [],
            platformId: '',
            portalPop: false,
            platformPopup: false,
        }
    },
    mounted() {
        let app = this;
        // app.getMember();
        app.agent_id = app.$store.state.agent.agent_id;
        console.log(app.agent_id);
        app.getGroups();
        app.getPlatforms();
    },
    methods: {
    getGroups: function() {
        let app = this;
        // let agent_id = app.$route.params.id;
        axios.get(process.env.VUE_APP_API_BASE+'/get-agent-groups', {
          params: {
            agent_id: app.agent_id,
          }
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.status == 'success') {
            app.groupData = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getPlatforms: function() {
        let app = this;
        // let agent_id = app.$route.params.id;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-platforms', {
          params: {
            agent_id: app.agent_id,
          }
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.status == 'success') {
            app.platforms = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    choosePortal: function() {
        let app = this;
        console.log('platforms' + app.platformId);
        app.platformError = [];
        if (app.platformId != '') {
          window.localStorage.setItem('portal_id', this.platformId);
          app.platformPopup = false;
          app.visible = true;
        }else{
          app.platformError['error'] = true;
          app.platformError['message'] = "Please choose a platform";
        }
    },
    nextPage: function() {
      this.platformPopup = false;
      this.visible = true;
        this.groupErr = [];
        if (this.group_id == '') {
            this.groupErr['error'] = true;
            this.groupErr['message'] = "Please choose a group";
        }else{
            window.localStorage.setItem('group_id', this.group_id);
            window.localStorage.setItem('enroll_type', 'new-group-member');
            window.localStorage.setItem('eprocess', 'rep');
            this.$router.push('/home');
        }
    },
    nextPageDefault: function() {
        window.localStorage.setItem('group_id', this.platformId);
        window.localStorage.setItem('enroll_type', 'new-member');
        window.localStorage.setItem('eprocess', 'rep');
        this.$router.push('/home');
    },
    reloadPage: function(pid) {
        this.getPlanDetails();
        this.getPlanPricing();
        this.$router.push({name: 'plan', params: {id: pid}});
    },
    showPop(){
        this.portalPop=false;
        this.platformPopup=true;
    },
    closePop(){
        this.visible=false;
    },
      closePopPlatforms(){
        this.platformPopup=false;
      },
    closeportalPop(){
        this.portalPop=false;
    },
    memberPage: function(){
        window.localStorage.setItem('enroll_type', 'existing-member');
        this.$router.push('/agent-select-member');
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div>
    <banner-area></banner-area>
    <!-- Section Two - Brands -->
    <!-- <brands-area></brands-area> -->
    <!-- Section three - Advantages -->
    <!-- <advantage-area></advantage-area> -->
    <!-- Section four - Testimonials -->
    <!-- <testimonial></testimonial> -->

  </div>
</template>

<script>
  import MemberBanner from "../components/MemberBanner";
  // import Brands from "../components/Brands";
  // import Advantage from "../components/Advantage";
  // import Testimonial from "../components/Testimonial";

  export default {
    name: "Index",
    components: {
      'banner-area': MemberBanner,
      // 'brands-area' : Brands,
      // 'advantage-area' : Advantage,
      // 'testimonial' : Testimonial
    },
  }
</script>

<style scoped>

</style>

  <template>
    <section class="innerpage-wrap">
        <div class="container" ref="savedep">
            <div class="split-title">
                <h1 class="brand-color">Dependent Information</h1>
            </div>
            <div class="enrollmentForm card-block">
               <div v-if="!isPremierPlan">
                <div v-if="tier == 'IC'"><h3>Child information required </h3></div>
                <div v-if="tier == 'IS'"><h3>Spouse information required </h3></div>
                <div v-if="tier == 'IF'"><h3>Spouse & Child information required </h3></div>
                <div v-if="hqReq == true && enrolType != 'new-member'"><p>Please add/edit your dependent info to add/edit health questions.</p></div>
                <div id="incomeError" class="mt-3" style="color: red;" v-if="disclaimer">{{disclaimer}}</div> 
               </div>

                <!-- L7123 validation for IF and IC start -->
<!--                <div class="mb-3" v-if=" isPremierPlan && tier == 'IF'">
                  <h3> At Least 1 Spouse and 1 Child or 2 Child information required </h3>
                </div>-->
                <div class="mb-3" v-if="isPremierPlan && tier == 'IC'">
                  <h3>Child information required</h3>
                </div>

                <b-row no-gutters>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div v-if="hqReq == true && isPremierPlan && enrolType != 'new-member'">
                      <p>Please add/edit your dependent info to add/edit health questions.</p>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div  class="mb-2" v-if="depData.length >= 0 && isPremierPlan && tier == 'IF' && enrollType === 'new-group-member' && !askForSpouse">
                     <span class="dep-edit" >
                       <span class="dep-edit-text">Enroll Spouse</span>
                       <a  class="tooltip-hover" @click="askedForAddedDep()">
                         <i class="fas fa-pen"></i>
                          <span class="tooltiptext">Enroll Spouse</span>
                       </a>
                      </span>
                    </div>
                    <div  class="mb-2" v-if="depData.length >= 0 && isPremierPlan && tier == 'IF' && enrollType === 'existing-member' && !askForSpouse">
                      <span class="dep-edit">
                        <span class="dep-edit-text">Enroll Spouse</span>
                          <a  class="tooltip-hover"  @click="askedForAddedDep()">
                            <i class="fas fa-pen"></i>
                             <span class="tooltiptext">Enroll Spouse</span>
                          </a>
                      </span>
                    </div>
                    <div  class="mb-2" v-if="depData.length >= 0 && isPremierPlan && tier == 'IF' && enrollType === 'new-member' && !askForSpouse">
                      <span class="dep-edit">
                        <span class="dep-edit-text">Enroll Spouse</span>
                          <a class="tooltip-hover"  @click="askedForAddedDep()">
                            <i class="fas fa-pen"></i>
                             <span class="tooltiptext">Enroll Spouse</span>
                          </a>
                      </span>
                    </div>
                  </b-col>
                </b-row>

              <div style="color: green;" class="mb-3" v-if="(depData.length >= 2 && isPremierPlan && tier == 'IF' && enrollType !== 'existing-member') || (depData.length == 1 && isPremierPlan && tier == 'IC' && enrollType !== 'existing-member')">
                <strong> You can proceed further clicking next button </strong>
              </div>

              <div style="color: green;" class="mb-3" v-if="(depData.length >= 2 && isPremierPlan && tier == 'IF' && enrollType === 'existing-member' && isSpouseEnrollAccept) || (depData.length == 1 && isPremierPlan && tier == 'IC' && enrollType === 'existing-member' && isSpouseEnrollAccept)">
                <strong> You can proceed further clicking next button </strong>
              </div>

              <div style="color: red;" class="mb-3" v-if="(depData.length > 1 && isPremierPlan && tier == 'IC')">
                <strong> Only one child is allowed in L713 plans for IC tier please delete additional child to proceed further </strong>
              </div>
               <!-- L7123 validation for IF and IC end -->

                <div class="memberAdded" v-if="depData.length > 0 && !isPremierPlan" >
                  <form class="" action="index.html" method="post">
                    <ul>
                        <li v-for="(dep,index) in depData" v-bind:key="index"  class="input_box depentend_list client_email" :id="dep">
                            {{dep.primary_info.relationship}}#{{index+1 }}{{dep.primary_info.first_name+' '+dep.primary_info.last_name}}
                            <div class="AddedAction">
                                <a href="#" class="tooltip-hover" @click.prevent="showPopEdit(index)">
                                  <i class="fas fa-pen"></i>
                                  <span class="tooltiptext">Edit</span>
                                </a>
                                <a  href="#"  class="tooltip-hover" @click.prevent="removeDep(index)">
                                  <i class="fas fa-trash" ></i>
                                  <span class="tooltiptext">{{Delete_title}}</span>
                                </a>
                            <!-- v-if="index > 0" -->
                            </div>
                        </li>
                    </ul>
                    </form>
                </div>

              <div class="memberAdded" v-if="depData.length > 0 && isPremierPlan && depData.length !== 0 && enrollType === 'new-group-member'">
                <form class="" action="index.html" method="post">
                  <ul>
                    <li v-for="(dep,index) in depData" v-bind:key="index"  class="input_box depentend_list client_email" :id="dep">
                      {{dep.primary_info.relationship}}#{{index+1 }}{{dep.primary_info.first_name+' '+dep.primary_info.last_name}}
                      <div class="AddedAction">
                        <a href="#"  class="tooltip-hover" @click.prevent="showPopEdit(index)">
                          <i class="fas fa-pen"></i>
                          <span class="tooltiptext">Edit</span>
                        </a>
                        <a  href="#" class="tooltip-hover" @click.prevent="removeDep(index)">
                          <i class="fas fa-trash" ></i>
                          <span class="tooltiptext">{{Delete_title}}</span>
                        </a>
                        <!-- v-if="index > 0" -->
                      </div>
                    </li>
                  </ul>
                </form>
              </div>

              <div class="memberAdded" v-if="depData.length > 0 && isPremierPlan && depData.length !== 0 && enrollType === 'new-member'">
                <form class="" action="index.html" method="post">
                  <ul>
                    <li v-for="(dep,index) in depData" v-bind:key="index"  class="input_box depentend_list client_email" :id="dep">
                      {{dep.primary_info.relationship}}#{{index+1 }}{{dep.primary_info.first_name+' '+dep.primary_info.last_name}}
                      <div class="AddedAction">
                        <a href="#" class="tooltip-hover" @click.prevent="showPopEdit(index)">
                          <i class="fas fa-pen"></i>
                          <span class="tooltiptext">Edit</span>
                        </a>
                        <a  href="#"  class="tooltip-hover" @click.prevent="removeDep(index)">
                          <i class="fas fa-trash" ></i>
                          <span class="tooltiptext">{{Delete_title}}</span>
                        </a>
                        <!-- v-if="index > 0" -->
                      </div>
                    </li>
                  </ul>
                </form>
              </div>

              <div class="memberAdded" v-if="depData.length >= 0 && isPremierPlan  && depData.length !== 0 && enrollType === 'existing-member' && isSpouseEnrollAccept">
                <form class="" action="index.html" method="post">
                  <ul>
                    <li v-for="(dep,index) in depData" v-bind:key="index"  class="input_box depentend_list client_email" :id="dep">
                      {{dep.primary_info.relationship}}#{{index+1 }}{{dep.primary_info.first_name+' '+dep.primary_info.last_name}}
                      <div class="AddedAction">
                        <a href="#"  class="tooltip-hover" @click.prevent="showPopEdit(index)">
                          <i class="fas fa-pen"></i>
                          <span class="tooltiptext">Edit</span>
                        </a>
                        <a  href="#" class="tooltip-hover" @click.prevent="removeDep(index)">
                          <i class="fas fa-trash" ></i>
                          <span class="tooltiptext">{{Delete_title}}</span>
                        </a>
                        <!-- v-if="index > 0" -->
                      </div>
                    </li>
                  </ul>
                </form>
              </div>

              <div class="memberAdded" v-if="depData.length > 0 && isPremierPlan  && depData.length !== 0 && enrollType === 'existing-member' && (tier == 'IC' || tier == 'IS')">
                <form class="" action="index.html" method="post">
                  <ul>
                    <li v-for="(dep,index) in depData" v-bind:key="index"  class="input_box depentend_list client_email" :id="dep">
                      {{dep.primary_info.relationship}}#{{index+1 }}{{dep.primary_info.first_name+' '+dep.primary_info.last_name}}
                      <div class="AddedAction">
                        <a href="#" class="tooltip-hover" @click.prevent="showPopEdit(index)">
                          <i class="fas fa-pen"></i>
                          <span class="tooltiptext">Edit</span>
                        </a>
                        <a  href="#" class="tooltip-hover" @click.prevent="removeDep(index)">
                          <i class="fas fa-trash" ></i>
                          <span class="tooltiptext">{{Delete_title}}</span>
                        </a>
                        <!-- v-if="index > 0" -->
                      </div>
                    </li>
                  </ul>
                </form>
              </div>

              <!-- Logic for L7123 plan selection on dependent page start -->
                <div style="margin-top:1rem;" centered   class="mb-3" v-if="isPremierPlan">
                  <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mt-3">
                    <div v-if="!depData.length > 0 && tier == 'IS'">
                      <button   @click="showPop" class="univ-btn tooltip-hover">+ Add Spouse  <span class="tooltiptext">{{add_dep}}</span></button>
                    </div>
                    <div v-if="!depData.length > 0 && tier == 'IC'">
                      <button @click="showPop" class="univ-btn tooltip-hover">+ Add Child  <span class="tooltiptext">{{add_dep}}</span></button>
                    </div>
                  </div>

                  <div v-if="tier == 'IF'">
                    <div v-if="askForSpouse && depData.length == 0">
                     <label>Will you be Enrolling your spouse ?</label>
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <b-form-select v-model="selectSpouse"
                                       class="custom-input"
                                       @input="removeSpouseError"
                                       id="spouse"
                                       :class="{'form-control is-invalid': isSpouseSelected}"
                                       :options="spouseOption">
                        </b-form-select>
                        <div class="form-group__message mt-2" v-if="isSpouseSelected">
                          Please select spouse status
                        </div>
                        <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mt-3">
                        <button class="submit univ-btn mt-2" type="button" name="button" @click="spouseStatus">Submit</button>
                        </div>
                      </div>
                    </div>

                    <div v-if="askForSpouse && askExistingSpouse  && depData.length !== 0 && enrollType === 'existing-member'">
                      <label>Will you be Enrolling your spouse ?</label>
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <b-form-select v-model="selectSpouse"
                                       class="custom-input"
                                       @input="removeSpouseError"
                                       id="spouse"
                                       :class="{'form-control is-invalid': isSpouseSelected}"
                                       :options="spouseOption">
                        </b-form-select>
                        <div class="form-group__message mt-2" v-if="isSpouseSelected">
                          Please select spouse status
                        </div>
                        <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mt-3">
                          <button class="submit univ-btn mt-2" type="button" name="button" @click="spouseStatus">Submit</button>
                        </div>
                      </div>
                    </div>

                    <div v-if="askForSpouse && askExistingSpouse  && depData.length !== 0 && enrollType === 'new-group-member'">
                      <label>Will you be Enrolling your spouse ?</label>
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <b-form-select v-model="selectSpouse"
                                       class="custom-input"
                                       @input="removeSpouseError"
                                       id="spouse"
                                       :class="{'form-control is-invalid': isSpouseSelected}"
                                       :options="spouseOption">
                        </b-form-select>
                        <div class="form-group__message mt-2" v-if="isSpouseSelected">
                          Please select spouse status
                        </div>
                        <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mt-3">
                          <button class="submit univ-btn mt-2" type="button" name="button" @click="spouseEditStatus">Submit</button>
                        </div>
                      </div>
                    </div>

                    <div v-if="askForSpouse && askExistingSpouse  && depData.length !== 0 && enrollType === 'new-member'">
                      <label>Will you be Enrolling your spouse ? </label>
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <b-form-select v-model="selectSpouse"
                                       class="custom-input"
                                       @input="removeSpouseError"
                                       id="spouse"
                                       :class="{'form-control is-invalid': isSpouseSelected}"
                                       :options="spouseOption">
                        </b-form-select>
                        <div class="form-group__message mt-2" v-if="isSpouseSelected">
                          Please select spouse status
                        </div>
                        <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mt-3">
                          <button class="submit univ-btn mt-2" type="button" name="button" @click="spouseEditStatus">Submit</button>
                        </div>
                      </div>
                    </div>

                    <div v-if="askForSpouse && askExistingSpouse  && depData.length !== 0 && enrollType === 'new-member'">
                      <label>Will you be Enrolling your spouse ? </label>
                      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <b-form-select v-model="selectSpouse"
                                       class="custom-input"
                                       @input="removeSpouseError"
                                       id="spouse"
                                       :class="{'form-control is-invalid': isSpouseSelected}"
                                       :options="spouseOption">
                        </b-form-select>
                        <div class="form-group__message mt-2" v-if="isSpouseSelected">
                          Please select spouse status
                        </div>
                        <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mt-3">
                          <button class="submit univ-btn mt-2" type="button" name="button" @click="spouseEditStatus">Submit</button>
                        </div>
                      </div>
                    </div>

                    <div v-if="!askForSpouse" class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-3">
                      <div v-if="tier == 'IF' && checkExistingSpouse == false && selectSpouse === 'Yes'">
                        <button  @click="showPop" class="univ-btn tooltip-hover">+ Add Spouse   <span class="tooltiptext">{{add_dep}}</span></button>
                      </div>
                      <div v-else-if="tier == 'IF' && checkExistingSpouse == true && selectSpouse === 'No'">
                        <button  @click="showPop" class="univ-btn tooltip-hover">+ Add child <span class="tooltiptext">{{add_dep}}</span></button>
                      </div>
                      <div v-else>
                        <div>
                         <button @click="showPop" class="univ-btn tooltip-hover">+ Add child <span class="tooltiptext">{{add_dep}}</span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               <!-- Logic for L7123 plan selection on dependent page end -->

               <!-- Normal flow logic -->
                <div style="margin-top:1rem;" centered class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-3" v-if="!isPremierPlan">
                  <div v-if="!depData.length > 0 && tier == 'IS'">
                    <button  @click="showPop" class="univ-btn tooltip-hover">+ Add Spouse  <span class="tooltiptext">{{add_dep}}</span></button>
                  </div>
                  <div v-if="tier == 'IC'">
                    <button  @click="showPop" class="univ-btn tooltip-hover">+ Add Child <span class="tooltiptext">{{add_dep}}</span></button>
                  </div>
                  <div v-if="tier == 'IF' && checkExistingSpouse == false">
                    <button  @click="showPop" class="univ-btn tooltip-hover">+ Add Spouse <span class="tooltiptext">{{add_dep}}</span></button>
                  </div>
                  <div v-else-if="tier == 'IF' && checkExistingSpouse == true">
                    <button  @click="showPop" class="univ-btn tooltip-hover">+ Add child <span class="tooltiptext">{{add_dep}}</span></button>
                  </div>

                </div>

                <div style="color: red;padding: 1em;" v-if="mainErrorFlag == true">{{mainErrors}}</div>

                <div  v-show="visible" id="refresh"  class="popups pt-3" ref="savedep">
                <h3>Dependent #{{depData.length+1}}</h3>
                  <form class="" id="popups_disable" action="index.html" method="post">
                    <div class="form-group row">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.first_name.required == true">
                          <label>First Name <span class="required">*</span></label>
                          <input type="text"
                                 class="custom-input"
                                 placeholder="Enter First Name"
                                 name=""
                                 id=""
                                 @input="$v.formFields.first_name.value.$touch(); removeError();"
                                 :class="{'form-control is-invalid':$v.formFields.first_name.value.$error ||  formErrors['primary_info.first_name_message']}"
                                 v-model="formFields.first_name.value">
                          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                            Field is required
                          </div>
                          <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                            Please enter valid first name
                          </div>
                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['primary_info.first_name_message']}}</div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.last_name.required == true">
                          <label>Last Name <span class="required">*</span></label>
                          <input type="text"
                                 class="custom-input"
                                 placeholder="Enter Last Name"
                                 name="" id=""
                                 @input="$v.formFields.last_name.value.$touch(); removeError();"
                                 v-bind:class="{'form-control is-invalid':$v.formFields.last_name.value.$error || formErrors['primary_info.last_name_message']}"
                                 v-model="formFields.last_name.value">
                          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                            Field is required
                          </div>
                          <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                            Please enter valid last name
                          </div>
                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['primary_info.last_name_message']}}</div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.birthdate.required == true">
                          <label>Date of Birth <span class="required">*</span></label>
                          <v-date-picker
                              v-model="formFields.birthdate.value"
                              :popover="{ visibility: 'click' }"
                              :max-date="new Date()"
                              @input="$v.formFields.birthdate.value.$touch(); removeError(); convertIntoDateFormat();"
                              mode="date">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                  class="custom-input"
                                  placeholder="mm/dd/yyyy"
                                  :class="{'invalid':$v.formFields.birthdate.value.$error || formErrors['primary_info.birthdate_message']}"
                                  size=10 maxlength=10
                                  :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                  v-on="inputEvents"
                              />
                            </template>
                          </v-date-picker>
                          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.birthdate.value.required && $v.formFields.birthdate.value.$error">
                            Field is required
                          </div>
                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['primary_info.birthdate_message']}}</div>
                        </div>
                    </div>

                    <div class="form-group row">


                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.gender.required == true">
                          <label>Gender <span class="required">*</span></label>
                          <b-form-select name=""
                                  class="custom-input"
                                   :options="genderOptions"
                                  @input="$v.formFields.gender.value.$touch(); removeError();"
                                  v-bind:class="{'form-control is-invalid':$v.formFields.gender.value.$error || formErrors['primary_info.gender_message']}"
                                  id="" v-model="formFields.gender.value">
                          </b-form-select>
                          <div class="form-group__message text-left ml-3 mt-2"
                               v-if="!$v.formFields.gender.value.required && $v.formFields.gender.value.$error">
                            Field is required
                          </div>
                          <div class="error_message"  v-if="formErrorFlag == true">{{formErrors['primary_info.gender_message']}}</div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.ssn.required == true">
                          <label>Social Security Number <span class="required">*</span></label>
                          <input type="number"
                                 class="custom-input"
                                 placeholder="Enter Social Security Number"
                                 name="" id=""
                                 :class="{'form-control is-invalid':$v.formFields.ssn.value.$error || formErrors['primary_info.ssn_message'] }"
                                 @input="$v.formFields.ssn.value.$touch();removeError();"
                                 min="0"
                                 v-model="formFields.ssn.value"
                                 oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="9">
                          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                            Field is required
                          </div>
                          <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                            Invalid Social Security Number
                          </div>
                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['primary_info.ssn_message']}}</div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.relationship.required == true">
                            <label>Relationship <span class="required">*</span></label>
                            <div v-if="isPremierPlan">
                              <input type="text" class="custom-input" v-model="formFields.relationship.value" v-if="(depData.length == 0 && spouseReq == true && tier =='IS' ) " value="Spouse" placeholder="Spouse" disabled readonly="readonly">
                              <input type="text" class="custom-input" v-model="formFields.relationship.value" v-else-if="(depData.length == 0  && selectSpouse == 'Yes'&& tier =='IF' )" value="Spouse" placeholder="Spouse" disabled readonly="readonly">
                              <input type="text" class="custom-input" v-model="formFields.relationship.value" v-else-if="(depData.length > 0  && selectSpouse == 'Yes'&& tier =='IF' && askExistingSpouse )" value="Spouse" placeholder="Spouse" disabled readonly="readonly">
                              <input type="text" class="custom-input" v-model="formFields.relationship.value" v-else value="Child" placeholder="Child" disabled="true" readonly="readonly">
                            </div>
                            <div v-else>
                              <input type="text" class="custom-input" v-model="formFields.relationship.value" v-if="(depData.length == 0 && spouseReq == true && tier =='IS' ) " value="Spouse" placeholder="Spouse" disabled readonly="readonly">
                              <input type="text" class="custom-input" v-model="formFields.relationship.value" v-else-if="(depData.length >= 0 && checkExistingSpouse == false && tier =='IF' )" value="Spouse" placeholder="Spouse" disabled readonly="readonly">
                              <input type="text" class="custom-input" v-model="formFields.relationship.value" v-else value="Child" placeholder="Child" disabled="true" readonly="readonly">
                            </div>
                            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['primary_info.relationship_message']}}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.weight.required == true">
                          <label>Weight (lbs) <span class="required">*</span></label>
                          <input type="number"
                                 class="custom-input"
                                 id="weight"
                                 :class="{'form-control is-invalid':$v.formFields.weight.value.$error || formErrors['primary_info.weight_message']}"
                                 @input="$v.formFields.weight.value.$touch(); removeError();"
                                 placeholder="Enter weight"
                                 v-model="formFields.weight.value" value=""
                                 min="0"
                                 oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="3">
                          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.weight.value.required && $v.formFields.weight.value.$error">
                            Field is required
                          </div>
                          <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.weight.value.required && $v.formFields.weight.value.$error">
                            Weight cannot be zero or less
                          </div>
                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['primary_info.weight_message']}}</div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4"  v-if="formFields.height_feet.required == true">
                          <label>Height<span class="required">*</span></label>
                          <div class="height-input" :class="{'invalid-header-align':$v.formFields.height_feet.value.$error || formErrors['height_feet_message']
                              || $v.formFields.height_inch.value.$error || formErrors['height_inch_message']}">
                            <span class="feet">Feet:</span>
                            <div :class="{'invalid-feet-align': $v.formFields.height_feet.value.$error || formErrorFlag}">
                              <input type="number"
                                     placeholder="Feet"
                                     class="custom-input"
                                     id="height1"
                                     @input="$v.formFields.height_feet.value.$touch(); removeError();"
                                     :class="{'form-control is-invalid':$v.formFields.height_feet.value.$error || formErrors['primary_info.height_feet_message']}"
                                     v-model="formFields.height_feet.value"
                                     oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="1">
                              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.height_feet.value.required && $v.formFields.height_feet.value.$error">
                                Field is required
                              </div>
                              <div class="form-group__message  ml-3 mt-2" style="font-size: 11px;"
                                   v-if="$v.formFields.height_feet.value.required && $v.formFields.height_feet.value.$error">
                                Height cannot be zero
                              </div>
                              <div style="color: red;padding: 1em;" v-if="formErrorFlag == true">{{formErrors['primary_info.height_feet_message']}}</div>
                            </div>
                            <span class="feet">Inches:</span>
                            <div :class="{'invalid-align':$v.formFields.height_inch.value.$error || formErrors['height_inch_message']}">
                              <input type="number"
                                     placeholder="Inch"
                                     class="custom-input"
                                     @input="$v.formFields.height_inch.value.$touch(); removeError();"
                                     v-bind:class="{'form-control is-invalid':$v.formFields.height_inch.value.$error || formErrors['height_inch_message']}"
                                     v-model="formFields.height_inch.value" value="1"
                                     oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="2">
                              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.height_inch.value.required && $v.formFields.height_inch.value.$error">
                                Field is required
                              </div>
                              <div class="form-group__message  ml-3 mt-2" style="font-size: 11px;"
                                   v-if="$v.formFields.height_inch.value.required && $v.formFields.height_inch.value.$error">
                                Inch is invalid
                              </div>
                              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['height_inch_message']}}</div>
                            </div>
                          </div>
                        </div>

                    </div>

                    <div class="form-group mb-4 healthQues" v-if="hqReq == true">
                        <h5>Health Questions</h5>
                        <vue-scroll :ops="quesScroll">
                            <ul>
                                <li  v-for="(quest,index) in questions" v-bind:key="quest.index = null">
                                    <div class="split">
                                        {{quest.question_text}}
                                        <div class="text-end">
                                            <div class="form-check form-check-inline mt-3">
                                                <input class="form-check-input" type="radio" name="" id="inlineRadio1"  v-model="yn[quest.question_id]" value="1" @click="setAns(quest.question_id, 'yes')">
                                                <label class="form-check-label" for="inlineRadio1">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="" id="inlineRadio2"  v-model="yn[quest.question_id]" value="0" @click="setAns(quest.question_id, 'no')">
                                                <label class="form-check-label" for="inlineRadio2">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="answer-field row" v-if="yesNoAns['q'+quest.question_id] == 1">
                                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                          <label>Condition/Diseases/Diagnosis/Treatment</label>
                                          <input class="custom-input"
                                                 placeholder="Enter health condition"
                                                 type="text"
                                                 @input="removeError();"
                                                 :class="{'form-control is-invalid': formErrors['health_info.condition_questions.'+index+'.conditions.0.health_condition_message']}"
                                                 name="health_condition"
                                                 v-model="answers[quest.question_id+'health_condition1']">
                                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.condition_questions.'+index+'.conditions.0.health_condition_message']}}</div>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                          <label>Date of Onset</label>
                                          <v-date-picker
                                              v-model="answers[quest.question_id+'date_of_onset1']"
                                              v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_onset1'], 'date_of_onset1')"
                                              :popover="{ visibility: 'click' }"
                                              :max-date="new Date()"
                                              @input="removeError(); convertTreatmentDateFormat(quest.question_id);"
                                              mode="date">
                                            <template v-slot="{ inputValue, inputEvents }">
                                              <input
                                                  class="custom-input"
                                                  placeholder="mm/dd/yyyy"
                                                  size=10 maxlength=10
                                                  :class="{'invalid': formErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_onset_message']}"
                                                  :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                                  v-on="inputEvents"
                                              />
                                            </template>
                                          </v-date-picker>
                                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_onset_message']}}</div>

                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                          <label>Date of Recovery</label>
                                          <v-date-picker
                                              v-model="answers[quest.question_id+'date_of_recovery1']"
                                              v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_recovery1'], 'date_of_recovery1')"
                                              :popover="{ visibility: 'click' }"
                                              :max-date="new Date()"
                                              @input="removeError(); convertRecoveryDateFormat(quest.question_id);"
                                              mode="date">
                                            <template v-slot="{ inputValue, inputEvents }">
                                              <input
                                                  class="custom-input"
                                                  placeholder="mm/dd/yyyy"
                                                  size=10 maxlength=10
                                                  :class="{'invalid': formErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_recovery_message']}"
                                                  :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                                  v-on="inputEvents"
                                              />
                                            </template>
                                          </v-date-picker>
                                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_recovery_message']}}</div>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                          <label>Current Treatment</label>
                                          <b-form-select class="custom-input"
                                                  name="is_treatment"
                                                  @input="removeError();"
                                                  :options="currentTreatmentOption"
                                                  :class="{'invalid': formErrors['health_info.condition_questions.'+index+'.conditions.0.is_treatment_message']}"
                                                  v-model="answers[quest.question_id+'is_treatment1']">
                                          </b-form-select>
                                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.condition_questions.'+index+'.conditions.0.is_treatment_message']}}</div>

                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                          <label>Taking Medication</label>
                                          <b-form-select class="custom-input"
                                                  name="is_medicate"
                                                  @input="removeError();"
                                                  :options="takingMedicalOption"
                                                  :class="{'invalid': formErrors['health_info.condition_questions.'+index+'.conditions.0.is_medicate_message']}"
                                                  v-model="answers[quest.question_id+'is_medicate1']">
                                          </b-form-select>
                                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.condition_questions.'+index+'.conditions.0.is_medicate_message']}}</div>

                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                          <label>Date Last Seen By Physician</label>
                                          <v-date-picker
                                              v-model="answers[quest.question_id+'d_last_seen1']"
                                              v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'d_last_seen1'], 'd_last_seen1')"
                                              :popover="{ visibility: 'click' }"
                                              :max-date="new Date()"
                                              @input="removeError(); convertPhysicianDateFormat(quest.question_id);"
                                              mode="date">
                                            <template v-slot="{ inputValue, inputEvents }">
                                              <input
                                                  class="custom-input"
                                                  placeholder="mm/dd/yyyy"
                                                  size=10 maxlength=10
                                                  :class="{'invalid': formErrors['health_info.condition_questions.'+index+'.conditions.0.d_last_seen_message']}"
                                                  :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                                  v-on="inputEvents"
                                              />
                                            </template>
                                          </v-date-picker>
                                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.condition_questions.'+index+'.conditions.0.d_last_seen_message']}}</div>

                                        </div>
                                        <div class="col-xxl-6 col-xl-6 col-12 mt-3">
                                          <label>Remaining Symptoms of Problems? Surgery or Hospitalization</label>
                                          <input class="custom-input"
                                                 type="text"
                                                 placeholder="Enter remaining problems"
                                                 @input="removeError();"
                                                 :class="{'invalid': formErrors['health_info.condition_questions.'+index+'.conditions.0.symptoms_message']}"
                                                 name="symptoms"
                                                 value=""
                                                 v-model="answers[quest.question_id+'symptoms1']">
                                          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.condition_questions.'+index+'.conditions.0.symptoms_message']}}</div>

                                        </div>
                                    </div>
                                </li>

                            </ul>
                            <ul>
                            <li v-if="medQuestion.question_id > 0">
                                <div class="split">
                                    {{medQuestion['question_text']}}
                                    <div class="text-end">
                                        <div class="form-check form-check-inline mt-3">
                                            <input class="form-check-input" type="radio" id="inlineRadio1"  v-model="yn[medQuestion.question_id]" value="1" @click="setAns(medQuestion.question_id, 'yes')">
                                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio2"  v-model="yn[medQuestion.question_id]" value="0" @click="setAns(medQuestion.question_id, 'no')">
                                            <label class="form-check-label" for="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                              <div class="answer-field row" v-if="yesNoAns['q'+medQuestion.question_id] == 1">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Medication/Rx injection</label>
                                  <input class="custom-input"
                                         placeholder="Enter Medication"
                                         type="text"
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.0.medication_message']}"
                                         name="medication"
                                         value="" v-model="medAnswers[medQuestion.question_id+'medication1']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.0.medication_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Dosage</label>
                                  <input class="custom-input"
                                         placeholder="Enter Dosage"
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.0.dosage_message']}"
                                         type="text"
                                         name="symptoms"
                                         value=""
                                         v-model="medAnswers[medQuestion.question_id+'dosage1']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.0.dosage_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Medical Condition</label>
                                  <input class="custom-input"
                                         placeholder="Enter Medical Condition"
                                         type="text"
                                         name="symptoms"
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.0.medical_condition_message']}"
                                         value=""
                                         v-model="medAnswers[medQuestion.question_id+'medical_condition1']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.0.medical_condition_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Medication/Rx injection</label>
                                  <input class="custom-input"
                                         placeholder="Enter Medication"
                                         type="text"
                                         name="medication"
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.1.medication_message']}"
                                         value=""
                                         v-model="medAnswers[medQuestion.question_id+'medication2']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.1.medication_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Dosage</label>
                                  <input class="custom-input"
                                         placeholder="Enter Dosage"
                                         type="text"
                                         name="symptoms"
                                         value=""
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.1.dosage_message']}"
                                         v-model="medAnswers[medQuestion.question_id+'dosage2']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.1.dosage_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Medical Condition</label>
                                  <input class="custom-input"
                                         placeholder="Enter Medical Condition"
                                         type="text"
                                         name="symptoms"
                                         value=""
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.1.medical_condition_message']}"
                                         v-model="medAnswers[medQuestion.question_id+'medical_condition2']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.1.medical_condition_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Medication/Rx injection</label>
                                  <input class="custom-input"
                                         placeholder="Enter Medication"
                                         type="text"
                                         name="medication"
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.2.medication_message']}"
                                         value=""
                                         v-model="medAnswers[medQuestion.question_id+'medication3']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.2.medication_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Dosage</label>
                                  <input class="custom-input"
                                         placeholder="Enter Dosage"
                                         type="text"
                                         name="symptoms"
                                         value=""
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.2.dosage_message']}"
                                         v-model="medAnswers[medQuestion.question_id+'dosage3']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.2.dosage_message']}}</span>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                  <label>Medical Condition</label>
                                  <input class="custom-input"
                                         placeholder="Enter Medical Condition"
                                         type="text"
                                         name="symptoms"
                                         @input="removeError();"
                                         :class="{'form-control is-invalid': formErrors['health_info.medication_question.medications.2.medical_condition_message']}"
                                         value=""
                                         v-model="medAnswers[medQuestion.question_id+'medical_condition3']">
                                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors['health_info.medication_question.medications.2.medical_condition_message']}}</span>
                                </div>
                              </div>
                      </ul>
                        </vue-scroll>
                        <div class="additional_notes" style="margin-top:2rem;">
                  <label><b>Additional Notes</b></label>
                  <textarea class="notes custom-input" v-model="notes" rows="4" ></textarea>
          </div>
                    </div>

                    <div class="form-group row justify-content-end">
                      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-3">
                        <button class="dark-univ-btn" type="button" name="button"
                                @click="cancelDepData()">Cancel</button>
                      </div>
                        <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12" v-if="depData.length < 9">
                            <button class="submit univ-btn" v-if="tier == 'IS' && depData.length == 0" type="button" name="button" @click="validateData('insert', depData.length)">Submit</button>
                            <button class="submit univ-btn" v-if="tier == 'IC' && depData.length < 9" type="button" name="button" @click="validateData('insert', depData.length)">Submit</button>
                            <button class="submit univ-btn" v-if="tier == 'IF' && depData.length < 9" type="button" name="button" @click="validateData('insert', depData.length)">Submit</button>
                        </div>
                    </div>
                </form>
                </div>
                <!--- Add Dependent Pop Up Form/Section Ends -->

                <!--- edit Dependent Pop Up Form/Section  -->

                <div  v-show="visibleEdit" class="popups" ref="savedepupdate">
                <h3>Dependent #{{editDep+1}}</h3>
                  <form class="" action="index.html" method="post">
                    <div class="form-group row">
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.first_name.required == true">
                        <label>First Name <span class="required">*</span></label>
                        <input type="text"
                               class="custom-input"
                               placeholder="Enter First Name"
                               name=""
                               id=""
                               v-model="formFields.dependent_id.value"
                               style="display: none;">
                        <input type="text"
                               class="custom-input"
                               placeholder="Enter First Name 12"
                               name=""
                               id=""
                               @input="$v.formFields.first_name.value.$touch(); removeError();"
                               :class="{'form-control is-invalid':$v.formFields.first_name.value.$error || updateformErrors['health_info.condition_questions.'+index+'.conditions.0.health_condition_message']}"
                               v-model="formFields.first_name.value">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                          Please enter valid first name
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.health_condition_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.last_name.required == true">
                        <label>Last Name <span class="required">*</span></label>
                        <input type="text"
                               class="custom-input"
                               placeholder="Enter Last Name"
                               name=""
                               id=""
                               @input="$v.formFields.last_name.value.$touch(); removeError();"
                               v-bind:class="{'form-control is-invalid':$v.formFields.last_name.value.$error || updateformErrors['primary_info.last_name_message']}"
                               v-model="formFields.last_name.value">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                          Please enter valid last name
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['primary_info.last_name_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.birthdate.required == true">
                        <label>Date of Birth <span class="required">*</span></label>
                        <v-date-picker
                            v-model="formFields.birthdate.value"
                            :popover="{ visibility: 'click' }"
                            :max-date="new Date()"
                            @input="$v.formFields.birthdate.value.$touch(); removeError(); convertIntoDateFormat();"
                            mode="date">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                                class="custom-input"
                                placeholder="mm/dd/yyyy"
                                :class="{'invalid':$v.formFields.birthdate.value.$error || updateformErrors['primary_info.birthdate_message']}"
                                :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                size=10 maxlength=10
                                v-on="inputEvents"
                            />
                          </template>
                        </v-date-picker>
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.birthdate.value.required && $v.formFields.birthdate.value.$error">
                          Field is required
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['primary_info.birthdate_message']}}</div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.gender.required == true">
                        <label>Gender <span class="required">*</span></label>
                        <b-form-select name=""
                                       class="custom-input"
                                       id=""
                                       :options="genderOptions"
                                       @input="$v.formFields.gender.value.$touch(); removeError();"
                                       v-bind:class="{'form-control is-invalid':$v.formFields.gender.value.$error || updateformErrors['primary_info.gender_message']}"
                                       v-model="formFields.gender.value">
                        </b-form-select>
                        <div class="form-group__message text-left ml-3 mt-2"
                             v-if="!$v.formFields.gender.value.required && $v.formFields.gender.value.$error">
                          Field is required
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['primary_info.gender_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.ssn.required == true">
                        <label>Social Security Number <span class="required">*</span></label>
                        <input type="text"
                               class="custom-input"
                               placeholder="Enter Social Security Number"
                               name="" id="" v-model="formFields.ssn.value"
                               :class="{'form-control is-invalid':$v.formFields.ssn.value.$error || updateformErrors['primary_info.ssn_message']}"
                               @input="$v.formFields.ssn.value.$touch();removeError();"
                               min="0"
                               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                               maxlength="9">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                          Invalid Social Security Number
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['primary_info.ssn_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.relationship.required == true">
                        <label>Relationship <span class="required">*</span></label>
                        <input type="text" class="custom-input"
                               v-model="formFields.relationship.value"
                               v-if="depData.length >= 0 && spouseReq == true"
                               value="spouse" placeholder="spouse"
                               disabled="true"
                               readonly="readonly">
                        <input type="text" class="custom-input" v-model="formFields.relationship.value" v-else value="child" placeholder="child" disabled="true" readonly="readonly">
                        <div v-if="updateformErrorFlag == true">{{updateformErrors['primary_info.relationship_message']}}</div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.weight.required == true">
                        <label>Weight (lbs)</label>
                        <input type="number"
                               class="custom-input"
                               id="weight"
                               placeholder="Enter weight"
                               v-model="formFields.weight.value"
                               :class="{'form-control is-invalid':$v.formFields.weight.value.$error || updateformErrors['primary_info.weight_message'] }"
                               @input="$v.formFields.weight.value.$touch(); removeError();"
                               value=""
                               min="0"
                               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                               maxlength="3">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.weight.value.required && $v.formFields.weight.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.weight.value.required && $v.formFields.weight.value.$error">
                          Weight cannot be zero or less
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['primary_info.weight_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-if="formFields.height_feet.required == true">
                        <label>Height <span class="required">*</span></label>
                        <div class="height-input" :class="{'invalid-header-align':$v.formFields.height_feet.value.$error || formErrors['height_feet_message']
                          || $v.formFields.height_inch.value.$error || formErrors['height_inch_message']}"
                             style=" display: flex; flex-direction: row;  align-items: space-between;">
                          <span class="feet">Feet:</span>
                          <div :class="{'invalid-feet-align': $v.formFields.height_feet.value.$error}">
                            <input type="number"
                                   class="custom-input"
                                   id="height"
                                   @input="$v.formFields.height_feet.value.$touch(); removeError();"
                                   :class="{'form-control is-invalid':$v.formFields.height_feet.value.$error || updateformErrors['primary_info.height_feet_message'] }"
                                   v-model="formFields.height_feet.value"
                                   oninput="javascript:
                                 if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                   maxlength="1">
                            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.height_feet.value.required && $v.formFields.height_feet.value.$error">
                              Field is required
                            </div>
                            <div class="form-group__message  ml-3 mt-2" style="font-size: 11px;"
                                 v-if="$v.formFields.height_feet.value.required && $v.formFields.height_feet.value.$error">
                              Height cannot be zero
                            </div>
                            <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['primary_info.height_feet_message']}}</div>
                          </div>
                          <span class="feet">Inches: </span>
                          <div :class="{'invalid-align':$v.formFields.height_inch.value.$error || formErrors['height_inch_message']}">
                            <input type="number"
                                   class="custom-input"
                                   v-model="formFields.height_inch.value"
                                   value="2"
                                   @input="$v.formFields.height_inch.value.$touch(); removeError();"
                                   v-bind:class="{'form-control is-invalid':$v.formFields.height_inch.value.$error || updateformErrors['height_inch_message']}"
                                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                   maxlength="2">
                            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.height_inch.value.required && $v.formFields.height_inch.value.$error">
                              Field is required
                            </div>
                            <div class="form-group__message  ml-3 mt-2" style="font-size: 11px;"
                                 v-if="$v.formFields.height_inch.value.required && $v.formFields.height_inch.value.$error">
                              Inch is invalid
                            </div>
                            <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['height_inch_message']}}</div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="form-group mb-4 healthQues" v-if="hqReq == true">
                      <h5>Health Questions</h5>
                      <vue-scroll :ops="quesScroll">
                        <ul>
                          <li v-for="(quest,index) in questions" v-bind:key="index" style="list-style-type: none;">
                            <div class="split">
                              {{quest.question_text}}
                              <div class="text-end">
                                <div class="form-check form-check-inline mt-3">
                                  <input class="form-check-input" type="radio" name="" id="inlineRadio1"  v-model="yn[quest.question_id]" value="1" @click="setAns(quest.question_id, 'yes')">
                                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="" id="inlineRadio2" v-model="yn[quest.question_id]" value="0" @click="setAns(quest.question_id, 'no')">
                                  <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                              </div>
                            </div>
                            <div class="answer-field row"  v-if="yesNoAns['q'+quest.question_id] == 1">
                              <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                <label>Condition/Diseases/Diagnosis/Treatment</label>
                                <input class="custom-input"
                                       type="text"
                                       @input="removeError();"
                                       :class="{'form-control is-invalid': updateformErrors['health_info.condition_questions.'+index+'.conditions.0.health_condition_message']}"
                                       placeholder="Select Health Condition"
                                       name="health_condition"
                                       v-model="answers[quest.question_id+'health_condition1']">
                                <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.health_condition_message']}}</div>
                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                <label>Date of Onset</label>
                                <v-date-picker
                                    v-model="answers[quest.question_id+'date_of_onset1']"
                                    v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_onset1'], 'date_of_onset1')"
                                    :popover="{ visibility: 'click' }"
                                    :max-date="new Date()"
                                    @input="removeError(); convertTreatmentDateFormat(quest.question_id);"
                                    mode="date">
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        class="custom-input"
                                        placeholder="mm/dd/yyyy"
                                        size=10 maxlength=10
                                        :class="{'invalid': updateformErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_onset_message']}"
                                        :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                        v-on="inputEvents"
                                    />
                                  </template>
                                </v-date-picker>
                                <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_onset_message']}}</div>
                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                <label>Date of Recovery</label>
                                <v-date-picker
                                    v-model="answers[quest.question_id+'date_of_recovery1']"
                                    v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_recovery1'], 'date_of_recovery1')"
                                    :popover="{ visibility: 'click' }"
                                    :max-date="new Date()"
                                    @input="removeError(); convertRecoveryDateFormat(quest.question_id);"
                                    mode="date">
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        class="custom-input"
                                        placeholder="mm/dd/yyyy"
                                        size=10 maxlength=10
                                        :class="{'invalid': updateformErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_recovery_message']}"
                                        :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                        v-on="inputEvents"
                                    />
                                  </template>
                                </v-date-picker>
                                <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_recovery_message']}}</div>

                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                <label>Current Treatment</label>
                                <b-form-select class="custom-input"
                                        name="is_treatment"
                                        @input="removeError();"
                                        :options="currentTreatmentOption"
                                        :class="{'invalid': updateformErrors['health_info.condition_questions.'+index+'.conditions.0.is_treatment_message']}"
                                        v-model="answers[quest.question_id+'is_treatment1']">
                                </b-form-select>
                                <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.is_treatment_message']}}</div>

                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                <label>Taking Medication</label>
                                <b-form-select class="custom-input"
                                        name="is_medicate"
                                        @input="removeError();"
                                        :options="takingMedicalOption"
                                        :class="{'invalid': updateformErrors['health_info.condition_questions.'+index+'.conditions.0.is_medicate_message']}"
                                        v-model="answers[quest.question_id+'is_medicate1']">
                                </b-form-select>
                                <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.is_medicate_message']}}</div>

                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                                <label>Date Last Seen By Physician</label>
                                <v-date-picker
                                    v-model="answers[quest.question_id+'d_last_seen1']"
                                    v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'d_last_seen1'], 'd_last_seen1')"
                                    :popover="{ visibility: 'click' }"
                                    :max-date="new Date()"
                                    @input="removeError(); convertPhysicianDateFormat(quest.question_id);"
                                    mode="date">
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        class="custom-input"
                                        placeholder="mm/dd/yyyy"
                                        size=10 maxlength=10
                                        :class="{'invalid': updateformErrors['health_info.condition_questions.'+index+'.conditions.0.d_last_seen_message']}"
                                        :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                        v-on="inputEvents"
                                    />
                                  </template>
                                </v-date-picker>
                                <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.d_last_seen_message']}}</div>

                              </div>
                              <div class="col-xxl-6 col-xl-6 col-12 mt-3">
                                <label>Remaining Symptoms of Problems? Surgery or Hospitalization</label>
                                <input class="custom-input"
                                       type="text"
                                       placeholder="Enter remaining problems"
                                       @input="removeError();"
                                       :class="{'invalid': updateformErrors['health_info.scondition_questions.'+index+'.conditions.0.symptoms_message']}"
                                       name="symptoms"
                                       value=""
                                       v-model="answers[quest.question_id+'symptoms1']">
                                <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.scondition_questions.'+index+'.conditions.0.symptoms_message']}}</div>

                              </div>
                            </div>
                            <!-- <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8" v-if="yesNoAns['q'+quest.question_id] == 1">
                                <table class="table" cellspacing="0" style="border-collapse: unset !important;" >
                                    <tr>
                                        <td>Condition/Diseases/Diagnosis/Treatment</td>
                                        <td>
                                        <input class="custom-input" type="text" placeholder="Select Health Condition" name="health_condition" v-model="answers[quest.question_id+'health_condition1']">
                                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.health_condition_message']}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date of Onset</td>
                                        <td>
                                        <date-picker
                                        v-model="answers[quest.question_id+'date_of_onset1']"
                                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_onset1'], 'date_of_onset1')"
                                        input-class="custom-input"
                                        placeholder="mm/dd/yyyy"
                                        format="MM/DD/YYYY"
                                        value-type="format"
                                        type="date" ></date-picker>
                                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_onset_message']}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date of Recovery</td>
                                        <td>
                                        <date-picker
                                        v-model="answers[quest.question_id+'date_of_recovery1']"
                                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_recovery1'], 'date_of_recovery1')"
                                        input-class="custom-input"
                                        placeholder="mm/dd/yyyy"
                                        format="MM/DD/YYYY"
                                        value-type="format"
                                        type="date" ></date-picker>
                                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.date_of_recovery_message']}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Current Treatment</td>
                                        <td>
                                        <select class="custom-input" name="is_treatment" v-model="answers[quest.question_id+'is_treatment1']">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.is_treatment_message']}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Taking Medication</td>
                                        <td>
                                        <select class="custom-input" name="is_medicate" v-model="answers[quest.question_id+'is_medicate1']">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.is_medicate_message']}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date Last Seen By Physician</td>
                                        <td>
                                        <date-picker
                                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'d_last_seen1'], 'd_last_seen1')"
                                        v-model="answers[quest.question_id+'d_last_seen1']"
                                        input-class="custom-input"
                                        placeholder="mm-dd-yyyy"
                                        format="MM/DD/YYYY"
                                        type="date"
                                        value-type="format"></date-picker>
                                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.condition_questions.'+index+'.conditions.0.d_last_seen_message']}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Remaining Symptoms of Problems? Surgery or Hospitalization</td>
                                        <td>
                                        <input class="custom-input" type="text" placeholder="Enter remaining problems" name="symptoms" value="" v-model="answers[quest.question_id+'symptoms1']">
                                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.scondition_questions.'+index+'.conditions.0.symptoms_message']}}</div>
                                        </td>
                                    </tr>
                                </table>
                            </div> -->
                          </li>

                        </ul>
                        <ul>
                          <li  v-if="medQuestion.question_id > 0" >
                            <div class="split">
                              {{medQuestion['question_text']}}
                              <div class="text-end">
                                <div class="form-check form-check-inline mt-3">
                                  <input class="form-check-input" type="radio" id="inlineRadio1"  v-model="yn[medQuestion.question_id]" value="1" @click="setAns(medQuestion.question_id, 'yes')">
                                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" id="inlineRadio2" v-model="yn[medQuestion.question_id]" value="0" @click="setAns(medQuestion.question_id, 'no')">
                                  <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <div class="answer-field row" v-if="yesNoAns['q'+medQuestion.question_id] == 1">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Medication/Rx injection</label>
                              <input class="custom-input"
                                     placeholder="Enter Medication"
                                     type="text"
                                     name="medication"
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.0.medication_message']}"
                                     value=""
                                     v-model="medAnswers[medQuestion.question_id+'medication1']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.0.medication_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Dosage</label>
                              <input class="custom-input"
                                     placeholder="Enter Dosage"
                                     type="text"
                                     name="symptoms"
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.0.dosage_message']}"
                                     value=""
                                     v-model="medAnswers[medQuestion.question_id+'dosage1']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.0.dosage_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Medical Condition</label>
                              <input class="custom-input"
                                     placeholder="Enter Medical Condition"
                                     type="text"
                                     name="symptoms"
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.0.medical_condition_message']}"
                                     value=""
                                     v-model="medAnswers[medQuestion.question_id+'medical_condition1']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.0.medical_condition_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Medication/Rx injection</label>
                              <input class="custom-input"
                                     placeholder="Enter Medication"
                                     type="text"
                                     name="medication"
                                     value=""
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.1.medication_message']}"
                                     v-model="medAnswers[medQuestion.question_id+'medication2']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.1.medication_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Dosage</label>
                              <input class="custom-input"
                                     placeholder="Enter Dosage"
                                     type="text"
                                     name="symptoms"
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.1.dosage_message']}"
                                     value=""
                                     v-model="medAnswers[medQuestion.question_id+'dosage2']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.1.dosage_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Medical Condition</label>
                              <input class="custom-input"
                                     placeholder="Enter Medical Condition"
                                     type="text"
                                     name="symptoms"
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.1.medical_condition_message']}"
                                     value=""
                                     v-model="medAnswers[medQuestion.question_id+'medical_condition2']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.1.medical_condition_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Medication/Rx injection</label>
                              <input class="custom-input"
                                     placeholder="Enter Medication"
                                     type="text"
                                     name="medication"
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.2.medication_message']}"
                                     value="" v-model="medAnswers[medQuestion.question_id+'medication3']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.2.medication_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Dosage</label>
                              <input class="custom-input"
                                     placeholder="Enter Dosage"
                                     type="text"
                                     name="symptoms"
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.2.dosage_message']}"
                                     value=""
                                     v-model="medAnswers[medQuestion.question_id+'dosage3']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.2.dosage_message']}}</span>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                              <label>Medical Condition</label>
                              <input class="custom-input"
                                     placeholder="Enter Medical Condition"
                                     type="text"
                                     name="symptoms"
                                     value=""
                                     @input="removeError();"
                                     :class="{'form-control is-invalid': updateformErrors['health_info.medication_question.medications.2.medical_condition_message']}"
                                     v-model="medAnswers[medQuestion.question_id+'medical_condition3']">
                              <span class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['health_info.medication_question.medications.2.medical_condition_message']}}</span>
                            </div>
                          </div>
                        </ul>
                      </vue-scroll>
                      <div class="additional_notes" style="margin-top:2rem;">
                        <label><b>Additional Notes</b></label>
                        <textarea class="notes custom-input" v-model="notes" rows="4"></textarea>
                      </div>
                    </div>
                    <div class="form-group row justify-content-end">
                      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <button class="dark-univ-btn" type="button" name="button"
                                @click="cancelUpdateDepData()">Cancel</button>
                      </div>
                      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-2">
                        <button class="submit univ-btn" type="button" name="button" @click="validateData('update', depData.length)">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
            <div class="action-btn split-title mt-5">
                <router-link to="" class="dark-btn" @click.native="navigatePrevious">Back</router-link>
                <!-- L7123 Enrollment Validation Section  -->
                <div class="action-next" v-if="isPremierPlan && enrollType !== 'existing-member'">
                  <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
                  <router-link to="" class="button-brand  tooltip-hover" @click.native="saveData"
                               v-if="depData.length >= 2 && tier == 'IF'">Next   <span class="tooltiptext">{{next_title}}</span></router-link>
                  <router-link to="" class="button-brand tooltip-hover" @click.native="saveData"
                               v-if="depData.length == 1 && tier == 'IC'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
                  <router-link to="" class="button-brand tooltip-hover" @click.native="saveData"
                               v-if="depData.length > 0 && tier == 'IS'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
                </div>
               <div class="action-next" v-else-if="isPremierPlan && enrollType === 'existing-member' && isSpouseEnrollAccept">
                <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
                <router-link to="" class="button-brand tooltip-hover" @click.native="saveData"
                             v-if="depData.length >= 2 && tier == 'IF'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
                <router-link to="" class="button-brand  tooltip-hover" @click.native="saveData"
                             v-if="depData.length == 1 && tier == 'IC'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
                <router-link to="" class="button-brand tooltip-hover" @click.native="saveData"
                             v-if="depData.length > 0 && tier == 'IS'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
               </div>
               <div class="action-next" v-else-if="isPremierPlan && enrollType === 'existing-member' && (tier == 'IC' || tier == 'IS')">
                  <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
                  <router-link class="button-brand  tooltip-hover" to="" @click.native="saveData"
                               v-if="depData.length >= 2 && tier == 'IF'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
                  <router-link class="button-brand tooltip-hover" to="" @click.native="saveData"
                               v-if="depData.length == 1 && tier == 'IC'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
                  <router-link class="button-brand tooltip-hover" to="" @click.native="saveData"
                               v-if="depData.length > 0 && tier == 'IS'">Next <span class="tooltiptext">{{next_title}}</span></router-link>
                </div>
                <div class="action-next" v-else-if="isPremierPlan && enrollType === 'existing-member' && !isSpouseEnrollAccept">
                  <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
                </div>
                <div class="action-next" v-else>
                    <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
                    <router-link to="" class="button-brand tooltip-hover" @click.native="saveData" v-if="depData.length > 1 && tier == 'IF' && checkExistingSpouse == true">Next <span class="tooltiptext">{{next_title}}</span> </router-link>
                    <router-link to="" class="button-brand tooltip-hover" @click.native="saveData" v-if="depData.length > 0 && tier == 'IC'">Next  <span class="tooltiptext">{{next_title}}</span> </router-link>
                    <router-link to="" class="button-brand tooltip-hover" @click.native="saveData" v-if="depData.length > 0 && tier == 'IS'">Next <span class="tooltiptext">{{next_title}}</span> </router-link>
                </div>
            </div>
        </div>
      <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <div  v-if="saveSuccess">
              <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
            </div>
            <div v-else>
              <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
            </div>
            <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div v-if="saveSuccess==false" class="input_box client_email">
            <input type="text"
                   class="custom-input"
                   :class="{'form-control is-invalid': email && !isEmailValid || isEmailNull}"
                   @input="emailValid(); removeError();"
                   v-model="email" value="" />
            <div class="form-group__message  ml-3 mt-2" v-if="email && !isEmailValid || isEmailNull">
              Enter valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="popUp-details text-center">
            <div v-if="saveSuccess" class="action-btn text-center mt-4">
              <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
            </div>
            <div v-else class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>
    </section>
</template>

<script>
/*eslint-disable */
import axios from 'axios';
import html2canvas from 'html2canvas';
import {required} from "vuelidate/lib/validators";
import {checkEmail, checkSsn, validName, checkZero, checkInch} from "../../utils/validators";
export default {
    name: "DependentInformation",
    data: function () {
        return {
            tempId: '',
            // formFields: [],
            formFields: {
                first_name: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                middle_name: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                last_name: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                height_feet: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                height_inch: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                weight: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                gender: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                birthdate: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                ssn: {
                    value: '',
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                relationship: {
                    value: 0,
                    required: false,
                    read_only: false,
                    error: false,
                    message: '',
                },
                dependent_id: {
                  value: 0
                }
            },
            depData: [],
            relOptions: [],
            category: '',
            visible: false,
            visibleEdit: false,
            editDep: 0,
            formErrors: [],
            updateformErrors: [],
            formErrorFlag: false,
            mainErrorFlag: false,
            mainErrors: '',
            updateformErrorFlag: false,
            questions: [],
            medQuestion: [],
            yn: [],
            yesNoAns: [],
            answers: [],
            medAnswers: [],
            notes: '',
            finalDataCondAns: [],
            finalDataMedAns: [],
            tier: '',
            hqReq: false,
            spouseReq: false,
            spouseErr: false,
            childReq: false,
            childErr: false,
            savePopup: false,
            email: '',
            saveSuccess: false,
            enrollType: '',
            checkExistingSpouse: false,
            haveSpouse : 0,
            disable_delete: false,
            disable_dep: false,
            Delete_title: 'Delete',
            guide:'',
            next_title: 'Click to continue enrollment',
            add_dep: 'Click to add dependent',
            dependent_id: '',
            isEmailNull: false,
            isEmailValid: false,
            genderOptions: [
              { value: null, text: 'Select Gender', disabled: true},
              { value: '0', text: 'Male' },
              { value: '1', text: 'Female'}
            ],
            genderEditOptions: [
              { value: null, text: 'Select Gender', disabled: true},
              { value: '0', text: 'Male' },
              { value: '1', text: 'Female'}
            ],
            currentTreatmentOption: [
              { value: 'undefined', text: 'Please Select'},
              { value: '1', text: 'Yes' },
              { value: '0', text: 'No'}
            ],
            takingMedicalOption: [
              { value: 'undefined', text: 'Please Select'},
              { value: '1', text: 'Yes' },
              { value: '0', text: 'No'}
            ],
           isPremierPlan: false,
           askForSpouse: false,
           selectSpouse: null,
           isSpouseSelected: false,
           askExistingSpouse: false,
           spouseOption: [
            { value:  null, text: 'Please Select', disabled: true},
            { value: 'Yes', text: 'Yes' },
            { value: 'No', text: 'No'}
           ],
           tempDepData: [],
           checkExistSpouseDep: false,
           isSpouseEnrollAccept: false,
           editPremierPlan: false,
           disclaimer:'',
        }
    },
  validations () {
    if (this.formFields.weight.required && this.formFields.height_feet.required ) {
      return {
        formFields: {
          first_name: {
            value: {
              required,
              validName
            }
          },
          last_name: {
            value: {
              required,
              validName
            }
          },
          gender: {
            value: {
              required
            }
          },
          ssn: {
            value: {
              required,
              checkSsn
            }
          },
          weight: {
            value: {
              required,
              checkZero
            }
          },
          birthdate: {
            value: {
              required
            }
          },
          height_feet: {
            value: {
              required,
              checkZero
            }
          },
          height_inch: {
            value: {
              required,
              checkInch
            }
          }
        },
      }
    } else if (this.formFields.weight.required ) {
      return {
        formFields: {
          first_name: {
            value: {
              required,
              validName
            }
          },
          last_name: {
            value: {
              required,
              validName
            }
          },
          gender: {
            value: {
              required
            }
          },
          ssn: {
            value: {
              required,
              checkSsn
            }
          },
          weight: {
            value: {
              required,
              checkZero
            }
          },
          birthdate: {
            value: {
              required
            }
          }
        }
      }
    } else if (this.formFields.height_feet.required) {
      return {
        formFields: {
          first_name: {
            value: {
              required,
              validName
            }
          },
          last_name: {
            value: {
              required,
              validName
            }
          },
          gender: {
            value: {
              required
            }
          },
          ssn: {
            value: {
              required,
              checkSsn
            }
          },
          birthdate: {
            value: {
              required
            }
          },
          height_feet: {
            value: {
              required,
              checkZero
            }
          },
          height_inch: {
            value: {
              required,
              checkInch
            }
          }
        },
      }
    } else {
      return {
        formFields: {
          first_name: {
            value: {
              required,
              validName
            }
          },
          last_name: {
            value: {
              required,
              validName
            }
          },
          gender: {
            value: {
              required
            }
          },
          ssn: {
            value: {
              required,
              checkSsn
            }
          },
          birthdate: {
            value: {
              required
            }
          }
        }
      }
    }
  },
    mounted() {
        let app = this;
        app.tempId = window.localStorage.getItem('tempId');
        app.enrollType = window.localStorage.getItem('enroll_type');
        app.checkForPremierPlan();
        app.getFilters();
        app.scrollTop();
        app.getFields();
        app.getQuestions();
        app.checkHqReq();
        app.formshow();
        app.fetchDataTemporarySavedData();
        if (!window.localStorage.getItem('first_track') && app.enrollType == 'existing-member') {
            app.fetchInfoExisting();
        }
        else{
        app.fetchExiInfo();
        }

    },
    methods: {
        checkForPremierPlan () {
          let app = this
          axios.get(process.env.VUE_APP_API_BASE+'/get-more-requirement', {
            params: {
              enrollment_id: app.tempId,
            }
          })
              .then(function (response) {
                app.isPremierPlan = response.data.data
                if (app.isPremierPlan && app.depData.length <= 0 ) {
                  app.askForSpouse = true
                } else {
                  app.askForSpouse = false
                }
              })
              .catch(function (error) {
                console.log(error)
              });
        },
        spouseStatus () {
          let app = this
          if (app.selectSpouse === null) {
              app.isSpouseSelected = true
          } else {
            app.askForSpouse = false
            if (app.selectSpouse === 'Yes') {
              if (app.enrollType == 'existing-member') {
                let isSpouseAvailable = false;
                let checkDepSpouse = false;
                 isSpouseAvailable = app.depData.some(element => {
                  return element.primary_info.relationship.toLowerCase() == 'spouse'
                })
                app.checkExistingSpouse = true
                app.askExistingSpouse = false
                checkDepSpouse = app.depData.some( ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
                if (!checkDepSpouse) {
                  app.tempDepData = JSON.parse(window.localStorage.getItem('tempDepData'))
                  if (app.tempDepData) {
                    app.depData.splice(0, 0, app.tempDepData[0])
                    app.checkExistingSpouse = true
                    app.selectSpouse = 'No'
                    // app.saveDependentData();
                  }
                 if (app.tempDepData == null) {
                   app.askExistingSpouse = true
                   app.checkExistingSpouse = false
                 }
                }
              } else {
                app.askForSpouse = false
                app.checkExistingSpouse = false
              }
              app.isSpouseEnrollAccept = true
              localStorage.setItem('isSpouseEnrollAccept', true)
            } else {
              if (app.enrollType == 'existing-member') {
                let removeArrayStatus = false;
                let removedArray = [];
                let checkDepSpouse = false;
                checkDepSpouse = app.depData.some( ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
                if (checkDepSpouse) {
                  let index = null
                  index = app.depData.findIndex(ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
                  removedArray = app.depData.splice(index, 1);
                }
                removeArrayStatus = removedArray.some( ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
                if (removeArrayStatus) {
                  localStorage.setItem('tempDepData',  JSON.stringify(removedArray));
                }
                // app.saveDependentData();
              } else {
                app.checkExistingSpouse = true
                app.haveSpouse = 1
                app.spouseReq = false
                app.childReq = true
              }
              app.isSpouseEnrollAccept = true
              localStorage.setItem('isSpouseEnrollAccept', true)
            }
          }
        },
        removeSpouseError () {
          let app = this
          if (app.selectSpouse === 'Yes' || app.selectSpouse === 'No') {
            app.isSpouseSelected = false
          }
        },
        cancelUpdateDepData () {
          let app = this;
          app.visibleEdit = false;
          app.disable_delete = false;
          app.Delete_title = 'Delete';
          app.disable_dep = false;
          app.add_dep = 'Click to add dependent';
          app.formErrors= [];
          app.updateformErrors= [];
          app.formErrorFlag= false;
          app.updateformErrorFlag= false;
          app.answers= [];
          app.medAnswers = [];
          app.notes = '';
          app.visible = false;
          app.dependent_id = '';
          app.formFields.first_name.value = '';
          app.formFields.middle_name.value = '';
          app.formFields.last_name.value = '';
          app.formFields.birthdate.value = '';
          app.formFields.ssn.value = '';
          app.formFields.relationship.value = null;
          app.formFields.gender.value = null;
          app.formFields.height_feet.value = '';
          app.formFields.height_inch.value = '';
          app.formFields.weight.value = '';
        },
        cancelDepData () {
          let app = this;
          app.visible = false;
          app.disable_delete = false;
          app.Delete_title = 'Delete';
          app.disable_dep = false;
          app.add_dep = 'Click to add dependent';
          app.formErrors= [];
          app.updateformErrors= [];
          app.formErrorFlag= false;
          app.updateformErrorFlag= false;
          app.answers= [];
          app.medAnswers = [];
          app.notes = '';
          app.visible = false;
          app.dependent_id = '';
          app.formFields.first_name.value = '';
          app.formFields.middle_name.value = '';
          app.formFields.last_name.value = '';
          app.formFields.birthdate.value = '';
          app.formFields.ssn.value = '';
          app.formFields.relationship.value = null;
          app.formFields.gender.value = null;
          app.formFields.height_feet.value = '';
          app.formFields.height_inch.value = '';
          app.formFields.weight.value = '';
        },
        askedForAddedDep () {
          let app = this
          app.askForSpouse = true;
          app.askExistingSpouse = true;
          app.editPremierPlan = true;
        },
        spouseEditStatus () {
        let app = this
        if (app.selectSpouse === null) {
          app.isSpouseSelected = true
        } else {
          app.askForSpouse = false
          if (app.selectSpouse === 'Yes') {
            if (app.enrollType == 'new-group-member' || app.enrollType == 'new-member') {
              let isSpouseAvailable = false;
              let checkDepSpouse = false;
              isSpouseAvailable = app.depData.some(element => {
                return element.primary_info.relationship.toLowerCase() == 'spouse'
              })
              app.checkExistingSpouse = true
              app.askExistingSpouse = false
              checkDepSpouse = app.depData.some( ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
              if (!checkDepSpouse) {
                app.tempDepData = JSON.parse(window.localStorage.getItem('tempDepData'))
                if (app.tempDepData) {
                  app.depData.splice(0, 0, app.tempDepData[0])
                  app.checkExistingSpouse = true
                  app.selectSpouse = 'No'
                  // app.saveDependentData();
                }
                if (app.tempDepData == null) {
                  app.askExistingSpouse = true
                  app.checkExistingSpouse = false
                }
              }
            } else {
              app.askForSpouse = false
              app.checkExistingSpouse = false
            }
            app.isSpouseEnrollAccept = true
            localStorage.setItem('isSpouseEnrollAccept', true)
          } else {
            if (app.enrollType == 'new-group-member' || app.enrollType == 'new-member') {
              let removeArrayStatus = false;
              let removedArray = [];
              let checkDepSpouse = false;
              checkDepSpouse = app.depData.some( ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
              if (checkDepSpouse) {
                let index = null
                index = app.depData.findIndex(ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
                removedArray = app.depData.splice(index, 1);
              }
              removeArrayStatus = removedArray.some( ele => ele.primary_info.relationship.toLowerCase() == 'spouse')
              if (removeArrayStatus) {
                localStorage.setItem('tempDepData',  JSON.stringify(removedArray));
              }
              // app.saveDependentData();
            } else {
              app.checkExistingSpouse = true
              app.haveSpouse = 1
              app.spouseReq = false
              app.childReq = true
            }
            app.isSpouseEnrollAccept = true
            localStorage.setItem('isSpouseEnrollAccept', true)
          }
        }
      },
        fetchInfoExisting: function () {
            let app = this;
          axios.get(process.env.VUE_APP_API_BASE+'/get-dependents-existing-user-enrollment', {
              params: {
                  enrollment_id: app.tempId,
              }
          })
          .then(function (response) {
            console.log(response);
                // let fetchedData = [];
                if (response.data.status == 'success') {
                    // app.depData = response.data.data.result;
                    if (response.data.data.result.dependents == null || response.data.data.result.dependents.length == 0) {
                        app.depData = [];
                    }else{
                        app.depData = [];
                        app.haveSpouse = 0;
                        let receiver = [];
                        receiver = response.data.data.result.dependents;
                        Object.values(receiver).forEach(function (item) {
                                if(app.tier == 'IS'){
                                    if (item.primary_info.relationship.toLowerCase() == 'spouse' && app.haveSpouse == 0) {
                                        app.depData.push(item);
                                        app.checkExistingSpouse = true;
                                        app.spouseReq = false;
                                        app.haveSpouse = 1;
                                        }
                                }
                                if(app.tier == 'IC'){
                                    if (item.primary_info.relationship.toLowerCase() == 'child') {
                                            app.depData.push(item);
                                    }
                                }
                                if(app.tier == 'IF'){
                                    if(item.primary_info.relationship.toLowerCase() == 'spouse' && app.haveSpouse == 0){
                                        app.checkExistingSpouse = true;
                                        app.spouseReq = false;
                                        app.haveSpouse = 1;
                                        app.depData.push(item);
                                    }else if(item.primary_info.relationship.toLowerCase() != 'spouse'){
                                        app.haveSpouse = 0;
                                        app.depData.push(item);
                                    }

                                }
                        });
                        app.depData.forEach(item => {
                        if(item.health_info != undefined){
                          if(item.health_info.length == 0){
                            item.health_info = Object.assign({}, item.health_info);
                            item.health_info['condition_questions'] = app.questions;
                            item.health_info.condition_questions.forEach(element => {
                            element['conditions'] = [];
                            element['yes_no_flag'] = "0";

                        });
                            item.health_info.medication_question = {};
                            item.health_info.medication_question['question_id'] = app.medQuestion['question_id'];
                            item.health_info.medication_question['question_text'] = app.medQuestion['question_text'];
                            item.health_info.medication_question['yes_no_flag'] = "0";
                          }
                          else{
                        item.health_info.condition_questions.forEach(element => {
                            app.questions.forEach(item => {
                                    if(element['question_id'] == item['question_id']){
                                        element['question_text'] = item['question_text'];
                                    }
                            });
                        });
                        item.health_info.medication_question['question_text'] = app.medQuestion['question_text'];
                        }
                      }
                    });
                    app.saveDependentData();
                    // localStorage.removeItem('enrollment')
                    // localStorage.setItem("dep_sync_temp_id",app.tempId);
                    app.fetchDataTemporarySavedData();
                    }
                }
            })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
                app.fetchInfo();
            }
        });
      },
        checkValue: function(str, max) {
            if (str.charAt(0) !== '0' || str == '00') {
              var num = parseInt(str);
              if (isNaN(num) || num <= 0 || num > max) num = 1;
              str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
            }
            return str;
        },
        eventBirthDob: function() {
            let app = this;
            var input = app.formFields.birthdate.value;
            if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
            var values = input.split('/').map(function(v) {
              return v.replace(/\D/g, '')
            });
            if (values[0]) values[0] = app.checkValue(values[0], 12);
            if (values[1]) values[1] = app.checkValue(values[1], 31);
            var output = values.map(function(v, i) {
              return v.length == 2 && i < 2 ? v + '/' : v;
            });
            app.formFields.birthdate.value = output.join('').substr(0, 10);
        },
        eventDob: function(questionid,input,name) {
            let app = this;
            if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
            var values = input.split('/').map(function(v) {
              return v.replace(/\D/g, '')
            });
            if (values[0]) values[0] = app.checkValue(values[0], 12);
            if (values[1]) values[1] = app.checkValue(values[1], 31);
            var output = values.map(function(v, i) {
              return v.length == 2 && i < 2 ? v + '/' : v;
            });
            app.answers[questionid+name] = output.join('').substr(0, 10);
        },
        getFilters: function() {
            let app = this;
            axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
              params: {
                enrollment_id: app.tempId,
                column: 'filter_info'
              }
            })
            .then(function (response) {
                console.log(response.data.data.result);
                if (response.data.status == 'success') {
                    app.tier = response.data.data.result.tier;
                    if (response.data.data.result.tier == 'IS' || response.data.data.result.tier == 'IF') {
                        app.spouseReq = true;
                    }else{
                        app.spouseReq = false;
                    }
                    if (response.data.data.result.tier == 'IC' || response.data.data.result.tier == 'IF' || response.data.data.result.tier == 'IC3') {
                        app.childReq = true;
                    }else{
                        app.childReq = false;
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        checkHqReq: function() {
            let app = this;
            axios.get(process.env.VUE_APP_API_BASE+'/check-tab-required', {
              params: {
                enrollment_id: app.tempId,
                tab_name: 'primary_health_info',
              }
            })
            .then(function (response) {
                console.log(response.data.data.result);
                if (response.data.status == 'success') {
                    if (response.data.data.result.required == true) {
                        app.hqReq = true;
                    }else{
                        app.hqReq = false;
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getFields: function () {
            let app = this;
            axios.get(process.env.VUE_APP_API_BASE+'/get-form-fields-dependent-primary-info', {
            params: {
                enrollment_id: app.tempId,
            }
            })
            .then(function (response) {
                console.log(response.data.data);
                if (response.data.status == 'success') {
                  app.disclaimer= response.data.data.disclaimer?response.data.data.disclaimer:'';
                    response.data.data.result.forEach(function(item) {
                     
                      if(item.field_name=='birthdate'){
                        app.formFields[item.field_name] = item;
                        let date=app.formFields.birthdate.value;
                        if(date){
                        const [year, month, day] = date.split('-');
                        const result = [month, day, year].join('/');
                        app.formFields.birthdate.value=result;
                        }
                      }else{
                        app.formFields[item.field_name] = item;
                      }
                    });
                }
                // app.fetchInfo();
                console.log(app.formFields);
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getQuestions: function() {
            let app = this;
            axios.get(process.env.VUE_APP_API_BASE+'/get-health-questions-dependent-info', {
              params: {
                enrollment_id: app.tempId,
              }
            })
            .then(function (response) {
                console.log(response.data.data.result.codition_questions);
                if (response.data.status == 'success') {
                    app.questions = response.data.data.result.codition_questions;
                    app.medQuestion['question_id'] = response.data.data.result.medication_question.question_id;
                    app.medQuestion['question_text'] = response.data.data.result.medication_question.question_text;
                    response.data.data.result.codition_questions.forEach(function(item) {
                        app.yesNoAns['q'+item.question_id] = 0;
                        app.yn[item.question_id] = 0;
                    });
                    app.yesNoAns['q'+app.medQuestion.question_id] = 0;
                    app.yn[app.medQuestion.question_id] = 0;
                }
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        fetchExiInfo: async function () {
            let app = this;
            if (app.enrollType == 'new-member' || app.enrollType == 'new-group-member') {
              axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
              params: {
                  enrollment_id: app.tempId,
                  column: 'dependent_info',
              }
              })
               .then(function (response) {
                // let fetchedData = [];
                if (response.data.status == 'success') {
                    // app.depData = response.data.data.result;
                    if (response.data.data.result == null) {
                        app.depData = [];
                        app.getFields();
                    }else{
                        app.askForSpouse = false;
                        app.depData = [];
                        app.haveSpouse = 0;
                        let receiver = [];
                        receiver = response.data.data.result;
                        Object.values(receiver).forEach(function (item) {
                                if(app.tier == 'IS'){
                                     app.askForSpouse = false;
                                    if (item.primary_info.relationship.toLowerCase() == 'spouse') {
                                        app.depData.push(item);
                                        app.checkExistingSpouse = true;
                                        app.spouseReq = false;
                                        }
                                }
                                if(app.tier == 'IC'){
                                    app.askForSpouse = false;
                                    if (item.primary_info.relationship.toLowerCase() == 'child') {
                                            app.depData.push(item);
                                    }
                                }
                                if(app.tier == 'IF'){
                                    app.askForSpouse = false;
                                    if(item.primary_info.relationship.toLowerCase() == 'spouse'){
                                        app.checkExistingSpouse = true;
                                        app.spouseReq = false;
                                        app.haveSpouse = 1;
                                    }else{
                                        app.haveSpouse = 0;
                                    }
                                    app.depData.push(item);
                                }
                        });
                    }
                }
            })
            .catch(function (error) {
                if (error.response.data.status == 'error') {
                    app.fetchInfo();
                }
            });
            }
            // else if(localStorage.getItem('dep_sync_temp_id') != app.tempId || localStorage.getItem('enrollment') == 'new_enrollment' ){
            //   axios.get(process.env.VUE_APP_API_BASE+'/get-dependents-existing-user-enrollment', {
            //   params: {
            //       enrollment_id: app.tempId,
            //   }
            //   })
            //   .then(function (response) {
            //     console.log(response);
            //     // let fetchedData = [];
            //     if (response.data.status == 'success') {
            //         // app.depData = response.data.data.result;
            //         if (response.data.data.result.dependents == null || response.data.data.result.dependents.length == 0) {
            //             app.depData = [];
            //         }else{
            //             app.depData = [];
            //             app.haveSpouse = 0;
            //             let receiver = [];
            //             receiver = response.data.data.result.dependents;
            //             Object.values(receiver).forEach(function (item) {
            //                     if(app.tier == 'IS'){
            //                         if (item.primary_info.relationship.toLowerCase() == 'spouse') {
            //                             app.depData.push(item);
            //                             app.checkExistingSpouse = true;
            //                             app.spouseReq = false;
            //                             }
            //                     }
            //                     if(app.tier == 'IC'){
            //                         if (item.primary_info.relationship.toLowerCase() == 'child') {
            //                                 app.depData.push(item);
            //                         }
            //                     }
            //                     if(app.tier == 'IF'){
            //                         if(item.primary_info.relationship.toLowerCase() == 'spouse'){
            //                             app.checkExistingSpouse = true;
            //                             app.spouseReq = false;
            //                             app.haveSpouse = 1;
            //                         }else{
            //                             app.haveSpouse = 0;
            //                         }
            //                         app.depData.push(item);
            //                     }
            //             });
            //         }
            //             app.depData.forEach(item => {
            //             item.health_info.condition_questions.forEach(element => {
            //                 app.questions.forEach(item => {
            //                         if(element['question_id'] == item['question_id']){
            //                             element['question_text'] = item['question_text'];
            //                         }
            //                 });
            //             });
            //             item.health_info.medication_question['question_text'] = app.medQuestion['question_text'];
            //         });
            //         app.saveDependentData();
            //         localStorage.removeItem('enrollment')
            //         localStorage.setItem("dep_sync_temp_id",app.tempId);
            //         app.fetchDataTemporarySavedData();
            //     }
            // })
            // .catch(function (error) {
            //     if (error.response.data.status == 'error') {
            //         app.fetchInfo();
            //     }
            // });
            // }
             else {
               await app.fetchDataTemporarySavedData();
            }
        },
        removeDep: function(index) {
            let app = this;
            if(app.disable_delete == true){
                return false;
            }
             else{
                var arr = app.depData;
                if(app.tier == 'IF'){
                   if(app.depData[index].primary_info.relationship.toLowerCase() == 'spouse'){
                    app.checkExistingSpouse = false;
                  }
                }
                if(app.tier == 'IS'){
                    app.spouseReq = true;
                }

              // L713 checking for spouse on depData if spouse exist or not if exist then saving that spouse to localstorage
              if (app.tier == 'IF' && app.isPremierPlan && app.enrollType == 'existing-member') {
                let removeArrayStatus = false;
                let removedArray = [];
                removedArray = app.depData.splice(index, 1);
                removeArrayStatus = removedArray.some( ele => ele.primary_info.relationship == 'Spouse')
                if (removeArrayStatus) {
                  localStorage.setItem('tempDepData',  JSON.stringify(removedArray));
                }
              } else {
                app.depData.splice(index, 1);
              }

              if (app.tier == 'IF' && app.isPremierPlan && app.depData.length < 1) {
                app.askForSpouse = true;
                app.checkExistingSpouse = false;
              }

              //app.saveDependentData();
            }
        },
      validateData: function(action, index) {
        if (this.$v.$invalid) {
          this.$v.$touch();
          return false
        } else {
          let app = this;
          app.formErrors= [];
          app.updateformErrors= [];
          app.formErrorFlag= false;
          app.updateformErrorFlag= false;
          app.showAddDependent = false
          console.log(app.hqReq);
          // if (app.enrollType == 'new-member') {
          //   let primary_info = {
          //     first_name:   app.formFields.first_name.value,
          //     last_name:    app.formFields.last_name.value,
          //     birthdate:    app.formFields.birthdate.value,
          //     ssn:          app.formFields.ssn.value,
          //     relationship: app.formFields.relationship.value,
          //     weight:       app.formFields.weight.value,
          //     height_inch:  app.formFields.height_inch.value,
          //     height_feet:  app.formFields.height_feet.value,
          //     gender:       app.formFields.gender.value,
          //     employed:     '0',
          // };
          // }else{
          let primary_info = {
            dependent_id: app.dependent_id,
            first_name:   app.formFields.first_name.value,
            last_name:    app.formFields.last_name.value,
            birthdate:    app.formFields.birthdate.value,
            ssn:          app.formFields.ssn.value,
            relationship: app.formFields.relationship.value,
            weight:       app.formFields.weight.value,
            height_inch:  app.formFields.height_inch.value,
            height_feet:  app.formFields.height_feet.value,
            gender:       app.formFields.gender.value,
            employed:     '0',
          };
          // }
          //discard null values
          if (app.isPremierPlan) {
            console.log(primary_info)
            if (app.depData.length == 0 && app.tier == 'IF' && app.selectSpouse == 'Yes' && action !== 'update') {
              primary_info.relationship = 'spouse';
              app.checkExistingSpouse = true;
            } else if (app.depData.length > 0 && app.tier == 'IF' && app.selectSpouse == 'Yes' && action !== 'update' && app.askExistingSpouse) {
              primary_info.relationship = 'spouse';
              app.checkExistingSpouse = true;
            } else {
              if (action !== 'update') {
                primary_info.relationship = 'child';
                app.checkExistingSpouse = false;
              }
            }
            if (action == 'update') {
              app.disable_delete = false;
              app.Delete_title = 'Delete';
              app.next_title = 'Click to continue enrollment';
              app.disable_dep = false;
              app.add_dep = 'Click to add dependent';
              console.log(primary_info.relationship);
            } else {
              if (app.tier == 'IS') {
                primary_info.relationship = 'spouse';
              } else if (app.tier == 'IC') {
                primary_info.relationship = 'child';
              } else {
                if (primary_info.relationship == 'spouse') {
                  primary_info.relationship = 'spouse';
                } else {
                  primary_info.relationship = 'child';
                }
              }
            }
          } else {
            if (primary_info.relationship == 'child' && app.tier == 'IF' && app.haveSpouse == 0) {
              app.checkExistingSpouse = false;
              app.haveSpouse = 1;
            }
            if (action == 'update') {
              app.disable_delete = false;
              app.Delete_title = 'Delete';
              app.next_title = 'Click to continue enrollment';
              app.disable_dep = false;
              app.add_dep = 'Click to add dependent';
              console.log(primary_info.relationship);
            } else {
              if (app.tier == 'IS') {
                primary_info.relationship = 'spouse';
              } else if (app.tier == 'IC') {
                primary_info.relationship = 'child';
              } else {
                if (app.haveSpouse == 0) {
                  primary_info.relationship = 'spouse';
                  app.checkExistingSpouse = true;
                } else {
                  primary_info.relationship = 'child';
                  app.checkExistingSpouse = false;
                }
              }
            }
          }
          // get all hq
          app.finalDataCondAns = [];
          app.finalDataMedAns  = [];
          // let finalData = [];
          app.questions.forEach(function(item) {
            let row = {};
            let rowAns1 = {};
            // let rowAns2 = {};
            row['conditions'] = [];
            if (app.yesNoAns['q'+item.question_id] == 1) {
              console.log("here");
              row['question_id'] = item.question_id;
              row['question_text'] = item.question_text;
              row['yes_no_flag'] = 1;
              rowAns1['health_condition'] = app.answers[item.question_id+'health_condition1'];
              rowAns1['date_of_onset'] = app.answers[item.question_id+'date_of_onset1'];
              rowAns1['date_of_recovery'] = app.answers[item.question_id+'date_of_recovery1'];
              rowAns1['is_treatment'] = app.answers[item.question_id+'is_treatment1'];
              rowAns1['is_medicate'] = app.answers[item.question_id+'is_medicate1'];
              rowAns1['d_last_seen'] = app.answers[item.question_id+'d_last_seen1'];
              rowAns1['symptoms'] = app.answers[item.question_id+'symptoms1'];
              row['conditions'].push(rowAns1);
              let x = JSON.stringify(row);
              console.log(x);
              // row['conditions'].push(rowAns2);
              app.finalDataCondAns.push(row);
              console.log(app.finalDataCondAns);
            }else{
              row['question_id'] = item.question_id;
              row['question_text'] = item.question_text;
              row['yes_no_flag'] = 0;
              app.finalDataCondAns.push(row);
            }
          });
          let subData2 = '';
          if (app.medQuestion.question_id > 0 && app.yesNoAns['q'+app.medQuestion.question_id] == 1) {
            let rowMed = {};
            let rowMedAns1 = {};
            let rowMedAns2 = {};
            let rowMedAns3 = {};
            rowMed['question_id'] = app.medQuestion.question_id;
            rowMed['question_text'] = app.medQuestion.question_text;
            rowMed['yes_no_flag'] = 1;
            rowMed['medications'] = [];
            rowMedAns1['medication'] = app.medAnswers[app.medQuestion.question_id+'medication1'];
            rowMedAns1['dosage'] = app.medAnswers[app.medQuestion.question_id+'dosage1'];
            rowMedAns1['medical_condition'] = app.medAnswers[app.medQuestion.question_id+'medical_condition1'];
            rowMedAns2['medication'] = app.medAnswers[app.medQuestion.question_id+'medication2'];
            rowMedAns2['dosage'] = app.medAnswers[app.medQuestion.question_id+'dosage2'];
            rowMedAns2['medical_condition'] = app.medAnswers[app.medQuestion.question_id+'medical_condition2'];
            rowMedAns3['medication'] = app.medAnswers[app.medQuestion.question_id+'medication3'];
            rowMedAns3['dosage'] = app.medAnswers[app.medQuestion.question_id+'dosage3'];
            rowMedAns3['medical_condition'] = app.medAnswers[app.medQuestion.question_id+'medical_condition3'];
            rowMed['medications'].push(rowMedAns1);
            if ((rowMedAns2['medication'] == '' || rowMedAns2['medication'] == undefined) && (rowMedAns2['dosage'] == '' || rowMedAns2['dosage'] == undefined) && (rowMedAns2['medical_condition'] == '' || rowMedAns2['medical_condition'] == undefined)) {
              //do notthing
            }else{
              rowMed['medications'].push(rowMedAns2);
            }
            if ((rowMedAns3['medication'] == '' || rowMedAns3['medication'] == undefined) && (rowMedAns3['dosage'] == '' || rowMedAns3['dosage'] == undefined) && (rowMedAns3['medical_condition'] == '' || rowMedAns3['medical_condition'] == undefined)) {
              //do notthing
            }else{
              rowMed['medications'].push(rowMedAns3);
            }
            let y = JSON.stringify(rowMed);
            app.finalDataMedAns.push(y);
            subData2 = JSON.parse(app.finalDataMedAns);
          }else{
            subData2 = {
              "medications": [],
              "question_id": app.medQuestion.question_id,
              "question_text": app.medQuestion.question_text,
              "yes_no_flag": 0,
            };
          }
          console.log(action);
          console.log(primary_info);
          console.log(app.finalDataCondAns);
          console.log(subData2);
          console.log(app.editDep);
          let depFinal = {};
          let subDep = {};
          if (app.hqReq == true) {
            depFinal = {
              "enrollment_id": app.tempId,
              "primary_info": primary_info,
              "health_info": {
                "notes": app.notes,
                "condition_questions": app.finalDataCondAns,
                "medication_question": subData2,
              }
            };
            subDep = {
              "dependents": [{
                "primary_info": primary_info,
                "health_info": {
                  "notes": app.notes,
                  "condition_questions": app.finalDataCondAns,
                  "medication_question": subData2,
                }
              }],
            };
          }else{
            depFinal = {
              "enrollment_id": app.tempId,
              "primary_info": primary_info,
            };
            subDep = {
              "dependents": [{
                "primary_info": primary_info,
              }],
            };
          }
          //empty the new form values
          let el = '';
          if (action == 'update') {
            el = app.$refs.savedep;
          } else {
            el = app.$refs.savedep;
          }
          (async () => {
            this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'dependent_info_'+action+depFinal.primary_info.first_name);
          })();
          axios.post(process.env.VUE_APP_API_BASE+'/validate-single-dependent', depFinal)
              .then(function (response) {
                if (response.data.status == 'success') {
                  console.log("success");
                  if (action == 'update') {
                    app.depData[app.editDep] = subDep.dependents[0];
                    app.closePopEdit(app.editDep);
                    app.showPop();
                    app.scrollTop();
                  }else{
                    app.depData.push(subDep.dependents[0]);
                    app.scrollTop();
                  }
                  app.formErrors= [];
                  app.updateformErrors= [];
                  app.formErrorFlag= false;
                  app.updateformErrorFlag= false;
                  app.yn = [];
                  app.yesNoAns = [];
                  app.answers= [];
                  app.medAnswers = [];
                  app.notes = '';
                  app.visible = false;
                  app.dependent_id = '';
                  app.formFields.first_name.value = '';
                  app.formFields.middle_name.value = '';
                  app.formFields.last_name.value = '';
                  app.formFields.birthdate.value = '';
                  app.formFields.ssn.value = '';
                  app.formFields.relationship.value = null;
                  app.formFields.gender.value = null;
                  app.formFields.height_feet.value = '';
                  app.formFields.height_inch.value = '';
                  app.formFields.weight.value = '';
                  app.saveDependentData();
                  app.getQuestions();
                  app.getFields();
                  app.fetchExiInfo();
                  app.$v.$reset();
                }
              })
              .catch(function (error) {
                console.log(error);
                app.showAddDependent = true;
                if(app.checkExistingSpouse == false){
                  app.checkExistingSpouse = true;
                }else{
                  app.checkExistingSpouse = false;
                }
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function(item) {
                    if (action == 'update') {
                      console.log(item.error_message);
                      app.updateformErrorFlag = true;
                      app.updateformErrors[item.target_element+'_message'] = item.error_message;
                    } else {
                      app.formErrorFlag = true;
                      app.formErrors[item.target_element+'_message'] = item.error_message;
                    }
                  });
                }
              });
        }
      },
        saveData: async function () {
           let app = this;
           if(app.disable_delete == true){
                return false;
            }
            else{
                app.mainErrorFlag = false;
          // await Promise.all(this.depData.map(async (key,data) => {
          //       await app.validateExistingDependentData(data,key);
          // }))
          if(app.updateformErrorFlag) return;
             const el = this.$refs.savedep;
            (async () => {
                this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'dependent_info');
            })();
            // Submit API
            app.formErrors= [];
            app.updateformErrors= [];
            app.formErrorFlag= false;
            app.updateformErrorFlag= false;
            app.mainErrorFlag = false;
            app.mainErrors = '';
            app.visible=false;

            //empty the new form values
            // let y = JSON.parse(app.depData);
            axios.post(process.env.VUE_APP_API_BASE+'/save-dependent-info', {"enrollment_id":app.tempId, "dependents": app.depData})
            .then(function (response) {
                if (response.data.status == 'success') {
                    console.log("success");
                    app.isSpouseEnrollAccept = true
                    app.saveAndContinue();
                }
            })
            .catch(function (error) {
                if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                        app.mainErrorFlag = true;
                        app.mainErrors = item.error_message;
                    });
                }
            });
            }
        },
        saveAndContinue() {
            //Update APIs
            //Navigate to next page
            if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
                this.$emit('comEnvNavigation', this.$route.name);
            }else{
                window.localStorage.removeItem('navto');
                this.$router.push('review-confirm');
            }
        },
        navigatePrevious() {
            //Update APIs
            //Navigate to next page
            this.$emit('comEnvNavigationBack', this.$route.name);
        },
        saveScreenShot (image, name) {
            let app = this;
            let data = {
                "enrollment_id" : app.tempId,
                "name" : name,
                "image" : image
            };
            let config = {
                method: 'post',
                url: process.env.VUE_APP_API_BASE+'/save-screenshot',
                headers: { },
                data : data
            };
            axios(config)
                .then(function (response) {
                    if (response.data.status == 'success') {
                        console.log('screenshot saved');
                    }
                })
                .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                });
        },
        showPop(){
          if(this.disable_dep == true){
            return false;
          }
          else{
            if(this.isPremierPlan){
            if(this.depData.length == 0 && this.spouseReq == true && this.tier =='IS'){}
            else if(this.depData.length == 0  && this.selectSpouse == 'Yes'&& this.tier =='IF'){
            }else if(this.depData.length > 0  && this.selectSpouse == 'Yes'&& this.tier =='IF' && this.askExistingSpouse ){}
            else{
            this.formFields.birthdate.value = '';
            }
          }else{
            if(this.depData.length == 0 && this.spouseReq == true && this.tier =='IS'){}
            else if(this.depData.length >= 0  && this.checkExistingSpouse== false&& this.tier =='IF'){
            }else{
            this.formFields.birthdate.value = '';
            }
          }
            this.yesNoAns=[];
            this.answers=[];
            this.medAnswers=[];
            this.visible=true;
            this.updateformErrors= [];
            this.updateformErrorFlag= false;
          }
          this.$nextTick(() => { this.$v.$reset() })
        },
        closePop(){
            this.visible=false;
            this.fetchExiInfo();
        },
        async showPopEdit(val){
            let app = this;
            app.add_dep = 'Please submit the edited form first to add dep';
            app.disable_dep = true;
            app.disable_delete = true;
            app.dependent_id = '';
            app.next_title = 'Please submit the edited form first to continue';
            app.Delete_title = 'Please submit the edited form first to delete';
            app.visible=false;
            app.visibleEdit=true;
            console.log(val);
             app.updateformErrorFlag = false;
            app.formErrorFlag = false;
            app.updateformErrors = [];
            app.formErrors = [];
            app.editDep = val;
            app.dependent_id = app.depData[val].primary_info.dependent_id,
            app.formFields.first_name.value = app.depData[val].primary_info.first_name;
            app.formFields.middle_name.value = app.depData[val].primary_info.middle_name;
            app.formFields.last_name.value = app.depData[val].primary_info.last_name;
            app.formFields.birthdate.value = app.depData[val].primary_info.birthdate;
            app.formFields.ssn.value = app.depData[val].primary_info.ssn;
            app.formFields.relationship.value = app.depData[val].primary_info.relationship;
            app.formFields.gender.value = app.depData[val].primary_info.gender;
            app.formFields.height_feet.value = app.depData[val].primary_info.height_feet;
            app.formFields.height_inch.value = app.depData[val].primary_info.height_inch;
            app.formFields.weight.value = app.depData[val].primary_info.weight;
            console.log(app.depData[val]);
            console.log(app.hqReq);
            if(app.depData[val].hasOwnProperty('health_info')) {
            if (app.hqReq == true && Object.keys(app.depData[val].health_info).length > 0) {
                app.notes=app.depData[val].health_info.notes;
              app.depData[val].health_info.condition_questions.forEach(function(item) {
                if (item.yes_no_flag == 1) {
                    app.yesNoAns['q'+item.question_id] = 1;
                    app.yn[item.question_id] = 1;
                    app.answers[item.question_id+'health_condition1'] = item.conditions[0].health_condition;
                    app.answers[item.question_id+'is_treatment1'] = item.conditions[0].is_treatment;
                    app.answers[item.question_id+'date_of_onset1'] = item.conditions[0].date_of_onset;
                    app.answers[item.question_id+'date_of_recovery1'] = item.conditions[0].date_of_recovery;
                    app.answers[item.question_id+'is_medicate1'] = item.conditions[0].is_medicate;
                    app.answers[item.question_id+'d_last_seen1'] = item.conditions[0].d_last_seen;
                    app.answers[item.question_id+'symptoms1'] = item.conditions[0].symptoms;
                }else{
                    app.yesNoAns['q'+item.question_id] = 0;
                    app.yn[item.question_id] = 0;
                }
              });
            } else {
                app.yesNoAns=[];
                app.yn=[];
                app.answers=[];
            }
        } else {
                app.yesNoAns=[];
                app.yn=[];
                app.answers=[];
            }
         if(app.depData[val].hasOwnProperty('health_info')) {
            if (app.hqReq == true && Object.keys(app.depData[val].health_info).length == 0) {
              // if (app.depData[val].health_info[0].medication_question.yes_no_flag == true) {
                // app.yesNoAns['q'+app.depData[val].health_info.medication_question.question_id] = 1;
                // app.yn[app.depData[val].health_info.medication_question.question_id] = 1;
                app.medAnswers=[];
                // app.medAnswers[app.depData[val].health_info.medication_question.question_id+'medication1'] = '';
                // app.medAnswers[app.depData[val].health_info.medication_question.question_id+'medical_condition1'] = '';
              // }else{
              //     app.yesNoAns['q'+app.depData[val].health_info.medication_question.question_id] = 0;
              //     app.yn[app.depData[val].health_info.medication_question.question_id] = 0;
              // }
            } else if(app.hqReq == true && Object.keys(app.depData[val].health_info).length > 0) {
              if (app.depData[val].health_info.medication_question.yes_no_flag == 1) {
               /* older one */
               /* app.yesNoAns['q'+app.depData[val].health_info.medication_question.question_id] = 1;
                app.yn[app.depData[val].health_info.medication_question.question_id] = 1;
                app.medAnswers[app.depData[val].health_info.medication_question.question_id+'dosage1'] = app.depData[val].health_info.medication_question.medications[0].dosage;
                app.medAnswers[app.depData[val].health_info.medication_question.question_id+'medication1'] = app.depData[val].health_info.medication_question.medications[0].medication;
                app.medAnswers[app.depData[val].health_info.medication_question.question_id+'medical_condition1'] = app.depData[val].health_info.medication_question.medications[0].medical_condition;
                */
                /* new one that fills up every medical answers */
                app.yesNoAns['q'+app.depData[val].health_info.medication_question.question_id] = 1;
                app.yn[app.depData[val].health_info.medication_question.question_id] = 1;
                /* used 3 since we have 3 medication question used 3 */
              app.notes = app.depData[val].health_info.notes;
              for (var i = 1; i <= 3; i++) {
                  if(app.depData[val].health_info.medication_question){
                  let keyOject = app.depData[val].health_info.medication_question.medications[i-1];
                  app.medAnswers[app.depData[val].health_info.medication_question.question_id+'dosage'+i] = (keyOject != undefined) ? keyOject.dosage : '';
                  app.medAnswers[app.depData[val].health_info.medication_question.question_id+'medication'+i] =  (keyOject != undefined) ? keyOject.medication : '';
                  app.medAnswers[app.depData[val].health_info.medication_question.question_id+'medical_condition'+i] =  (keyOject != undefined) ? keyOject.medical_condition : '';
                  }
              }
             }else{
                  app.yesNoAns['q'+app.depData[val].health_info.medication_question.question_id] = 0;
                  app.yn[app.depData[val].health_info.medication_question.question_id] = 0;
              }
            } else {
              app.medAnswers=[];
            }
         } else {
              app.medAnswers=[];
            }
            // }
            app.visibleEdit=true;
            app.formErrors= [];
            app.formErrorFlag= false;
        },
        closePopEdit(val){
            let app = this;
            console.log(val);
            this.editDep = 0;
            app.formFields.first_name.value = '';
            app.formFields.middle_name.value = '';
            app.formFields.last_name.value = '';
            app.formFields.birthdate.value = '';
            app.formFields.ssn.value = '';
            app.formFields.relationship.value = '';
            app.formFields.gender.value = '';
            app.formFields.height_feet.value = '';
            app.formFields.height_inch.value = '';
            app.formFields.weight.value = '';
            if(app.hqReq == true){
             // app.depData[val].health_info.condition_questions = [];
              //app.depData[val].health_info.medication_question= [];
            }
            this.visibleEdit=false;
            this.fetchExiInfo();
        },
        setAns: function(id, type) {
            if (type ==  "yes") {
                this.yesNoAns['q'+id] = 1;
            }
            if (type ==  "no") {
                this.yesNoAns['q'+id] = 0;
            }
            console.log(id+'='+this.yesNoAns['q'+id]);
        },
        saveEnrollment : async function () {
           let app = this;
           app.mainErrorFlag = false;
          await Promise.all(this.depData.map(async (key,data) => {
                await app.validateExistingDependentData(data,key);
          }))
          if(app.updateformErrorFlag) return;
          app.$bvModal.show('code-send');
        },
        closePopup : function () {
          let app = this;
          app.savePopup = false;
        },
        saveAndContinueEnrollment: function () {
          if (!this.isEmailValid) {
            this.isEmailNull = true;
          } else {
            let app = this;
            console.log('save called');
            let data = {
              enrollment_id: app.tempId,
              email: app.email,
              tab: 'dependent_info',
              data: app.depData
            }
            app.formErrorFlag = false;
            app.formErrors = [];
            axios.post(process.env.VUE_APP_API_BASE + '/save-enrollment', data)
                .then(function (response) {
                  if (response.data.status == 'success') {
                    app.saveSuccess = true;
                    console.log("success");
                  }
                })
                .catch(function (error) {
                  if (error.response.data.status == 'error') {
                    console.log('error');
                    error.response.data.data.forEach(function (item) {
                      app.formErrorFlag = true;
                      app.formErrors[item.target_element + '_message'] = item.error_message;
                    });
                    console.log(app.formErrors);
                  }
                });
          }
        },
        scrollTop(){
            window.scrollTo(0,0);
        },
        async saveDependentData () {
            let app = this;
           return axios.post(process.env.VUE_APP_API_BASE+'/save-dependent-info-to-temp', {"enrollment_id":app.tempId, "dependents": app.depData})
            .then(function (response) {
                if (response.data.status == 'success') {
                    window.localStorage.setItem('first_track',true);
                   app.fetchDataTemporarySavedData();
                }
            })
            .catch(function (error) {
            });
        },
        async fetchDataTemporarySavedData() {
          let app = this;
         axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
              params: {
                  enrollment_id: app.tempId,
                  column: 'dependent_info',
              }
              })
              .then(function (response) {
                // let fetchedData = [];
                if (response.data.status == 'success') {
                    // app.depData = response.data.data.result;
                    if (response.data.data.result == null) {
                        // app.depData = [];
                        app.getFields();
                    }else{
                        app.depData = [];
                        app.haveSpouse = 0;
                        let receiver = [];
                        receiver = response.data.data.result;
                        Object.values(receiver).forEach(function (item) {
                                if(app.tier == 'IS'){
                                     if (app.isPremierPlan) {
                                       app.askForSpouse = false;
                                     }
                                    if (item.primary_info.relationship.toLowerCase() == 'spouse') {
                                        app.depData.push(item);
                                        app.checkExistingSpouse = true;
                                        app.spouseReq = false;
                                        }
                                }
                                if(app.tier == 'IC'){
                                    if (app.isPremierPlan) {
                                      app.askForSpouse = false;
                                    }
                                    if (item.primary_info.relationship.toLowerCase() == 'child') {
                                            app.depData.push(item);
                                    }
                                }
                                if(app.tier == 'IF'){
                                    if (app.isPremierPlan) {
                                      app.askForSpouse = false;
                                    }
                                    if(item.primary_info.relationship.toLowerCase() == 'spouse'){
                                        app.checkExistingSpouse = true;
                                        app.spouseReq = false;
                                        app.haveSpouse = 1;
                                    }
                                    app.depData.push(item);
                                }
                        });
                    }
                    console.log('This is a response'+JSON.stringify(app.depData));
                  //checking for spouse in existing case and asking if its not in dependent data for existing case
                    if (app.tier == 'IF' && app.isPremierPlan && app.enrollType == 'existing-member') {
                      app.isSpouseEnrollAccept = JSON.parse(localStorage.getItem('isSpouseEnrollAccept'))
                      if (!app.isSpouseEnrollAccept) {
                        app.askExistingSpouse = true
                        app.askForSpouse = true
                      } else {
                        app.askExistingSpouse = app.depData.some(element => {
                          return element.primary_info.relationship.toLowerCase() == 'spouse'
                        })
                        if (app.askExistingSpouse) {
                          app.askExistingSpouse = false
                          app.askForSpouse = false
                        } else if (app.isSpouseEnrollAccept) {
                          app.askExistingSpouse = false
                          app.askForSpouse = false
                        } else {
                          app.askExistingSpouse = true
                          app.askForSpouse = true
                        }
                      }
                    }
                }
            })
            .catch(function (error) {
                if (error.response.data.status == 'error') {
                    app.fetchInfo();
                }
            });
        },
         validateExistingDependentData(key,data) {
          let app = this;
          if(data.hasOwnProperty('health_info')) {
              if(data.health_info.hasOwnProperty('medication_question')) {
                  data.health_info.medication_question.question_text = app.medQuestion.question_text
              }
          }
          if(app.mainErrorFlag == true) return;
         for (const key of Object.keys(data.primary_info)) {
                if (data.primary_info[key] === null || data.primary_info[key] === "") {
                    delete data.primary_info[key];
                }
            }
          data.enrollment_id = this.tempId
            return axios.post(process.env.VUE_APP_API_BASE+'/validate-single-dependent', data)
            .then(function (response) {
               //app.mainErrorFlag = false;
            })
            .catch(function (error) {
                if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                            console.log(item.error_message);
                            app.updateformErrorFlag = true;
                            app.updateformErrors[item.target_element+'_message'] = item.error_message;
                            app.mainErrorFlag = true;
                            app.mainErrors = "Dependent #"+(key+1)+' '+item.error_message;
                    });
                }
            });
        },
        removeError () {
          this.formErrors = []
          this.updateformErrors = []
        },
        formshow(){
            let app =this;
            app.visible=false;
        },
        emailValid () {
          this.isEmailValid = checkEmail(this.email)
          this.isEmailNull = !checkEmail(this.email)
        },
        convertIntoDateFormat () {
          let app = this
          let date = app.formFields.birthdate.value
          let dd = date.getDate()
          let mm = date.getMonth() + 1
          let yyyy = date.getFullYear()
          if (dd < 10) {
            dd = '0' + dd
          }
          if (mm < 10) {
            mm = '0' + mm
          }
          app.formFields.birthdate.value = mm + '/' + dd + '/' + yyyy;
      },
      convertTreatmentDateFormat (questionId) {
        let app = this
        let date = app.answers[questionId+'date_of_onset1']
        let dd = date.getDate()
        let mm = date.getMonth() + 1
        let yyyy = date.getFullYear()
        if (dd < 10) {
          dd = '0' + dd
        }
        if (mm < 10) {
          mm = '0' + mm
        }
        app.answers[questionId+'date_of_onset1'] = mm + '/' + dd + '/' + yyyy;
      },
      convertRecoveryDateFormat (questionId) {
        let app = this
        let date = app.answers[questionId+'date_of_recovery1']
        let dd = date.getDate()
        let mm = date.getMonth() + 1
        let yyyy = date.getFullYear()
        if (dd < 10) {
          dd = '0' + dd
        }
        if (mm < 10) {
          mm = '0' + mm
        }
        app.answers[questionId+'date_of_recovery1'] = mm + '/' + dd + '/' + yyyy;
      },
      convertPhysicianDateFormat (questionId) {
        let app = this
        let date = app.answers[questionId+'d_last_seen1']
        let dd = date.getDate()
        let mm = date.getMonth() + 1
        let yyyy = date.getFullYear()
        if (dd < 10) {
          dd = '0' + dd
        }
        if (mm < 10) {
          mm = '0' + mm
        }
        app.answers[questionId+'d_last_seen1'] = mm + '/' + dd + '/' + yyyy;
      }
    }
}
</script>
<style scoped>
.invalid-align {
  position: relative;
  top: 15px;
}
.invalid-header-align {
  position: relative;
  top: -13px;
}
.invalid-feet-align {
  position: relative;
  top: 15px;
}
.dep-edit {
  display: inline-flex;
  justify-content: space-between;
  color: #11809c;
  font-weight: bold;
  font-size: 13px;
  border-radius: 6px;
}
.dep-edit-text {
  position: relative;
  right: 6px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dep-edit {
    position: relative;
    left: -100px;
  }
}
@media (min-width: 1400px) {
  .dep-edit {
    position: relative;
    left: -190px;
  }
}
</style>

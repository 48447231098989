<template>
  <div>
    <b-modal id="getStarted1" centered size="md" v-model="show">
      <template #modal-header>
        <div class="popUp-header">
          <b-button variant="close-btn" @click="show=false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="formWizard">
          <form action="">
            <div class="form-group mb-3" v-if="step == 1">
              <h3>What is your zip code?</h3>
              <input type="number"
                     class="custom-input"
                     v-model="zip"
                     id="zip" value=""
                     @input="$v.zip.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.zip.$error || formErrors['zip_message']}"
                     placeholder="Enter your ZIP Code"
                     oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                     maxlength="5">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.zip.required && $v.zip.$error">
                Field is required
              </div>
              <div class="form-group__message  ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
                Enter 5 digit zip code.
              </div>
              <div class="error_message" v-if="formErrorFlag['zip'] == true">{{formErrors['zip_message']}}</div>
            </div>
            <div class="form-group mb-3" v-if="step == 2">
              <h3>What is your Date of Birth?</h3>
              <v-date-picker
                  v-model="dob"
                  :value="null"
                  :popover="{ visibility: 'click' }"
                  :max-date="new Date()"
                  @input="$v.dob.$touch(); removeError(); convertIntoDateFormat();"
                  mode="date">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      class="custom-input"
                      placeholder="mm/dd/yyyy"
                      size=10 maxlength=10
                      :class="{'invalid':$v.dob.$error || formErrors['dob_message']}"
                      :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                      v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.dob.required && $v.dob.$error">
                Field is required
              </div>
              <div class="form-group__message  ml-3 mt-2" v-if="$v.dob.required && $v.dob.$error">
                You must be 18 years to proceed
              </div>
              <div class="error_message" v-if="formErrorFlag['dob'] == true">{{formErrors['dob_message']}}</div>
            </div>
            <div class="form-group mb-3" v-if="step == 3">
              <h3>What is your gender?</h3>
              <div>
                <b-form-select
                    v-model="gender"
                    class="custom-input"
                    @input="$v.gender.$touch(); removeError();"
                    :class="{'form-control is-invalid':$v.gender.$error || formErrors['gender_message']}"
                    id="gender"
                    :options="genderOptions">
                </b-form-select>
              </div>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.gender.required && $v.gender.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag['gender'] == true">{{formErrors['gender_message']}}</div>
            </div>
            <div class="form-group mb-3" v-if="step == 4">
              <h3>Who needs covered?</h3>
              <b-form-select
                  v-model="tier"
                  class="custom-input"
                  @input="$v.tier.$touch(); removeError();"
                  :class="{'form-control is-invalid':$v.tier.$error || formErrors['tier_message']}"
                  id="tier"
                  :options="tierOptions">
              </b-form-select>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.tier.required && $v.tier.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag['tier'] == true">{{formErrors['tier_message']}}</div>
            </div>
            <div class="form-group wizBtn mb-3">
              <button type="button" class="prevBtn"  @click="wizardPrev(step)" v-if="step > 1">Back</button>
              <button type="button" :class="step == 1 ? 'univ-btn' : 'half-btn univ-btn'" @click="wizardNext(step)" v-if="step < 4">Continue</button>
              <button type="button" class="univ-btn half-btn" v-if="step == 4" @click="submitAPI">Let's Go!</button>
            </div>
            <div class="form-group stepCount">
              <p>
                <strong>Steps:</strong>
                <span v-if="step == 1">1 of 4</span>
                <span v-if="step == 2">2 of 4</span>
                <span v-if="step == 3">3 of 4</span>
                <span v-if="step == 4">4 of 4</span>
              </p>
            </div>
          </form>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {checkValidZip, isOver18} from "../utils/validators";
import axios from "axios";
export default {
  name: "FindPlan",
  data() {
    return {
      dateIn: '',
      show: false,
      showSlide1: true,
      showSlide2: false,
      showSlide3: false,
      slideIndex: 'first',
      buttonText: 'SUBMIT',
      zip: '',
      state: '',
      gender: '',
      is_smoker: '',
      tier: '',
      dob: null,
      eprocess: 'rep',
      agent_id: '10109',
      group_id: '77',
      apiResp: false,
      zipFlag: false,
      secondflag: false,
      thirdflag: false,
      zip_error_message: '',
      second_error_message: '',
      third_error_message: '',
      formErrorFlag: [],
      formErrors: [],
      platformId: '',
      enrollType: '',
      open_type: '',
      genderOptions: [
        { value: '', text: 'Choose Gender', disabled: true},
        { value: '0', text: 'Male' },
        { value: '1', text: 'Female'}
      ],
      tierOptions: [
        { value: '', text: 'Please select who needs covered', disabled: true},
        { value: 'IO', text: 'Me'},
        { value: 'IS', text: 'Me & My Spouse'},
        { value: 'IC', text: 'Me & My Children'},
        { value: 'IF', text: 'Me, My Spouse & Children'}
      ],
      isPremierPlanAddedOnCart: false,
      bannerImage: '',
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      goEnrollLandingImage: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/biz_image.png',
      hostName: location.host,
      envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME,
      step: 1,
      planSelected: [],
      planOptions: []
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_id = window.localStorage.getItem('group_id');
    app.platformId = window.localStorage.getItem('portal_id');
    app.enrollType = window.localStorage.getItem('enroll_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.open_type = window.localStorage.getItem('open_type');
    app.getFilters();
  },
  validations: {
    zip: {
      required,
      checkValidZip
    },
    tier: {
      required
    },
    dob: {
      required,
      isOver18
    },
    gender: {
      required
    }
  },
  methods: {
    wizardPrev(step) {
      this.step = step - 1
    },
    wizardNext(step) {
      this.checkPlanCoverage(step);
    },
    checkPlanCoverage(step) {
      if (step == 1) {
        if (this.$v.zip.$invalid) {
          this.$v.$touch();
        } else {
          this.validateZip(step);
        }
      }
      if (step == 2) {
        if (this.$v.dob.$invalid) {
          this.$v.$touch();
        } else {
          this.validateDOb(step);
        }
      }
      if (step == 3) {
        if (this.$v.gender.$invalid) {
          this.$v.$touch();
        } else {
          this.step = step + 1
          this.$v.$reset();
        }
      }
      if (step == 4) {
        if (this.$v.tier.$invalid) {
          this.$v.$touch();
        } else {
          this.step = step + 1
          this.getPlanCategoryList();
          this.$v.$reset();
        }
      }
    },
    submitAPI: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        // Submit API and
        // Navigate to plans page
        let app = this;
        let params = {
          zip: app.zip,
          dob: app.dob,
          is_smoker: app.is_smoker,
          gender: app.gender,
          tier: app.tier,
          eprocess: app.eprocess,
          agent_id: app.agent_id,
          group_id: app.group_id,
          platform_group_id: app.platformId,
          annual_incode: app.annualIncome,
          user_id: app.userId,
          open_type: app.open_type
        };
        if (app.tempId == null) {
          axios.post(process.env.VUE_APP_PLAN_COVERAGE_API + '/add-filter-for-new-user', {
            zip: app.zip,
            dob: app.dob,
            // is_smoker: app.is_smoker,
            gender: app.gender,
            tier: app.tier,
            eprocess: app.eprocess,
            agent_id: app.agent_id,
            group_id: app.group_id,
            platform_group_id: app.platformId,
            type: app.enrollType,
            open_type: app.open_type
          })
              .then(function (response) {
                if (response.data.status == 'success') {
                  window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                  window.localStorage.setItem('show-quotation', true);
                  window.localStorage.setItem('isFilterDataSubmitted', true);
                  console.log("success");
                  app.formErrorFlag = [];
                  app.formErrors = [];
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.checkErrorMessage(item);
                  });
                }
              });
        } else {
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] === '' || params[key] === undefined) {
              delete params[key];
            }
          }
          axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + app.tempId, params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.isHidden = false
                  app.getFilters();
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);

                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.checkErrorMessage(item);
                  });
                }
              });
        }
      }
    },
    checkErrorMessage (item) {
      let app = this;
      if (item.target_element ==  'zip') {
        app.step = 1
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'dob') {
        app.step = 2
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'gender') {
        app.step = 3
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
      if (item.target_element ==  'tier') {
        app.step = 4
        app.formErrorFlag[item.target_element] = true;
        app.formErrors[item.target_element + '_message'] = item.error_message;
      }
    },
    getPlanCategoryList () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-common-menu', {
        params: {
          agent_id: app.agent_id,
          group_id: app.group_id,
          portal_id: app.platformId,
          eprocess: app.eprocess,
          open_type: app.open_type
        }
      })
          .then(function (response) {
            console.log('Plan Category', response)
            if (response.data.status == 'success') {
              app.planOptions = []
              app.planOptions = response.data.data.map( a => a.charAt(0).toUpperCase() + a.substr(1) );
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    validateDOb: function (step) {
      let app = this;
      axios.get(`${process.env.VUE_APP_PLAN_COVERAGE_API_V2}/validate-dob?dob=${app.dob}`
      ).then(function (response) {
        if (response.status == 200) {
          app.step = step + 1
          app.$v.$reset();
        }
      }).catch(error => {
        app.formErrorFlag = [];
        app.formErrors = [];
        let dob = error.response.data.errors.dob
        app.formErrorFlag['dob'] = true;
        app.formErrors['dob_message'] = dob[0];
      });
    },
    validateZip: function (step) {
      let app = this;
      axios.get(process.env.VUE_APP_PLAN_COVERAGE_API_V2+'/validate-zip-code', {
        params: {
          zip: app.zip,
        },
      }).then(function (response) {
        if (response.status == 200) {
          app.step = step + 1
          app.$v.$reset();
        }
      })
          .catch(error => {
            app.formErrorFlag = [];
            app.formErrors = [];
            if (error.response.data.status == 'error') {
              let item =  error.response.data.data[0]
              app.checkErrorMessage(item);
            }
          });
    },
    nextPage: function(respFlag) {
      if (respFlag == true) {
        this.show = false
        this.$router.push('/plans');
        this.step = 1
      }
    },
    removeError () {
      this.formErrors = []
    },
    convertIntoDateFormat () {
      let app = this
      let date = this.dob
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.dob = mm + '/' + dd + '/' + yyyy;
    },
    getFilters: function () {
      let app = this;
      if(app.tempId != null){
        axios.get(process.env.VUE_APP_PLAN_COVERAGE_API + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'filter_info'
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.dob = response.data.data.result.dob;
                app.zip = response.data.data.result.zip;
                app.state = response.data.data.result.state;
                app.tier = response.data.data.result.tier;
                app.gender = response.data.data.result.gender;
                app.intrestSelected  = response.data.data.result.coverage;
                app.planSelected = response.data.data.result.categories;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
  },
  listener: {
    openFilterModel() {
      let app = this
      app.show = true
      app.tempId = window.localStorage.getItem('tempId');
      app.agent_id = window.localStorage.getItem('AGENT_ID');
      app.group_id = window.localStorage.getItem('group_id');
      app.platformId = window.localStorage.getItem('portal_id');
      app.enrollType = window.localStorage.getItem('enroll_type');
      app.eprocess = window.localStorage.getItem('eprocess');
      app.open_type = window.localStorage.getItem('open_type');
      app.getFilters();
    }
  }
}
</script>

<style scoped>

</style>

<template>
<div class="background-box">
    <div class="loading-box">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
</div>

</template>

<script>
    export default {
        name: "loader",
}
</script>

<style scoped>
.background-box{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 9999;
    background: #1a1a1a94;
}
.loading-box{
    position:fixed;
    top:40%;
    width:25%;
    margin:auto;
    z-index: 999;
    left: 0px;
    right: 0px;
    text-align: center;
}
    .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(0, 3, 51);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  50%{

     border: 4px solid rgb(66, 78, 131);

  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
     border: 4px solid rgb(255, 255, 255);
    opacity: 0;
  }
}

</style>
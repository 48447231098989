<template>
  <div>
    <enrollment-nav v-bind:navdata="nav_array" :key="$route.fullPath" ></enrollment-nav>
    <router-view v-bind:navdata="nav_array" @comEnvNavigation="comEnvNavigationFn" @comEnvNavigationBack="comEnvNavigationBackFn"></router-view>
  </div>
</template>

<script>
  import EnrollmentNav from "../components/Enrollment/EnrollmentNav";
 import Api from "@/includes/Api";
  export default {
    name: "Enrolment",
    data: function () {
      return {
        nav_array: JSON.parse(window.localStorage.getItem('nav_array'))
      }
    },
    components: {
      'enrollment-nav': EnrollmentNav
    },
    mounted() {
      this.getNavTabs();
    },
    methods: {
      comEnvNavigationFn(currentRoute){
        let currentIndex = 0;
        this.getnavarray();
        this.nav_array.forEach((nav, index) => {
            if(nav.name == currentRoute){
              currentIndex = index;
            }
        });
        let newIndex = currentIndex + 1;
        this.$router.push({ name: this.nav_array[newIndex].name, params: { id: '1' }})
      },
      comEnvNavigationBackFn(currentRoute){
        let currentIndex = 0;
        this.getnavarray();
        this.nav_array.forEach((nav, index) => {
          if(nav.name == currentRoute){
            currentIndex = index;
          }
        });
        let newIndex = currentIndex - 1;
        this.$router.push({ name: this.nav_array[newIndex].name, params: { id: '1' }})
      },
      captureScreenshots(){
      },
      getnavarray: function(){
        let app = this;
        app.nav_array = JSON.parse(window.localStorage.getItem('nav_array'));
      },
      getNavTabs: function() {
      let nav_array = [];
      let tempId = window.localStorage.getItem('tempId');
      //Lets pull Nav Data from API
      Api.get(process.env.BASE_URL+'get-dynamic-tabs?enrollment_id='+ tempId).then((response) => {
        console.log(response.data.data.result);
        let index = 1;
        let order = 0;
        for (order = 0; order <= 10; order++) {
          response.data.data.result.forEach((nav) => {
            if(nav.order == order){
              if(nav.tab_name == 'primary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Personal Information', "name": 'personal-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'contact_info' && nav.required){
                nav_array.push( {"index": index, "title": 'Contact Information', "name": 'contact-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'employer_info' && nav.required){
                nav_array.push({"index": index, "title": 'Employer Information', "name": 'employer-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'beneficiary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Beneficiary Information', "name": 'beneficiary-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'primary_health_info' && nav.required){
                nav_array.push({"index": index, "title": 'Primary Health Questions', "name": 'health-questions', "visible": true});
                index++;
              }
              if(nav.tab_name == 'dependent_info' && nav.required){
                nav_array.push({"index": index, "title": 'Dependent Information', "name": 'dependent-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'review_info' && nav.required){
                nav_array.push({"index": index, "title": 'Review & Confirmation', "name": 'review-confirm', "visible": true});
                index++;
              }
              if(nav.tab_name == 'agreement_info' && nav.required){
                nav_array.push({"index": index, "title": 'Agreement & Signature', "name": 'agreement-signature', "visible": true});
                index++;
              }
              if(nav.tab_name == 'billing_info' && nav.required){
                nav_array.push({"index": index, "title": 'Billing Information', "name": 'billing-information', "visible": true});
                index++;
              }
            }
          });
        }
        window.localStorage.setItem("nav_array", JSON.stringify(nav_array));
      }).catch(() => {
      }).then(() => {
      });
    }
    },
      listener: {
        'FromDirect'(){
        this.getNavTabs();
        }
    }
  }
</script>

<style scoped>
</style>

<template>
  <section class="page-wrap">
    <div class="banner-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li v-if="hostName !== envAssignHostName"><a href="#" @click.prevent="homePage">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <div class="caption">
              <div class="caption_container">
                <div class="caption_boxes">
                  <h1><span>Welcome to the Benefit Store</span><br>Let's Get Started...</h1>
                  <div class="links">
                    <ul style="list-style:none;">
                      <li v-if="portalPop">
                        <router-link to="#" @click.native="showPop" class="brand-btn">Start Enrollment</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- group popup -->
            <div  v-show="visible">
              <div class="card-block">
                <form action="">
                  <div class="col-12 mb-4">
                    <a href="javascript:void(0);" class="dark-btn text-center w-100" @click="nextPageDefault">Start New Member Enrollment</a>
                    <br>
                    <br>
                    <h3> OR </h3>
                    <br>
                    <label for="">Choose an option to enroll a new member:</label>
                    <select name="" class="custom-input "
                            :class="{'custom-input invalid': groupInvaild}"
                            v-model="group_id"
                            id="group_id">
                      <option v-for="item in groupData" :value="item.gid" v-bind:key="item.gid">{{item.gname}}</option>
                    </select>
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="groupInvaild">
                      Choose enroll for new member
                    </div>
                    <p style="color:red;" v-if="platformError['error'] == true">{{groupErr['message']}}</p>
                  </div>
                  <br>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-xxl-6 col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="[visible = false , platformPopup = true]">Back</a>
                    </div>
                    <br>
                    <div class="col-xxl-6 col-6">
                      <a href="javascript:void(0);" @click="nextPage"  class="brand-btn  mb-3">Submit</a>
                    </div>

                  </div>
                </form>
              </div>
            </div>

            <!--platform popup-->
            <div  v-show="platformPopup" class="popups">
              <div class="card-block" >
                <form action="">
                  <div class="col-12 mb-4">
                    <label for="">Choose Enrollment Portal </label>
<!--                    <select name="" class="custom-input" v-model="platformId" id="platformId">
                      <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                    </select>-->
                    <b-form-select name="" class="custom-input"
                                   :options="portalList"
                                   :class="{'invalid': platformError['error'] == true}"
                                   @input="preservePortalInfo(portal)"
                                   v-model="portal" id="platformId">
                    </b-form-select>
                    <p style="color:red;" class="mt-2" v-if="platformError['error'] == true">{{platformError['message']}}</p>
                  </div>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="[platformPopup = false, portalPop = true]">Back</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" @click.prevent="choosePortal()"  class="brand-btn  mb-3">Submit</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
            <div class="text-center">
              <img :src="bannerImage" alt="banner-image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



</template>

<script>
import axios from 'axios';
export default {
  name: "AgentHome",
  data: function () {
    return {
      groupData: [],
      agent_id: '',
      group_id: '',
      visible: false,
      groupErr: [],
      platforms: [],
      platformError: [],
      // platformId: '',
      portalPop: true,
      platformPopup: false,
      agent_name: '',
      bannerImage: '',
      portal: {},
      portalList: [],
      groupInvaild: false,
      hostName: location.host,
      envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME
    }
  },
  mounted() {
    let app = this;
    app.agent_id = window.localStorage.getItem('agent_id');
    app.agent_name = app.$store.state.agent.agent_fname + ' ' + app.$store.state.agent.agent_lname;
    app.getGroups();
    app.getPlatforms();
    app.fetchCatchInfo();
  },
  methods: {
    getGroups: function() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-agent-groups', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.groupData = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getPlatforms: function() {
      let app = this;
      app.platforms = [];
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-platforms', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.platforms = response.data.data;
              app.platforms.forEach( element => {
                let option = {
                  value: element,
                  text: element.platform_name
                }
                app.portalList.push(option)
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    choosePortal: function() {
      let app = this;
      console.log('platforms' + app.platformId);
      app.platformError = [];
      if (app.portal.platform_group_id) {
        window.localStorage.setItem('portal_id', this.portal.platform_group_id);
        app.platformPopup = false;
        app.visible = true;
        app.getGroups();
      }else{
        app.platformError['error'] = true;
        app.platformError['message'] = "Please choose a platform";
      }
    },
    nextPage: function() {
      if (!this.group_id) {
        this.groupInvaild = true;
      } else {
        this.platformPopup = false;
        this.visible = true;
        this.groupErr = [];
        if (this.group_id == '') {
          this.groupErr['error'] = true;
          this.platformError['error'] = true
          this.groupErr['message'] = "Please choose a group";
        } else {
          window.localStorage.setItem('group_id', this.group_id);
          window.localStorage.setItem('enroll_type', 'new-group-member');
          window.localStorage.setItem('eprocess', 'rep');
          this.$bus.$emit('getMenuList');
          window.localStorage.removeItem('tempId')
          this.$router.push('/home');
        }
      }
    },
    nextPageDefault: function() {
      this.visible = false;
      this.platformPopup = false;
      window.localStorage.setItem('group_id', this.portal.platform_group_id);
      this.$bus.$emit('getMenuList');
      this.$router.push('/home');
    },
    reloadPage: function(pid) {
      this.getPlanDetails();
      this.getPlanPricing();
      this.$router.push({name: 'plan', params: {id: pid}});
    },
    showPop(){
      this.portalPop=false;
      this.platformPopup=true;
      this.getPlatforms();
    },
    closePop(){
      this.visible=false;
    },
    closePopPlatforms(){
      this.platformPopup=false;
    },
    closeportalPop(){
      this.portalPop=false;
    },
    preservePortalInfo(portalInfo) {
      window.localStorage.setItem('portal_code', portalInfo.platform_code);
      window.localStorage.setItem('portal_name', portalInfo.platform_name);
    },
    homePage: function(){
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/home');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        } else if ((this.eprocess === 'applicant' && this.openType === 'agent') || (this.eprocess === 'applicant' && this.openType === 'group')) {
          this.$router.push('/');
        } else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-home');
        }
      }
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            this.bannerImage = newObj["VUE_APP_BANNER_IMAGE"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    }
  },
  watch: {
    group_id: function () {
      if (this.group_id) {
        this.groupInvaild = false;
      }
    },
    'portal.platform_group_id': function () {
      if (this.portal.platform_group_id) {
        this.platformError['error'] = false;
      }
    }
  }
}

</script>

<style scoped>
</style>

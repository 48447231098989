<template>
    <div class="innerpage-wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                Providers
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12">
                    <div class="providerTitle mb-4">
                        <h1>Providers</h1>
                    </div>
                </div>
                <div class="col-12">
                    <div class="providerList">
                        <h2>Dental Networks</h2>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <td>
                                        <div class="providerImage">
                                            <img role="button" @click="routeToProviderLink(providerLink.dental.solstice)" src="https://corenroll.com/biz_image.php?file=planLogo-solsticelogopng_12-12-2019.png" class="img-fluid" alt="">
                                        </div>
                                    </td>
                                    <td>
                                       <a :href="providerLink.dental.solstice" target="_blank"> Provider Search</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="providerImage">
                                            <img role="button" @click="routeToProviderLink(providerLink.dental.metlife)"
                                                src="https://corenroll.com/biz_image.php?file=planLogo-MetLifelogov1png_12-12-2019.png" class="img-fluid" alt="">
                                        </div>
                                    </td>
                                    <td>
                                      <a :href="providerLink.dental.metlife" target="_blank"> Provider Search</a>
                                    </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="providerImage">
                                      <img role="button" @click="routeToProviderLink(providerLink.dental.deltaDental)"
                                          src="https://corenroll.com/biz_image.php?file=planLogo-deltadentalbundlepng_08-22-2022.png" class="img-fluid" alt="">
                                    </div>
                                  </td>
                                  <td>
                                    <a :href="providerLink.dental.deltaDental" target="_blank"> Provider Search</a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="providerImage">
                                      <img role="button" @click="routeToProviderLink(providerLink.dental.ncd)"
                                          src="https://corenroll.com/biz_image.php?file=planLogo-BenAdvanceLogoforWebsitepng_02-10-2022.png" class="img-fluid" alt="">
                                    </div>
                                  </td>
                                  <td>
                                    <a :href="providerLink.dental.ncd" target="_blank"> Provider Search</a>
                                  </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="providerList mt-5">
                        <h2>VISION NETWORKS</h2>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <td>
                                        <div class="providerImage">
                                            <img role="button" @click="routeToProviderLink(providerLink.vision.vsp)"
                                                 src="https://corenroll.com/biz_image.php?file=planLogo-vspdeltapng_03-08-2023.png" class="img-fluid" alt="">
                                        </div>
                                    </td>
                                    <td>
                                      <a :href="providerLink.vision.vsp" target="_blank"> Provider Search</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="providerImage">
                                            <img role="button" @click="routeToProviderLink(providerLink.vision.solstice)"
                                                src="https://corenroll.com/biz_image.php?file=planLogo-solsticelogopng_12-12-2019.png" class="img-fluid" alt="">
                                        </div>
                                    </td>
                                    <td>
                                      <a :href="providerLink.vision.solstice" target="_blank"> Provider Search</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ProviderList',
  data () {
    return {
      providerLink: {
        dental: {
          solstice: process.env.VUE_APP_DENTAL_SOLSTICE,
          metlife: process.env.VUE_APP_DENTAL_METLIFE,
          deltaDental: process.env.VUE_APP_DENTAL_DELTA_DENTAL,
          ncd: process.env.VUE_APP_DENTAL_NCD
        },
        vision: {
          vsp: process.env.VUE_APP_VISION_VSP,
          solstice: process.env.VUE_APP_VISION_SOLSTICE
        }
      }
    }
  },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      routeToProviderLink (link) {
        window.open(link, '_blank')
      }
    }
}
</script>

<template>
  <div class="plan_info plan_questions">
    <div class="top_head">
      <h1>Simplified Issue Cancer Insurance - 3 Options to Choose from: Plus, Elite & Premier</h1>
    </div>
    <div class="planview_category">
      <ul>
        <li class="term_life">
          <div class="mcontent">
            <div class="micons">
              <!-- <img src="images/connection.svg" alt="Corenroll"> -->
            </div>
            <h2> Simplified Issue Term Life Start App Now</h2>
          </div>
        </li>
        <li class="can_insurance">
          <div class="mcontent">
            <div class="micons">
              <!-- <img src="images/pink_ribbon.svg" alt="Corenroll"> -->
            </div>
            <h2> Simplified Issue Cancer Insurance Medical Questions</h2>
          </div>
        </li>
      </ul>
    </div>
    <div class="quest_section">
      <ul>
        <li>
          <div class="question_list">
            <div class="qcount">
              Question 1
            </div>
            <div class="question">
              Has any propsed insured used tobacco in any form during the past 12 months?
            </div>
          </div>
          <div class="ans">
            <ul>
              <li>
                <input type="radio" id="qone_yes" name="answer_one" value="Yes">
                <label for="qone_yes">Yes</label>
              </li>
              <li>
                <input type="radio" id="qone_no" name="answer_one" value="No">
                <label for="qone_no">No</label>
              </li>
            </ul>
          </div>
          <div class="related_quest">
            <div class="child_block">
              <form class="" action="index.html" method="post">
                <div class="input_box">
                  <input type="text" name="" value="">
                </div>
              </form>
              <span class="bold space_left">If yes to Question 1, Who?</span>
            </div>
          </div>
        </li>
        <li>
          <div class="question_list">
            <div class="qcount">
              Question 1
            </div>
            <div class="question">
              Has any propsed insured used tobacco in any form during the past 12 months?
            </div>
          </div>
          <div class="ans">
            <ul>
              <li>
                <input type="radio" id="qtwo_yes" name="answer_two" value="Yes">
                <label for="qtwo_yes">Yes</label>
              </li>
              <li>
                <input type="radio" id="qtwo_no" name="answer_two" value="No">
                <label for="qtwo_no">No</label>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div class="question_list">
            <div class="qcount">
              Question 1
            </div>
            <div class="question">
              Has any proposed insured been informed by a physician of any abnormal test results or been advised to have any diagnostic/screening tests or procedures which have not yet been performed?
            </div>
          </div>
          <div class="ans">
            <ul>
              <li>
                <input type="radio" id="qtwo_yes" name="answer_two" value="Yes">
                <label for="qtwo_yes">Yes</label>
              </li>
              <li>
                <input type="radio" id="qtwo_no" name="answer_two" value="No">
                <label for="qtwo_no">No</label>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div class="question_list">
            <div class="qcount">
              Question 1
            </div>
            <div class="question">
              Within the past 10 years, has any proposed insured been disgnosed with, or had any indication of, symptom of or had treatment for or been recommended to have treatment for heart, brain, lung, circulatory, respiratory, blood, vascular, kidney, liver, digestive, neurological, rheumatoid, or other major organ disorders, or insulin dependent diabetes, Amyotrophic Lateral Sclerosis (ALS), drug abuse, alcoholism, cancer, or malignacny in any form (except for non-melannoma skin cancer)?
            </div>
          </div>
          <div class="ans">
            <ul>
              <li>
                <input type="radio" id="qtwo_yes" name="answer_two" value="Yes">
                <label for="qtwo_yes">Yes</label>
              </li>
              <li>
                <input type="radio" id="qtwo_no" name="answer_two" value="No">
                <label for="qtwo_no">No</label>
              </li>
            </ul>
          </div>
          <div class="related_quest">
            <div class="child_block treatment_info">
              <p>If yes to Question 4, give full details below. Include all dates, names and addresses of hospitals and all physicians, nature of the condition or impairment, the treatment or advice given and if released from the treatment</p>
              <div class="treat_list">
                <ul>
                  <li>
                    <div class="treat_data_form">
                      <span class="f_count">1</span>
                      <div class="input_box dtf half">
                        <label for="">Date of Treatment From</label>
                        <div class="input">
                          <input type="text" name="" value="">
                        </div>
                      </div>
                      <div class="input_box dtt half">
                        <label for="">Date of Treatment To</label>
                        <div class="input">
                          <input type="text" name="" value="">
                        </div>
                      </div>
                      <div class="input_box reason">
                        <label for="">Reason for Condition Diagnosis, injury, Etc.</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80"></textarea>
                        </div>
                      </div>
                      <div class="input_box recovery">
                        <label for="">Degree of Recovery</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80"></textarea>
                        </div>
                      </div>
                      <div class="input_box ad_physicians">
                        <label for="">Name/Address of Attending Physicians</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80"></textarea>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="treat_data_form">
                      <span class="f_count">1</span>
                      <div class="input_box dtf half">
                        <label for="">Date of Treatment From</label>
                        <div class="input">
                          <input type="text" name="" value="">
                        </div>
                      </div>
                      <div class="input_box dtt half">
                        <label for="">Date of Treatment To</label>
                        <div class="input">
                          <input type="text" name="" value="">
                        </div>
                      </div>
                      <div class="input_box reason">
                        <label for="">Reason for Condition Diagnosis, injury, Etc.</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80"></textarea>
                        </div>
                      </div>
                      <div class="input_box recovery">
                        <label for="">Degree of Recovery</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80"></textarea>
                        </div>
                      </div>
                      <div class="input_box ad_physicians">
                        <label for="">Name/Address of Attending Physicians</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80"></textarea>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="add_box">
                  <button class="add" type="button" name="button"><span>+</span> Add more</button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- <div class="submit_box">
        <button class="submit" type="button" name="button">Submit</button>
      </div> -->
    </div>
  </div>
</template>

<script>



export default {
  name: "PlanQuestionTwo",
  components: {
    // 'plan-description': PlanDescription
  },
  data: function () {
    return {

    }
  }
}
</script>

<style scoped>

</style>

<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
            </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-12 mb-4">
                <div class="infoicons-block text-center h-100">
                    <div class="infoIcon">
                        <img src="../assets/images/icons/best-price.svg" class="img-fluid">
                    </div>
                    <h3>Low Deductible Plans</h3>
                  <p><strong>Low-cost coverage for you and your family.</strong></p>
                  <div class="plan-list-align">
                    <p @click="routeToPlanDetail(planIdList.DELTA_DENTAL_PREVENTATIVE_ID, 'DELTA DENTAL PREVENTATIVE')">DELTA DENTAL PREVENTATIVE</p>
                    <p @click="routeToPlanDetail(planIdList.DELTA_DENTAL_PPO_1000_ID, 'DELTA DENTAL PPO 1000')">DELTA DENTAL PPO 1000</p>
                    <p @click="routeToPlanDetail(planIdList.SOLSTICE_PPO_DENTAL_1500_ID, 'Solstice PPO Dental 1500')">Solstice PPO Dental 1500</p>
                    <p @click="routeToPlanDetail(planIdList.METLIFE_DENTAL_SILVER_ID, 'MetLife Dental Silver')">MetLife Dental Silver</p>
                    <p  @click="routeToPlanDetail(planIdList.NCD_DENTAL_1500_ID, 'NCD Dental 1500')">NCD Dental 1500</p>
                   </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-12 mb-4">
                <div class="infoicons-block text-center h-100">
                    <div class="infoIcon">
                        <img src="../assets/images/icons/cost-free.svg" class="img-fluid">
                    </div>
                    <h3>High Annual Maximum Plans</h3>
                    <p><strong>More robust coverage, higher benefits.</strong></p>
                    <div class="plan-list-align">
                      <p @click="routeToPlanDetail(planIdList.DELTA_DENTAL_PPO_1500_ID, 'DELTA DENTAL PPO 1500')">DELTA DENTAL PPO 1500</p>
                      <p @click="routeToPlanDetail(planIdList.SOLSTICE_PPO_DENTAL_3000_ID, 'Solstice PPO Dental 3000')">Solstice PPO Dental 3000</p>
                      <p @click="routeToPlanDetail(planIdList.METLIFE_DENTAL_PLATINUM_ID, 'MetLife Dental Platinum')">MetLife Dental Platinum</p>
                      <p  @click="routeToPlanDetail(planIdList.NCD_DENTAL_5000_ID, 'NCD Dental 5000')">NCD Dental 5000</p>
                    </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-12 mb-4">
                <div class="infoicons-block text-center h-100">
                    <div class="infoIcon">
                        <img src="../assets/images/icons/all-in-one.svg" class="img-fluid">
                    </div>
                    <h3>Bundled Plans</h3>
                    <p><strong>Dental plus coverage for vision, or vision and hearing.</strong></p>
                    <div class="plan-list-align">
                      <p @click="routeToPlanDetail(planIdList.VSP_VISION_AND_DENTAL_1500_BUNDLE_ID, 'Delta Dental 1500 and VSP Vision Bundle')">Delta Dental 1500 and VSP Vision Bundle</p>
                      <p @click="routeToPlanDetail(planIdList.DAVIS_VISION_AND_DENTAL_3000_ID, 'Solstice Dental 3000 and Davis Vision')">Solstice Dental 3000 and Davis Vision</p>
                    </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-12 mb-4">
                <div class="infoicons-block text-center h-100">
                    <div class="infoIcon">
                        <img src="../assets/images/icons/plans.svg" class="img-fluid">
                    </div>
                    <h3>Vision Plans</h3>
                    <p><strong>Vision plans with a large national network of providers.</strong></p>
                    <div class="plan-list-align">
                      <p @click="routeToPlanDetail(planIdList.SOLSTICE_VISION_ID, 'Solstice Davis Vision')">Solstice Davis Vision</p>
                      <p @click="routeToPlanDetail(planIdList.METLIFE_VISION_HIGH_ID, 'Metlife Vision High & Low')">Metlife Vision High & Low</p>
                      <p @click="routeToPlanDetail(planIdList.NCD_VISION, 'NCD_VISION')">NCD Vision</p>
                </div>
              </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'InfoIcons',
    data () {
      return {
        planIdList: {
          DELTA_DENTAL_PREVENTATIVE_ID: process.env.VUE_APP_DELTA_DENTAL_PREVENTATIVE_ID,
          DELTA_DENTAL_PPO_1000_ID: process.env.VUE_APP_DELTA_DENTAL_PPO_1000_ID,
          SOLSTICE_PPO_DENTAL_1500_ID: process.env.VUE_APP_SOLSTICE_PPO_DENTAL_1500_ID,
          METLIFE_DENTAL_SILVER_ID: process.env.VUE_APP_METLIFE_DENTAL_SILVER_ID,
          NCD_DENTAL_1500_ID: process.env.VUE_APP_NCD_DENTAL_1500_ID,
          DELTA_DENTAL_PPO_1500_ID: process.env.VUE_APP_DELTA_DENTAL_PPO_1500_ID,
          SOLSTICE_PPO_DENTAL_3000_ID: process.env.VUE_APP_SOLSTICE_PPO_DENTAL_3000_ID,
          METLIFE_DENTAL_PLATINUM_ID: process.env.VUE_APP_METLIFE_DENTAL_PLATINUM_ID,
          NCD_DENTAL_5000_ID: process.env.VUE_APP_NCD_DENTAL_5000_ID,
          VSP_VISION_AND_DENTAL_1500_BUNDLE_ID: process.env.VUE_APP_VSP_VISION_AND_DENTAL_1500_BUNDLE_ID,
          DAVIS_VISION_AND_DENTAL_3000_ID: process.env.VUE_APP_DAVIS_VISION_AND_DENTAL_3000_ID,
          SOLSTICE_VISION_ID: process.env.VUE_APP_SOLSTICE_VISION_ID,
          METLIFE_VISION_HIGH_ID: process.env.VUE_APP_METLIFE_VISION_HIGH_ID,
          NCD_VISION: process.env.VUE_APP_VISION_NCD_VISION_ID,
        }
      }
    },
    methods: {
      routeToPlanDetail (pid, planName) {
        let app = this
        app.$router.push(`/plan/${pid}/${planName}`);
      }
  }
}
</script>

<template>
<h1>test</h1>
</template>
<script>
import axios from "axios";
export default {
  name: "member-benefit-store",
  data: function () {
    return {
      tempId: '',
      signaturePad: null,
    }
  },
  mounted() {
   this.submitApi(this.$route.params.id, this.$route.params.tier);

  },
  methods : {
    submitApi: function(id, tier) {
                let app = this;
                axios.post(process.env.VUE_APP_API_BASE+'/add-filter-for-member', {
                    user_id: id,
                    tier: tier,
                })
                .then(function (response) {
                    if (response.data.status == 'success') {
                        window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                        window.localStorage.setItem('group_id', 1929);
                        app.nextPage();
                    }
                })
                .catch(function (error) {
                    if (error.response.data.status == 'error') {
                        error.response.data.data.forEach(function(item) {
                            console.log(item);
                            app.tierErr['error'] = true;
                            app.tierErr['message'] = "Please choose a tier";
                        });
                    }
                });
            },
            nextPage: function() {
                this.$router.push('/plans');
            }
  }
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"savingPlans py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_vm._m(0),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"savingPlansTab mt-3"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('tr',[_c('th',[_vm._v("Coverage")]),_c('td',[_vm._v("See any dentist you’d like, but save more with a dentist one of our national PPO networks. "),_c('router-link',{attrs:{"to":"/provider-list","target":"_blank"}},[_vm._v("Find Your Provider Now")])],1)])])])])])])])]),_c('div',{staticClass:"helpSection py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_vm._m(7),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-8 mb-4"},[_c('div',{staticClass:"helpContent"},[_c('h2',[_vm._v("Need help finding a plan?")]),_c('p',[_vm._v("Answer a few questions to see which insurance options may be available for you.")]),_c('router-link',{directives:[{name:"b-modal",rawName:"v-b-modal.getStarted",modifiers:{"getStarted":true}}],attrs:{"to":""}},[_vm._v("Help me find a plan")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("What do dental plans cover?")]),_c('p',[_vm._v("Depending on the plan you choose, our dental insurance benefits can include:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Preventive Care")]),_c('td',[_vm._v("Covers routine cleaning for all covered persons and fluoride treatments for those under the age of 16 on the plan, often with no deductible or waiting period.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Basic Services")]),_c('td',[_vm._v("This often includes simple fillings or emergency treatment for dental plan.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Major Services")]),_c('td',[_vm._v("This can include retainers and root canals. These are often subject to the plan's deductible and waiting periods.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Access to a wide dental network")]),_c('td',[_vm._v("Including dental offices in both private and retail settings.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Direct payment to in-network dentists")]),_c('td',[_vm._v("No need to submit claim forms.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("No age restrictions")]),_c('td',[_vm._v("Find coverage for every member of your family and every stage of life. Even if you are on Medicare, which doesn't include dental benefits, we have plans designed specifically for seniors.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-4"},[_c('div',{staticClass:"helpImg"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/clipboard.png"),"alt":""}})])])
}]

export { render, staticRenderFns }
<template>
    <div>
        <div class="savingPlans py-5">
            <div class="container ">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2>What do dental plans cover?</h2>
                        <p>Depending on the plan you choose, our dental insurance benefits can include:</p>
                    </div>
                    <div class="col-12">
                        <div class="savingPlansTab mt-3">
                            <div class="table-responsive">
                                <table class="table">
                                    <tr>
                                        <th>Preventive Care</th>
                                        <td>Covers routine cleaning for all covered persons and fluoride treatments for those under the age of 16 on the plan, often with no deductible or waiting period.</td>
                                    </tr>
                                    <tr>
                                        <th>Basic Services</th>
                                        <td>This often includes simple fillings or emergency treatment for dental plan.</td>
                                    </tr>
                                    <tr>
                                        <th>Major Services</th>
                                        <td>This can include retainers and root canals. These are often subject to the plan's deductible and waiting periods.</td>
                                    </tr>
                                    <tr>
                                        <th>Access to a wide dental network</th>
                                        <td>Including dental offices in both private and retail settings.</td>
                                    </tr>
                                    <tr>
                                        <th>Direct payment to in-network dentists</th>
                                        <td>No need to submit claim forms.</td>
                                    </tr>
                                    <tr>
                                        <th>No age restrictions</th>
                                        <td>Find coverage for every member of your family and every stage of life. Even if you are on Medicare, which doesn't include dental benefits, we have plans designed specifically for seniors.</td>
                                    </tr>
                                    <tr>
                                        <th>Coverage</th>
                                        <td>See any dentist you’d like, but save more with a dentist one of our national PPO networks. <router-link to="/provider-list" target="_blank">Find Your Provider Now</router-link></td>
                                    </tr>
                                </table>
                            </div>

                            <!-- <b-tabs content-class="mt-3">
                                <b-tab title="Dental" active>
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Price Without Insurance</th>
                                                    <th>Price With Insurance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>X-ray</td>
                                                    <td>$150.00</td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Filing Surface</td>
                                                    <td>$250.00</td>
                                                    <td>$150.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Cleaning</td>
                                                    <td>$150.00</td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Extraction</td>
                                                    <td>$150.00</td>
                                                    <td>$100.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-tab>
                                <b-tab title="Vision">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Price Without Insurance</th>
                                                    <th>Price With Insurance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Normal Checkup</td>
                                                    <td>$150.00</td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Retina Fix</td>
                                                    <td>$250.00</td>
                                                    <td>$150.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Lens</td>
                                                    <td>$150.00</td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Operation</td>
                                                    <td>$150.00</td>
                                                    <td>$100.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-tab>
                            </b-tabs> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="helpSection py-5">
            <div class="container">
                <!-- <div class="row justify-content-center">
                    <div class="col-12 mb-3">
                        <div class="helpContent">
                            <h2>Coverage</h2>
                            <p>See any dentist you’d like, but save more with a dentist one of our national PPO networks.
                                <a href="https://www.solsticebenefits.com/provider-search.aspx" target="_blank"><strong>Provider Search</strong></a>
                            </p>
                        </div>
                    </div>
                </div> -->
                <div class="row justify-content-center">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
                        <div class="helpImg">
                            <img src="../assets/images/clipboard.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-8 mb-4">
                        <div class="helpContent">
                            <h2>Need help finding a plan?</h2>
                            <p>Answer a few questions to see which insurance options may be available for you.</p>
                            <router-link to="" v-b-modal.getStarted>Help me find a plan</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SavePlan'
}
</script>

<template>
  <section class="page-wrap error-page">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-6 col-lg-6">
          <div class="text-center">
            <h1>OOPS!</h1>
            <p class="text-capitalize">Landing url is not configured for this <strong><span style="color: #28a745;">{{enrollTypeKey}}</span></strong></p>
            <img src="../assets/images/404.svg" class="img-fluid" alt="404 Error">
            <div class="rollback-btn">
              <router-link to="/" class="backHome">Back to Homepage</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default  {
  name: 'landing-config-error',
  props: ['enrollType'],
  data () {
    return {
      enrollTypeKey: this.enrollType
    }
  }
}
</script>

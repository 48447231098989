<template>
  <div class="content_container">
    <div class="container">
      <top-filter></top-filter>
      <div style="padding-top: 1em;">
        <center><p>The group that you will be enrolling under is: <strong style="color:green;">{{gname}} ({{gcode}})</strong><span v-if="usertype=='existing-user'"> | Member: <strong style="color:green;">{{username}}</strong></span></p></center>
      </div>
      <div class="breadcrumbs">
        <ul>
          <li>
            <a href="#" @click="homePage">Home</a>
          </li>
          <li>
            <a href="#" @click="planListing">Plans</a>
          </li>
          <li>
            <a href="#">Plan Details</a>
          </li>
        </ul>
      </div>
      <!-- Plan Question -->
      <!-- <plan-quest-one></plan-quest-one> -->
      <plan-quest-two></plan-quest-two>
      <!-- <plan-quest-three></plan-quest-three> -->
      <!-- <plan-quest-four></plan-quest-four> -->
      <!-- Plan Question -->
    </div>
  </div>
</template>

<script>

  import TopFilter from "../components/plans/TopFilter";
  // import PlanQuestion from "../components/plan/PlanQuestion";
  import PlanQuestionTwo from "../components/plan/PlanQuestionTwo";
  // import PlanQuestionThree from "../components/plan/PlanQuestionThree";
  // import PlanQuestionFour from "../components/plan/PlanQuestionFour";

  export default {
    name: "PlanQuestion",
    components: {
      'top-filter': TopFilter,
      // 'plan-quest-one': PlanQuestion,
      'plan-quest-two': PlanQuestionTwo,
      // 'plan-quest-three': PlanQuestionThree,
      // 'plan-quest-four': PlanQuestionFour,
    },
    data: function () {
        return {

        }
    }
  }
</script>

<style scoped>

</style>

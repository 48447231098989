var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"hide-footer":true,"id":"Dependency-info","centered":"","size":"md"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"popUp-header",staticStyle:{"border-bottom":"0px !important"}},[_c('h4',[_vm._v("Please provide the following information")]),_c('b-button',{attrs:{"variant":"close-btn"},on:{"click":_vm.onClose}},[_c('i',{staticClass:"fas fa-times"})])],1)]},proxy:true}])},[_c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"popup-Details"},[_c('div',{staticClass:"row mb-3"},[(_vm.tier == 'IS' || _vm.tier == 'IF')?_c('div',{staticClass:"col-md-6"},[_c('div',[_c('label',{staticClass:"popupcontentTitle",attrs:{"for":"fname"}},[_vm._v("Spouse DOB:")]),_c('v-date-picker',{attrs:{"value":null,"popover":{ visibility: 'click' },"max-date":new Date(),"mode":"date"},on:{"input":function($event){_vm.$v.spouseDob.$touch();
                _vm.removeError();
                _vm.convertIntoDateFormat();}},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('input',_vm._g({staticClass:"custom-input",class:{ invalid: _vm.$v.spouseDob.$error },attrs:{"placeholder":"mm/dd/yyyy","size":"10","maxlength":"10"},domProps:{"value":inputValue
                      .replace(/^(\d\d)(\d)$/g, '$1/$2')
                      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                      .replace(/[^\d\/]/g, '')}},inputEvents))]}}],null,false,3169332721),model:{value:(_vm.spouseDob),callback:function ($$v) {_vm.spouseDob=$$v},expression:"spouseDob"}}),(!_vm.$v.spouseDob.required && _vm.$v.spouseDob.$error)?_c('div',{staticClass:"form-group__message text-left ml-3 mt-2"},[_vm._v(" Field is required ")]):_vm._e()],1)]):_vm._e(),(_vm.tier == 'IC' || _vm.tier == 'IF')?_c('div',{staticClass:"col-md-6"},[_c('div',[_c('label',{staticClass:"popupcontentTitle",attrs:{"for":"country"}},[_vm._v("Dependent (child):")]),_c('br'),_c('b-form-select',{staticClass:"popupBorder",class:{ 'form-control is-invalid': _vm.$v.childNumber.$error },attrs:{"options":_vm.childOptions,"placeholder":"Select number of children"},on:{"input":function($event){_vm.$v.childNumber.$touch();
                _vm.removeError();}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Choose Number of Children")])]},proxy:true}],null,false,3689651196),model:{value:(_vm.childNumber),callback:function ($$v) {_vm.childNumber=$$v},expression:"childNumber"}}),(!_vm.$v.childNumber.required && _vm.$v.childNumber.$error)?_c('div',{staticClass:"form-group__message text-left ml-3 mt-2"},[_vm._v(" Field is required ")]):_vm._e()],1)]):_vm._e()]),(_vm.errorMessage)?_c('span',{staticClass:"form-group__message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"popupEditButton",on:{"click":_vm.storeDependentInfo}},[_vm._v(" Continue ")])])]),_c('div')])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
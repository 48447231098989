<template>
    <div class="location-wrap" :class="{ 'hideBlock': showHideLocationPermission }">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xxl-12">
                    <div class="locationIcon mb-2">
                        <img src="../assets/images/location.svg" class="img-fluid" alt="">
                    </div>
                    <h3>Location Permission</h3>
                    <p>By clicking "Allow", you agree Benefit Store can store location of your and use for plan recommendation.</p>
                </div>
                <div class="col-xxl-12">
                    <div class="location-btn mt-2">
                        <a href="javascript:void(0);" @click="sendLocation" class="allowBtn">Allow</a>
                        <a href="javascript:void(0);" @click="closeLocation" class="denayBtn">Don't Allow</a>
                    </div>
                </div>
                <div class="closeIcon" @click="toggleLocationPermission">
                    <i class="fas fa-times"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DeviceUUID } from 'device-uuid';
import axios from 'axios';
export default {
    name: 'LocationPermission',
    data: function () {
        return{
            showHideLocationPermission: false,
            groupId:null,
            agentId:null,
            webUrl:null,
            deviceId:null,
        }
    },
    methods: {
        getLocationFlag(){
            let url = process.env.VUE_APP_API_BASE_STORE + '/check-lead-information'
            let params={
                device_id:this.deviceId,
                web_url:this.webUrl,
                agent_id :this.agentId
            }
            axios.get(url,{params:params})
            .then((response) => {
                if(response.data.statusCode == 200 ){
                    this.showHideLocationPermission = response.data.status;
                }
            }).catch(() => {
                this.showHideLocationPermission = false;

            });
        },
        toggleLocationPermission() {
            let app = this;
            if (app.showHideLocationPermission) {
                app.showHideLocationPermission = false;
            } else {
                app.showHideLocationPermission = true;
            }
        },
        sendLocation(){
            let app = this;
            let params={
                store_information:1,
                device_id:this.deviceId,
                web_url:this.webUrl
            }
            if(this.groupId){
                params['group_id']=this.groupId;
            }
            if(this.agentId){
                params['agent_id']=this.agentId
            }
            let url = process.env.VUE_APP_API_BASE_STORE + '/get-lead-information'
            axios.get(url,{params:params}).then((response) => {
                if(response.data.statusCode == 200 ){
                    window.localStorage.setItem("deviceId", this.deviceId);
                    app.showHideLocationPermission = true;
                }
            }).catch(() => {
                app.showHideLocationPermission = false;

            });
        },
        closeLocation(){
            let app = this;
            app.showHideLocationPermission = true;
        }
    },
    created(){
        let app = this;
        let deviceId = new DeviceUUID().get();
        this.webUrl=location.host;
        this.agentId=window.localStorage.getItem('agent_id');
        this.groupId=window.localStorage.getItem('group_id');
        this.deviceId = deviceId;
        this.getLocationFlag()
        if(this.deviceId){
            app.showHideLocationPermission = true;
        }
    }
}
</script>
<template>
  <div>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  name: "external",
    mounted(){
        this.$bus.$emit('externalsite');
        this.$router.push('/home');
    }
}
</script>

<style>
</style> 

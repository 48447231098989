<template>
  <section class="content_container">
    <section class="innerpage-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a style="color:#a2a2a2" @click="homePage">Home</a></li>
                <li v-if="isCurrentMemberAvailable"><a style="color:#a2a2a2" @click="routeToCurrentRoute">Current Member</a></li>
                <li><a href="#">Plans</a></li>
              </ul>
            </div>
          </div>
          <div v-if="tempId">
            <search-edit v-if="isFilterDataSubmitted" @getPlanList='getPlanList' />

            <div class="col-12"  v-if="isFilterDataSubmitted">
              <div class="split-block planFilterBlock mt-3 pt-4 pb-4">
                <p>
                    <span v-if="hostName !== envAssignHostName || ( hostName == envAssignHostName && open_type == 'group')">
                     Selected Group is: <strong style="color:green;">{{ gname }}
                     ({{ gcode }})</strong> <span v-if="usertype==='existing-user'"> | Member: <strong style="color:green;"
                                                                                                       class="brand-color">{{
                        username
                      }}</strong></span>
                    <span v-if="portal_name"> |  Selected Portal is: <strong style="color:green;">{{ portal_name }}
                    ({{ portal_code }})</strong> </span>
                    </span>
                </p>
                <multiselect
                    v-model="selectedCategories"
                    placeholder="Choose Category"
                    :options="categoryList"
                    label="value"
                    track-by="key"
                    @remove="checkRemovePlan()"
                    :multiple="true"
                    :taggable="true"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="dental.length > 0">
          <div id="dental">
            <h2>Dental Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in dental"
                   v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Dental" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <!--                  <router-link to="/" class="check-btn">Check more details</router-link>-->
                  <a class="more check-btn" href="#" id="nextdental" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="vision.length > 0">
          <div id="vision">
            <h2>Vision Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in vision" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextvision" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="medical.length > 0">
          <div id="medical">
            <h2>Medical Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in medical" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Medical" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextmedical" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="termlife.length > 0">
          <div id="termlife">
            <h2>Term Life Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in termlife" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Term Life" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextterm" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="limitedmed.length > 0">
          <div id="limitedmed">
            <h2>Limited Medical Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in limitedmed" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Limited Medical" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextlimited" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="rx.length > 0">
          <div id="rx">
            <h2>RX Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in rx" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="RX" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextrx" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="lifestyle.length > 0">
          <div id="lifestyle">
            <h2>Life Style Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in lifestyle" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Lifestyle" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextlifestyle" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="supplemental.length > 0">
          <div id="supplemental">
            <h2>Supplemental Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in supplemental" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Supplemental" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextsupp" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="di.length > 0">
          <div id="disability">
            <h2>Disability Income Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in di" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Supplemental" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextdi" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="critical.length > 0">
          <div id="critical">
            <h2>Critical Illness Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in critical" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Critical" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextcritical" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="accident.length > 0">
          <div id="accident">
            <h2>Accident Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in accident" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Critical" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nextaccident" v-on:click="nextPage(plan.plan_id)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="hospital.length > 0">
          <div id="hospital">
            <h2>Hospital Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in hospital" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Critical" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nexthospital" v-on:click="nextPage(plan.plan_id, plan.plan_name)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plansGrid-wrap" v-if="pet.length > 0">
          <div id="hospital">
            <h2>Pet Plans</h2>
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4" v-for="(plan,index) in pet" v-bind:key="index">
                <div class="plan-block card-block text-start">
                  <img :src=plan.plan_logo alt="Critical" class="img-fluid">
                  <h3><a href="#" v-on:click="nextPage(plan.plan_id)"> {{ plan.plan_name }}</a></h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a class="more check-btn" href="#" id="nexthospital" v-on:click="nextPage(plan.plan_id, plan.plan_name)">More Info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isPlanAvailable">
          <div class="no-plan row justify-content-center">
            <div class="col-xxl-6 col-lg-6">
              <img :src="noPlanIcon" class="img-fluid" alt="No Plan">
              <h5 class="text-center mt-3">No Plans are available for your selection</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import axios from 'axios';
import SearchEdit from '../components/SearchEdit.vue'
import Multiselect from 'vue-multiselect'
export default {
  name: "Plans",
  components: {
    SearchEdit,
    Multiselect
  },
  data: function () {
    return {
      tempId: '',
      gname: '',
      gcode: '',
      username: '',
      usertype: '',
      eprocess: '',
      limitedmed: [],
      isPlanAvailable: false,
      medical: [],
      dental: [],
      vision: [],
      supplemental: [],
      rx: [],
      lifestyle: [],
      termlife: [],
      cat: [],
      di: [],
      critical: [],
      accident: [],
      hospital: [],
      pet: [],
      menu:'',
      category: [],
      carrier: [],
      catList: {},
      catArray : [],
      categoryList: [],
      selectedCategories: [],
      noPlanIcon: '',
      isFilterDataSubmitted: false,
      isCurrentMemberAvailable: false,
      hostName: location.host,
      envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME,
      filters:null,
      selectedPlan: [],
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.group_id = window.localStorage.getItem('group_id');
    app.agent_id = window.localStorage.getItem('agent_id');
    app.portal_id = window.localStorage.getItem('portal_id');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.open_type = window.localStorage.getItem('open_type');
    app.referral = window.localStorage.getItem('referral');
    app.fromSite = window.localStorage.getItem('fromSite');
    app.portal_name = window.localStorage.getItem('portal_name');
    app.portal_code = window.localStorage.getItem('portal_code');
    app.isFilterDataSubmitted = window.localStorage.getItem('isFilterDataSubmitted');
    app.isCurrentMemberAvailable = window.localStorage.getItem('isCurrentMemberAvailable');
    app.selectedPlan = JSON.parse(localStorage.getItem('planList'))
    app.getInfo();
    app.checkPlanType();
    app.getSideFilters();
    app.fetchCatchInfo();
    if( this.tempId == null){
      let menu= this.$route.query.type;
      app.getMenuFilteredPlans(menu)
      this.$root.$on('menuData', (menu) => {
        app.getMenuFilteredPlans(menu)
      });
    } else if (app.fromSite === 'elevate' && !app.isFilterDataSubmitted) {
      let menu= this.$route.query.type;
      app.getMenuFilteredPlans(menu)
      this.$root.$on('menuData', (menu) => {
        app.getMenuFilteredPlans(menu)
      });
    } else{
      return false;
    }
  },
  methods: {
    homePage: function() {
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    },
    // method to get agent member info.
    getInfo: function() {
      let app = this;
      if(app.tempId !=null){
        axios.get(process.env.VUE_APP_API_BASE+'/get-group-agent-member-info?enrollment_id='+app.tempId)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.usertype = response.data.data.enrollment_type;
                if (response.data.data.enrollment_type == 'existing-user') {
                  app.username = response.data.data.result.user_info.first_name+' '+response.data.data.result.user_info.last_name;
                  window.localStorage.setItem('user_id',response.data.data.result.user_info.user_id)
                }
                app.gname = response.data.data.result.group_info.name;
                app.gcode = response.data.data.result.group_info.code;
                app.portal_name = response.data.data.result.platforms[0].platform_name;
                app.portal_code = response.data.data.result.platforms[0].platform_code;
                if (  app.usertype ) localStorage.setItem('usertype', app.usertype)
                if (  app.username ) localStorage.setItem('username', app.username)
                if (  app.gname ) localStorage.setItem('gname', app.gname)
                if (  app.gcode ) localStorage.setItem('gcode', app.gcode)
                if (  app.portal_name ) localStorage.setItem('portal_name', app.portal_name)
                if (  app.portal_code ) localStorage.setItem('portal_code', app.portal_code)
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    getPlans: function () {
      if (this.tempId !== null) {
        // Submit API and
        // Navigate to plans page
        if (localStorage.getItem('selectedCategorykey') || localStorage.getItem('selectedCategoryValue')) {
          let app = this;
          localStorage.removeItem('menuSelectedItem')
          this.$root.$emit('menuSelected', app.menuName);
          return false;
        } else if (this.$route.query.type) {
          return false;
        } else {
          let app = this;
          localStorage.removeItem('menuSelectedItem')
          this.$root.$emit('menuSelected', app.menuName);
        }
      }
    },
    checkPlanAvailability () {
      (!this.limitedmed.length && !this.medical.length && !this.dental.length  && !this.vision.length && !this.supplemental.length && !this.rx.length  && !this.lifestyle.length && !this.termlife.length  && !this.di.length  && !this.critical.length
          && !this.accident.length  && !this.hospital.length && !this.pet.length)  ? this.isPlanAvailable = true : this.isPlanAvailable = false
    },
    getFilteredPlans: function (category) {
      if (this.tempId !== null) {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE + '/get-filtered-plans', {
          params: {
            enrollment_id: app.tempId,
            categories: category,
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.clearPlans();
                app.planDivison(response.data.data.result)
                if (response.data.data.result.length < 1) {
                  app.selectedCategories = [];
                  localStorage.removeItem('selectedCategorykey');
                  localStorage.removeItem('selectedCategoryValue');
                }
              }
              // method to check the plan availability
              app.checkPlanAvailability();
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    getMenuFilteredPlans: function(menu) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-menu-filtered-plans', {
        params: {
          category : menu,
          group_id : app.group_id,
          agent_id : app.agent_id,
          portal_id : app.portal_id,
          eprocess : app.eprocess,
          open_type : app.open_type,
        }
      })
          .then(function(response)
          {
            if (response.data.status === 'success'){
              app.clearPlans();
              if (menu == null){
                app.planDivison(response.data.data)
              }
              else{
                response.data.data.forEach((item) => {
                  if (menu == 'limitedmed') {
                    app.limitedmed.push(item);
                  }
                  if (menu == 'medical') {
                    app.medical.push(item);
                  }
                  if (menu == 'dental') {
                    app.dental.push(item);
                  }
                  if (menu == 'vision') {
                    app.vision.push(item);
                  }
                  if (menu == 'supplemental') {
                    app.supplemental.push(item);
                  }
                  if (menu == 'rx') {
                    app.rx.push(item);
                  }
                  if (menu == 'lifestyle') {
                    app.lifestyle.push(item);
                  }
                  if (menu == 'term_life') {
                    app.termlife.push(item);
                  }
                  if (menu == 'di') {
                    app.di.push(item);
                  }
                  if (menu == 'critical') {
                    app.critical.push(item);
                  }
                  if (menu == 'accident') {
                    app.accident.push(item);
                  }
                  if (menu == 'hospital') {
                    app.hospital.push(item);
                  }
                  if (item.plan_category == 'pet') {
                    app.pet.push(item);
                  }
                });
              }
            }
            // method to check the plan availability
            app.checkPlanAvailability();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkPlanType () {
      if (this.tempId !== null) {
        if (this.$route.query.type) {
          let categoryKey = localStorage.getItem('selectedCategorykey');
          let categoryValue = localStorage.getItem('selectedCategoryValue');
          this.selectedCategories = [];
          this.selectedCategories.unshift({
            key: categoryKey,
            value: categoryValue
          })
        }
        if (localStorage.getItem('selectedCategorykey') === null || localStorage.getItem('selectedCategoryValue') === null ) {
          this.selectedCategories = [];
        }
      }
    },
    filterCat: async function (cat) {
      if (this.tempId !== null) {
        let app = this
        app.isPlanAvailable = false;
        app.category = [];
        app.category.push(cat);
        app.getFilteredPlans(app.category);
        await app.getSideFilters()
        app.setSelectedFilteredCategory(cat);
      }
    },
    setSelectedFilteredCategory (params) {
      let app = this;
      localStorage.removeItem('notInCategoryArray')
      app.categoryList.forEach((category) => {
        if (params === category.key) {
          app.selectedCategories = [];
          app.selectedCategories.unshift({
            key: category.key,
            value: category.value
          })
          localStorage.setItem('selectedCategorykey', category.key)
          localStorage.setItem('selectedCategoryValue', category.value)
        }
      })
      if (!app.catArray.includes(params)) {
        window.localStorage.setItem('notInCategoryArray', params)
      }
    },
    getSideFilters: async function() {
      let app = this;
      if(app.tempId){
        await axios.get(process.env.VUE_APP_API_BASE+'/get-left-side-filter-fields?enrollment_id='+app.tempId)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.categoryList = []
                app.catArray = []
                response.data.data.result.categories.forEach(function(item) {
                  app.catArray.push(item);
                  let catObj;
                  let catValue;
                  if (item == 'dental') {
                    app.catList.dental = 'Dental Plans';
                    catValue = app.catList.dental;
                  }
                  if (item == 'vision') {
                    app.catList.vision = 'Vision Plans';
                    catValue = app.catList.vision;
                  }
                  if (item == 'medical') {
                    app.catList.medical = 'Medical Plans';
                    catValue = app.catList.medical;
                  }
                  if (item == 'limitedmed') {
                    app.catList.limitedmed = 'Limited Med Plans';
                    catValue = app.catList.limitedmed;
                  }
                  if (item == 'term_life') {
                    app.catList.term_life = 'Term Life Plans';
                    catValue = app.catList.term_life;
                  }
                  if (item == 'supplemental') {
                    app.catList.supplemental = 'Supplemental Plans';
                    catValue = app.catList.supplemental;
                  }
                  if (item == 'lifestyle') {
                    app.catList.lifestyle = 'Lifestyle Plans';
                    catValue = app.catList.lifestyle;
                  }
                  if (item == 'rx') {
                    app.catList.rx = 'RX Plans';
                    catValue = app.catList.rx;
                  }
                  if (item == 'di') {
                    app.catList.di = 'Disability Income Plans';
                    catValue = app.catList.di;
                  }
                  if (item == 'pet') {
                    app.catList.pet = 'PET Plans';
                    catValue = app.catList.pet;
                  }
                  if (item == 'critical') {
                    app.catList.critical = 'Critical Plan';
                    catValue = app.catList.critical;
                  }
                  if (item == 'accident') {
                    app.catList.accident = 'Accident Plans';
                    catValue = app.catList.accident;
                  }
                  if (item == 'hospital') {
                    app.catList.hospital = 'Hospital Plans';
                    catValue = app.catList.hospital;
                  }
                  catObj = {key: item, value: catValue};
                  app.categoryList.push(catObj);
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        app.renderSelectedPlan(app.categoryList);
      }
    },
    nextPage: function (pid,planName) {
      this.$router.push('plan/' + pid + '/' + planName);
      localStorage.setItem('planId', pid)
      localStorage.setItem('planName', planName)
    },
    routeToCurrentRoute () {
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-select-member');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-select-member');
        }
      }
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            this.noPlanIcon = newObj["VUE_APP_NO_PLAN_ICON"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    },
    getPlanList () {
      let app = this
      if (this.$route.query.type) {
        let menu=this.$route.query.type;
        app.clearPlans();
        app.catArray = [];
        app.categoryList = [];
        app.filterCat(menu)
      } else {
        app.clearPlans();
        app.catArray = [];
        app.categoryList = [];
        app.renderPlanList()
      }
    },
    clearPlans(){
      let app = this
      app.limitedmed = [];
      app.medical = [];
      app.dental = [];
      app.vision = [];
      app.supplemental = [];
      app.rx = [];
      app.lifestyle = [];
      app.termlife = [];
      app.di = [];
      app.critical = [];
      app.accident = [];
      app.hospital = [];
      app.pet = [];
    },
    planDivison(data){
      let app = this
      data.forEach(function (item) {
        if (item.plan_category == 'limitedmed') {
          app.limitedmed.push(item);
        }
        if(item.plan_category == 'medical') {
          app.medical.push(item);
        }
        if (item.plan_category == 'dental') {
          app.dental.push(item);
        }
        if (item.plan_category == 'vision') {
          app.vision.push(item);
        }
        if (item.plan_category == 'supplemental') {
          app.supplemental.push(item);
        }
        if (item.plan_category == 'rx') {
          app.rx.push(item);
        }
        if (item.plan_category == 'lifestyle') {
          app.lifestyle.push(item);
        }
        if (item.plan_category == 'term_life') {
          app.termlife.push(item);
        }
        if (item.plan_category == 'di') {
          app.di.push(item);
        }
        if (item.plan_category == 'critical') {
          app.critical.push(item);
        }
        if (item.plan_category == 'accident') {
          app.accident.push(item);
        }
        if (item.plan_category == 'hospital') {
          app.hospital.push(item);
        }
        if (item.plan_category == 'pet') {
          app.pet.push(item);
        }
      });
    },
    renderSelectedPlan(catListVal) {
      let app = this;
      if (app.planSelected !== null  && !this.$route.query.type) {
        app.selectedPlan.forEach(plan => {
          catListVal.find((ele, index) => {
            if (plan == ele.key) {
              let category = {};
              category = catListVal[index]
              app.selectedCategories.push(category)
            }
          })
        })
      }
    },
    renderPlanList () {
      let app = this
      axios.get(process.env.VUE_APP_API_BASE + '/get-filtered-plans?enrollment_id=' + app.tempId)
          .then(function (response) {
            if (response.data.status == 'success') {
              app.clearPlans();
              app.planDivison(response.data.data.result)
              app.checkPlanAvailability();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkRemovePlan () {
      if (this.selectedCategories.length <= 1) {
        localStorage.removeItem('planList')
        this.$router.push('/plans')
        this.renderPlanList();
      }
    }
  },
  watch: {
    selectedCategories: function () {
      let catItems = [];
      this.selectedCategories.forEach(function(obj) {
        catItems.push(obj.key);
      });
      if (catItems.length !== 0) {
        this.getFilteredPlans(catItems);
      } else {
        if (localStorage.getItem('notInCategoryArray') && this.selectedCategories.length === 0) {
          if (this.catArray.includes(localStorage.getItem('notInCategoryArray'))) {
            return false;
          } else {
            if (this.$route.query.type) {
              return false;
            } else {
              return false;
            }
          }
        }
        if (!this.catArray.includes(localStorage.getItem('notInCategoryArray'))) {
          return false;
        } else {
          localStorage.removeItem('menuSelectedItem')
          localStorage.removeItem('selectedCategorykey');
          localStorage.removeItem('selectedCategoryValue');
        }
      }
    },
    '$route.query.type':{
      handler:  function () {
        this.selectedPlan = []
        this.tempId = window.localStorage.getItem('tempId');
        this.getPlanList();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style scoped>
</style>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-3">
                <router-link to="/plans?type=dental">
                    <div class="planBlock">
                        <div class="plan-image">
                            <img :src="dentalImage" class="img-fluid" alt="">
                        </div>
                        <div class="planAction">
                            <i class="fas fa-tooth"></i>
                            <h2>Dental Plans</h2>
                            <p>Bigger Value For <br> A Brighter Smile</p>
                        </div>
                    </div>
<!--                    <div class="startingFrom  mb-5 text-center">
                        Starting From: <span>$25.00</span>/month
                    </div>-->
                </router-link>
            </div>

            <div class="col-md-4 mb-3">
                <router-link to="/plans?type=vision">
                    <div class="planBlock">
                        <div class="plan-image">
                            <img :src="visionImage" class="img-fluid" alt="">
                        </div>
                        <div class="planAction">
                            <i class="fas fa-eye"></i>
                            <h2>Vision Plans</h2>
                            <p>Quality Vision Benefits</p>
                        </div>
                    </div>
<!--                    <div class="startingFrom  mb-5 text-center">
                        Starting From: <span>$12.00</span>/month
                    </div>-->
                </router-link>
            </div>

            <div class="col-md-4 mb-3">
                <router-link to="/plans?type=term_life">
                    <div class="planBlock">
                        <div class="plan-image">
                            <img :src="lifeStyleImage" class="img-fluid" alt="">
                        </div>
                        <div class="planAction">
                            <i class="fas fa-medkit"></i>
                            <h2>Term Life</h2>
                        </div>
                    </div>
<!--                    <div class="startingFrom  mb-5 text-center">
                        Starting From: <span>$5.00</span>/month
                    </div>-->
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PlanBlock',
    data () {
      return {
        dentalImage: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/dental.jpg',
        visionImage: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/vision.jpg',
        lifeStyleImage: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/lifestyle.jpg'
      }
    }
}
</script>

<template>
    <div class="bannerInfo-wrap pb-5">
        <div class="container">
            <div class="row align-items-center infoWrap-banner">
                <div class="col-xl-4 col-lg-4">
                    <h3>Shop and compare Dental plans now</h3>

                </div>
                <div class="col-xl-3 col-lg-3">
                    <router-link to="" v-b-modal.getStarted class="shop-link">
                        Shop for a plan
                    </router-link>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <p>Or, you can call <a href="tel:1 (929) 999-8535" style="color: #fff !important; text-decoration: underline !important;">(929) 999-8535</a> for a free quote, Mon-Fri, 8 am - 8 pm, ET</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BannerInfo',
    data () {
      return {
        repInfo: {}
      }
    },
    methods: {
      getRepInfo() {
        let app = this
        app.repInfo = {}
        let newObject = window.localStorage.getItem("repInfo")
        app.repInfo = JSON.parse((newObject))
      }
    },
  listener: {
    'getRepInfoLead'() {
      let app = this
      app.getRepInfo();
    }
  }
}
</script>

<template>
  <div>
    <banner-area></banner-area>
  </div>
</template>

<script>
import continueEnrollment from "../components/ContinueEnrollment";
export default {
  name: "Index",
  components: {
    'banner-area': continueEnrollment,
  },
}
</script>

<style scoped>
</style>

<template>
  <div class="top_filter">
    <span class="fliter_toggle"  @click="active=!active">
      <span class="label">Basic Filter</span>
      <span class="icon"></span>
    </span>
    <div class="collect" v-bind:class="{active:active}">
      <ul>
        <li class="zip"><span class="pinfo">Zip Code :<strong>{{zip}}</strong></span></li>
        <li class="gender"><span class="pinfo">Gender :<strong>{{genderOpt}}</strong></span></li>
        <li class="smoker" v-if="set_smoker == true"><span class="pinfo">Smoker :<strong>{{smoker}}</strong></span></li>
        <li class="tier"><span class="pinfo">Tier :<strong>{{tier}}</strong></span></li>
        <li class="birth"><span class="pinfo">Date of Birth :<strong>{{dob}}</strong></span></li>
        <li class="edit_but"><a @click="showPop" class="edit">Edit</a></li>
      </ul>
      <div  v-show="visible" class="popups">
        <div class="m_scrol">
          <div class="popup_container">
            <a @click="closePop" class="close" href="#">x</a>
            <h2>Basic Details</h2>
            <div class="content grey_box">
              <form class="" action="index.html" method="post">
                  <div class="input_box">
                      <label for="">Zip Code</label>
                      <input type="text" v-model="zip" id="zip">
                    <div class="error_message" v-if="formErrorFlag['zip'] == true">{{formErrors['zip_message']}}</div>
                  </div>
                  <div class="input_box">
                      <label for="">Gender</label>
                      <select class="" v-model="gender" id="gender">
                        <option value="0">Male</option>
                        <option value="1">Female</option>
                      </select>
                    <div class="error_message" v-if="formErrorFlag['gender'] == true">{{formErrors['gender_message']}}</div>
                  </div>
                  <div class="input_box birth">
                      <label for="">Date of Birth</label>
                      <input type="text" v-model="dob" id="dob" placeholder="mm/dd/yyyy"  v-on:keyup="eventDob()">
                    <div class="error_message" v-if="formErrorFlag['dob'] == true">{{formErrors['dob_message']}}</div>
                  </div>
                  <div class="input_box">
                      <label for="">Tier</label>
                      <select class="" v-model="tier" id="tier">
                        <option value="" disabled selected>Choose Tier</option>
                            <option value="IO">Member Only</option>
                            <option value="IS">Member + Spouse</option>
                            <option value="IC">Member + Child(ren)</option>
                            <!-- <option value="IC3">Member + Child(ren) > 3</option> -->
                            <option value="IF">Family</option>
                      </select>
                    <div class="error_message" v-if="formErrorFlag['tier'] == true">{{formErrors['tier_message']}}</div>
                  </div>
                  <div class="input_box" v-if="set_smoker == true">
                      <label for="">Smoker</label>
                      <span class="input_check">
                        <input type="radio" name="is_smoker" v-model="is_smoker" id="is_smoker" value="1">
                        <span class="check_name">Yes</span>
                        <input type="radio" name="is_smoker" v-model="is_smoker" id="is_smoker" value="0">
                        <span class="check_name">No</span>
                      </span>
                  </div>
                  <div class="button_box">
                      <button class="submit" type="button" id="submitFilter" name="button" @click="submitAPI">Submit</button>
                  </div>
              </form>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>

</template>
<style scoped>
@media(max-width:549px){
  .m_scrol {
    overflow-y: scroll;
    height: 100%;
    padding-top: 30px;
    padding-right: 10px;
    }
}
</style>
<script>
  import axios from 'axios';
  export default {
    name: "TopFilter",
    data: function () {
      return {
        tempId: '',
        category: '',
        dob: '',
        tier: '',
        state: '',
        zip: '',
        effectiveDate: '',
        gender: '',
        annualIncome: '',
        smoker: '',
        is_smoker: '',
        genderOpt: '',
        visible: false,
        eprocess: '',
        agent_id: '',
        group_id: '',
        active:false,
        userId: '',
        enrollmentType: '',
        set_smoker: false,
        formErrorFlag: [],
        formErrors: [],
      }
    },
    mounted() {
      let app = this;
      app.tempId = window.localStorage.getItem('tempId');
      app.agent_id = window.localStorage.getItem('AGENT_ID');
      app.group_id = window.localStorage.getItem('group_id');
      app.checkEprocess();
      app.getType();
      app.getFilters();
      console.log(this.$route.name);

    },
    methods: {
        getType: function() {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'type',
          }
        })
        .then(function (response) {
          console.log(response.data.data.result);
          if (response.data.status == 'success') {
            app.enrollmentType = response.data.data.result;
          }

        })
        .catch(function (error) {
          console.log(error);
        });
      },
      checkEprocess: function() {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'eprocess',
          }
        })
        .then(function (response) {
          console.log(response.data.data.result);
          if (response.data.status == 'success') {
            app.eprocess = response.data.data.result;
          }

        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getFilters: function() {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'filter_info'
          }
        })
        .then(function (response) {
          console.log(response.data.data.result);
          if (response.data.status == 'success') {
            app.dob = response.data.data.result.dob;
            app.zip = response.data.data.result.zip;
            app.state = response.data.data.result.state;
            app.tier = response.data.data.result.tier;
            app.gender = response.data.data.result.gender;
            if (app.gender == '0') {
                app.genderOpt = "M";
            }else{
                app.genderOpt = "F";
            }
            app.is_smoker = response.data.data.result.is_smoker;
            if (response.data.data.result.is_smoker == 1) {
              app.smoker = "Yes";
            }else{
              app.smoker = "No";
            }
            if (response.data.data.result.is_smoker == null || response.data.data.result.is_smoker == '' || response.data.data.result.is_smoker == undefined) {
              app.set_smoker = false;
            }else{
              app.set_smoker = true;
            }
            if (app.enrollmentType == 'existing-user') {
              app.userId = response.data.data.result.user_id;
            }
          }

        })
        .catch(function (error) {
          console.log(error);
        });
      },
        submitAPI: function () {
            // Submit API and
            // Navigate to plans page
            let app = this;
            let params = {
                zip: app.zip,
                dob: app.dob,
                is_smoker: app.is_smoker,
                gender: app.gender,
                tier: app.tier,
                eprocess: app.eprocess,
                agent_id: app.agent_id,
                group_id: app.group_id,
                annual_incode: app.annualIncome,
                user_id: app.userId,
            };
            //discard null values
            for (const key of Object.keys(params)) {
                if (params[key] === null || params[key] === '' || params[key] === undefined) {
                    delete params[key];
                }
            }
            axios.put(process.env.VUE_APP_API_BASE+'/change-filter-info/'+app.tempId, params)
            .then(function (response) {
                if (response.data.status == 'success') {
                    console.log("success");
                    app.closePop();
                    app.getFilters();
                    app.relPage();
                }
            })
            .catch(function (error) {
              app.formErrorFlag = [];
              app.formErrors = [];
              console.log(error);
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                  app.formErrorFlag[item.target_element] = true;
                  app.formErrors[item.target_element+'_message'] = item.error_message;
                });
              }
            });

        },
      showPop(){
        this.visible=true;
      },
      closePop(){
        this.visible=false;
      },
      relPage: function() {
        if (this.$route.name == 'plans') {
          this.$router.push('/plans');
        }else if(this.$route.name == 'plan') {
          let pid = this.$route.params.id;
          this.$router.push({name: 'plan', params: {id: pid}});
          window.location.reload()
        }
      },
      checkValue: function(str, max) {
        if (str.charAt(0) !== '0' || str == '00') {
          var num = parseInt(str);
          if (isNaN(num) || num <= 0 || num > max) num = 1;
          str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
        }
        return str;
      },
      eventDob: function() {

        let app = this;
        var input = app.dob;
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
        var values = input.split('/').map(function(v) {
          return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = app.checkValue(values[0], 12);
        if (values[1]) values[1] = app.checkValue(values[1], 31);
        var output = values.map(function(v, i) {
          return v.length == 2 && i < 2 ? v + '/' : v;
        });
        app.dob = output.join('').substr(0, 10);
      }
    }
  }
</script>

<style scoped>

</style>

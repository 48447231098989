import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index'
import AgentHome from '../views/AgentHome'
import GroupHome from '../views/GroupHome'
import Cart from "../views/Cart"
import AgentSelectMember from "../views/AgentSelectMember";
import GroupSelectMember from "../views/GroupSelectMember";
import Plans from "../views/Plans";
import Plan from "../views/Plan";
import Enrolment from "../views/Enrollment";
import PersonalInformation from "../components/Enrollment/PersonalInformation";
import ContactInformation from "../components/Enrollment/ContactInformation";
import EmployerInformation from "../components/Enrollment/EmployerInformation";
import BeneficiaryInformation from "../components/Enrollment/BeneficiaryInformation";
import HealthQuestions from "../components/Enrollment/HealthQuestions";
import DependentInformation from "../components/Enrollment/DependentInformation";
import ReviewAndConfirm from "../components/Enrollment/ReviewAndConfirm";
import AgreementAndSignature from "../components/Enrollment/AgreementAndSignature";
import BillingInformation from "../components/Enrollment/BillingInformation";
import Confirmation from "../components/Confirmation";
import Receipt from "../components/Receipt";
import ContinueEnrollment from "../views/Continue";
import DirectContinue from "../views/DirectContinue";
import External from "../views/External";
import MemberHome from '../views/MemberHome.vue';
import MemberBenefitStore from "../views/MemberBenefitStore";
import PlanQuestionView from "../views/PlanQuestionView";
import BenAdvanceHome from "../views/BenAdvanceHome";
import ErrorPage from "../components/404";
import Referrals from "../components/Referrals";
import GroupOpenHome from "../views/GroupOpenHome";
import LandingConfigError from "../components/LandingConfigError"
import ProviderList from "../views/ProviderList"
import axios from 'axios';

Vue.use(VueRouter)

const routes = [
  {
    path: '/:landing?',
    name: 'main',
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/agent-home',
        name: 'agent home',
        component: AgentHome
      },
      {
        path: '/website',
        name: 'websites',
        component: GroupOpenHome
      },
      {
        path: '/agent-select-member',
        name: 'agent Member',
        component: AgentSelectMember
      },
      {
        path: '/cart',
        name: 'cart',
        component: Cart
      },
      {
        path: '/plans',
        name: 'plans',
        component: Plans
      },
      {
        path: '/plan/:id/:planName',
        name: 'plan',
        component: Plan
      },
      {
        path: '/PlanQuestionView',
        name: 'PlanQuestionView',
        component: PlanQuestionView
      },
      {
        path: '/confirmation/',
        name: 'Confirmation',
        component: Confirmation
      },
      {
        path: 'enrollment/receipt/',
        name: 'Receipt',
        component: Receipt
      },
      {
        path: '/group-home',
        name: 'group home',
        component: GroupHome
      },
      {
        path: '/group-select-member',
        name: 'group Member',
        component: GroupSelectMember
      },
      {
        path: '/enrollment',
        name: 'enrolment',
        children: [
          {
            path: '/enrollment/personal-info',
            name: 'personal-information',
            component: PersonalInformation
          },
          {
            path: '/enrollment/contact-info',
            name: 'contact-information',
            component: ContactInformation
          },
          {
            path: '/enrollment/employer-info',
            name: 'employer-information',
            component: EmployerInformation
          },
          {
            path: '/enrollment/beneficiary-info',
            name: 'beneficiary-information',
            component: BeneficiaryInformation
          },
          {
            path: '/enrollment/health-questions',
            name: 'health-questions',
            component: HealthQuestions
          },
          {
            path: '/enrollment/dependent-information',
            name: 'dependent-information',
            component: DependentInformation
          },
          {
            path: '/enrollment/review-confirm',
            name: 'review-confirm',
            component: ReviewAndConfirm
          },
          {
            path: '/enrollment/agreement-signature',
            name: 'agreement-signature',
            component: AgreementAndSignature
          },
          {
            path: '/enrollment/billing-information',
            name: 'billing-information',
            component: BillingInformation
          }
        ],
        component: Enrolment
      },
      {
        path: '/continue-enrollment',
        name: 'continue-enrollment',
        component: ContinueEnrollment
      },
      {
        path: '/direct-continue/:id/:tab',
        name: 'direct-continue',
        component: DirectContinue
      },
      {
        path: '/member-home',
        name: 'member home',
        component: MemberHome
      },
      {
        path: '/external',
        name: 'external',
        component: External
      },
      {
        path: '/member-benefit-store/:id/:tier',
        name: 'member-benefit-store',
        component: MemberBenefitStore
      },
      {
        path: '/BenAdvanceHome',
        name: 'BenAdvanceHome',
        component: BenAdvanceHome
      },
      {
        path: '/referrals',
        name: 'referrals',
        component: Referrals
      },
      {
        path: '/external',
        name: 'external',
        component: External
      },
      {
        path: '/404',
        name: 'error',
        component: ErrorPage
      },
      {
        path: '/landing-config-error/:enrollType',
        name: 'landing-config-error',
        component: LandingConfigError,
        props: true
      },
      {
        path: '/provider-list',
        name: 'ProviderList',
        component: ProviderList
      }
    ],
    component: Index
    ,beforeEnter(to, from, next) {
        axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
        next();
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <section class="innerpage-wrap">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb">
            <ul>
              <li><a href="#" @click="homePage">Home</a></li>
              <li><a href="#" class="brand-color">Current Members</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div v-if="!isHidden">
            <div class="col-12">
              <div class="split-title agent-member">
                <h1 class="brand-color">Current Members </h1>
                <div class="form-group">
                  <!-- <label for="filter">&nbsp;</label> -->
                  <input type="text" class="custom-input" v-model="filter" placeholder="Search Member"
                         @keydown="$event.stopImmediatePropagation()">
                </div>
              </div>
            </div>
            <div class="members-table card-block table-responsive">
              <datatable :columns="columns" :data="rows" :filter="filter" :per-page="5">
                <template slot-scope="{ row }">
                  <tr>
                    <td align="left" style="width: 40%;">
                      <strong>Name:</strong> {{ row.name }} <br>
                      <strong>Date of Birth:</strong> {{ row.dob }} <br>
                      <strong>Email:</strong> {{ row.email }} <br>
                      <strong>SSN:</strong> {{ row.ssn }}
                    </td>
                    <td align="left" style="width: 40%;">
                      <strong>Group: </strong> {{ row.group_name }}
                      <div>
                        <span v-for="item in row.plans" :key="item.plan">
                          <span align="left" v-if="item.plan_status=='ACTIVE'">{{ item.plan_name }}</span>
                          <!--                        Standard Life 60k Critical Illness <br>-->
                          <!--                        Simplified Issue Disability Income <br>-->
                          <span align="left" v-else style="color: red;">{{ item.plan_name }}</span>
                          <br>
                        </span>
                      </div>
                    </td>
                    <td>
                      <!--              <router-link to="/personal-info" class="table-action-btn" v-b-tooltip.hover title="Enroll Now"><i class="fa fa-file"></i></router-link>-->
                      <!-- <a class="table-action-btn" id="enrollNow" v-b-tooltip.hover title="Enroll Now"
                        @click="enrollNow(row.userid, row.group_id, row.policyId)"><i class="fa fa-file"></i></a> -->
                        <a class="table-action-btn" id="enrollNow" v-b-tooltip.hover title="Enroll Now"
                        v-b-modal.enrollNow @click="enrollNow(row.userid, row.group_id, row.policyId)"><i class="fa fa-file"></i></a>
                    </td>
                  </tr>
                </template>
              </datatable>
              <!--      <pagination/>-->
              <section class="table-pagination">
                <!--      <datatable-pager v-model="page" type="short"></datatable-pager>-->
                <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
              </section>
            </div>
          </div>
          <!-- <div class="col-6">
            <div class="card-block" v-if="isHidden">
              <form action="">
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <label for="">Choose Tier</label>
                    <select name="" class="custom-input" v-model="tier" id="tier">
                      <option value="" disabled selected>Choose Tier</option>
                      <option value="IO">Member Only</option>
                      <option value="IS">Member + Spouse</option>
                      <option value="IC">Member + Child(ren)</option>
                      <option value="IF">Family</option>
                    </select>
                    <p style="color:red;" v-if="tierErr['error'] == true">{{ tierErr['message'] }}</p>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <button class="dark-univ-btn" v-on:click="isHidden = false">Back</button>
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <button type="button" class="univ-btn" name="button" @click="submitApi">Submit</button>
                  </div>
                </div>
              </form>

            </div>
          </div> -->
        </div>
      </div>
    </div>

    <b-modal id="enrollNow" centered size="md"  v-if="show">
            <template #modal-header>
                <div class="popUp-header text-center">
                    <h4>Enroll Now</h4>

                    <b-button variant="close-btn" @click="show=false">
                        <i class="fas fa-times"></i>
                    </b-button>
                </div>
            </template>
            <b-container fluid>
                <form action="">
                <div class="form-group row">
                  <div class="col-12 mb-4">
                    <label for="">Choose Tier</label>
                    <select name=""
                            class="custom-input"
                            :class="{'invalid': tierErr['error'] == true}"
                            v-model="tier" id="tier">
                      <option value="" disabled selected>Choose Tier</option>
                      <option value="IO">Member Only</option>
                      <option value="IS">Member + Spouse</option>
                      <option value="IC">Member + Child(ren)</option>
                      <!-- <option value="IC3">Member + Child(ren) > 3</option> -->
                      <option value="IF">Family</option>
                    </select>
                    <p style="color:red;" v-if="tierErr['error'] == true">{{ tierErr['message'] }}</p>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-6 mb-4">
                    <button class="dark-univ-btn" v-on:click="show=false">Back</button>
                  </div>
                  <div class="col-6 mb-4">
                    <button type="button" class="univ-btn" name="button" @click="submitApi">Submit</button>
                  </div>
                </div>
              </form>
            </b-container>
        </b-modal>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "AgentSelectMember",
  data: function () {
    return {
      show: false,
      agent_id: '',
      platformId: '',
      filter: '',
      columns: [
        // {label: 'System ID', field: 'system_id'},
        // {label: 'User ID', field: 'user_id'},
        {
          label: 'Members',
          representedAs: row => `Name: ${row.name}<br>Date of Birth: ${row.dob}<br>Group Name: ${row.group_name}<br>Email: ${row.email} <br>SSN: ${row.ssn}`,
          align: 'left',
          sortable: false,
          filterable: true
        },
        {label: 'Benefits', field: 'plans', filterable: true},
        {label: 'Action'}
      ],
      rows: [],
      page: '',
      visible: false,
      tierErr: [],
      isTierErr: false,
      tier: '',
      userId: '',
      groupId: '',
      policyId: '',
      isHidden: false,
      open_type: '',
    }
  },
  mounted() {
    let app = this;
    app.agent_id = app.$store.state.agent.agent_id;
    app.eprocess = window.localStorage.getItem("eprocess");
    app.open_type = window.localStorage.getItem("open_type");
    app.referral = window.localStorage.getItem("referral");
    app.platformId = window.localStorage.getItem('portal_id');
    app.getMembers();
  },
  methods: {
    getMembers: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-members', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            let row = [];
            app.rows = [];
            if (response.data.status == 'success') {
              response.data.data.forEach(function (item) {
                row = {
                  name: item.name,
                  dob: item.dob,
                  policyId: item.policy_id,
                  userid: item.user_id,
                  email: item.email,
                  ssn: item.ssn,
                  group_id: item.group_info.group_id,
                  group_name: item.group_info.group_name,
                  plans: item.plan_info,
                };
                app.rows.push(row);
              });
              console.log(app.rows);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    enrollNow: function (userId, groupId, policyId) {
      this.userId = userId;
      this.groupId = groupId;
      this.policyId = policyId;
      // this.isHidden = true
      this.show = true;
      // this.visible=true;
    },
    submitApi: function () {
      let app = this;
      app.tierErr = [];
      if (app.tier == '') {
        app.tierErr['error'] = true;
        app.tierErr['message'] = "Please choose a tier";
      }
      axios.post(process.env.VUE_APP_API_BASE + '/add-filter-for-existing-user', {
        user_id: app.userId,
        tier: app.tier,
        eprocess: 'rep',
        group_id: app.groupId,
        agent_id: app.agent_id,
        // platform_group_id: app.platformId,
        policy_id: app.policyId,
        open_type: app.open_type,
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('portal=>'+response.data.data.portal_id);
              window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
              window.localStorage.setItem('group_id', app.groupId);
              window.localStorage.setItem('show-quotation', true);
              window.localStorage.setItem('portal_id', response.data.data.portal_id);
              window.localStorage.setItem('enroll_type','existing-member');
              app.$bus.$emit('getMenuList');
              app.nextPage();
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function (item) {
                console.log(item);
                app.tierErr['error'] = true;
                app.tierErr['message'] = "Please choose a tier";
              });
            }
          });
    },
    homePage: function () {
      if (this.open_type !== null && typeof this.open_type !== "undefined") {
        if (this.referral !== null && typeof this.referral !== "undefined") {
          this.$router.push("/referrals?rid=" + this.referral);
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.eprocess === "rep") {
          this.$router.push("/agent-home");
        } else if (this.eprocess === "applicant") {
          this.$router.push("/member-home");
        } else if (this.eprocess === "group") {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    },
    closePop() {
      this.visible = false;
    },
    nextPage: function () {
      this.$router.push('/plans');
      localStorage.setItem('isCurrentMemberAvailable', true)
      window.localStorage.setItem('isFilterDataSubmitted', true);
    }
  },
  watch: {
    tier: function () {
      if (this.tier) {
       this.tierErr['error'] = false;
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
    <section class="innerpage-wrap pb-0">
        <div class="container">
            <div class="enrollmentProgress row" style="text-align:center">
                <div class="col" v-for="item in navData" v-bind:key="item.index"  v-bind:class="item.name == currentRoute ? 'active' : ''">

                    <div v-if="item.title == 'Personal Information'" class="progressIcon no-bar progressActive">
                        <img :src="item.image" class="img-fluid">

                    </div>

                    <div v-else-if="item.progressed == 'yes'|| item.name == currentRoute " class="progressIcon progressActive">
                     <img :src="item.image" class="img-fluid">

                    </div>
                    <div v-else class="progressIcon">
                     <img :src="item.image" class="img-fluid">

                    </div>
                    <h3 v-if="item.name === 'health-questions'">
                      <span> Primary Health Questions</span>
                    </h3>
                    <h3 v-else>{{item.title}}</h3>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
  export default {
    name: "EnrollmentNav",
    props: {
      navdata: {
        type: Array
      }
    },
    data: function () {
      return {
        navData : [],
        isActive: false,
        currentRoute: this.$route.name,
        nav_progressed_data : [],
        personalInformationImg: '',
        contactInformationImg: '',
        employerInformationImg: '',
        beneficiaryInformationImg: '',
        healthQuestionsInformationImg: '',
        dependentInformationImg: '',
        billingInformationImg: '',
        agreementSignatureInformationImg: '',
        reviewConfirmationInformationImg: ''
      }
    },

    mounted() {
      this.currentRoute = this.$route.name;
      this.fetchCatchInfo();
    },
    methods:{
      fetchCatchInfo() {
        let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
        // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
        let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
        caches.open('fetch_color_cache').then((cache) => {
          cache.match(url).then(response => {
            response.json().then(data => {
              let getCatchColor = []
              let newObj = {}
              getCatchColor = data
              getCatchColor.map(item => {
                newObj[item.key] = item.value;
              });
              this.personalInformationImg = newObj["VUE_APP_PERSONAL_INFORMATION"]
              this.contactInformationImg = newObj["VUE_APP_CONTACT_INFORMATION"]
              this.employerInformationImg = newObj["VUE_APP_EMPLOYER_INFORMATION"]
              this.beneficiaryInformationImg = newObj["VUE_APP_BENEFICIARY_INFORMATION"]
              this.healthQuestionsInformationImg = newObj["VUE_APP_HEALTH_QUESTIONS"]
              this.dependentInformationImg = newObj["VUE_APP_DEPENDENT_INFORMATION"]
              this.billingInformationImg = newObj["VUE_APP_BILLING_INFORMATION"]
              this.agreementSignatureInformationImg = newObj["VUE_APP_AGREEMENT_SIGNATURE"]
              this.reviewConfirmationInformationImg = newObj["VUE_APP_REVIEW_CONFIRMATION"]
              this.progress_bar(this.navdata);
            })
          })
        }).catch((err) => {
          console.log(err);
        })
      },
      progress_bar(navdat){
      const BreakError = {};
      try {
        navdat.forEach(element => {
          if (element.name == this.currentRoute) {
            throw BreakError;
          }

          this.nav_progressed_data.push(element.name);
        });
      } catch (err) {
        if (err !== BreakError) throw err;
      }
      this.navWithImage(navdat , this.nav_progressed_data);
      },

      navWithImage(navdat , nav_progress){
        navdat.forEach((nav,) => {
        if(nav.name == 'personal-information'){
          this.$set(nav,'image' , this.personalInformationImg);
          if(nav_progress.indexOf('personal-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }

        }
        if(nav.name == 'contact-information'){
          this.$set(nav,'image' , this.contactInformationImg);
          if(nav_progress.indexOf('contact-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav, 'progressed','no');
          }
        }
        if(nav.name == 'employer-information'){
          this.$set(nav,'image' , this.employerInformationImg);
          if(nav_progress.indexOf('employer-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'beneficiary-information'){
          this.$set(nav,'image' ,this.beneficiaryInformationImg);
          if(nav_progress.indexOf('beneficiary-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'health-questions'){
          this.$set(nav,'image' ,  this.healthQuestionsInformationImg);
          if(nav_progress.indexOf('health-questions') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'dependent-information'){
          this.$set(nav,'image' , this.dependentInformationImg);
                    if(nav_progress.indexOf('dependent-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'billing-information'){
          this.$set(nav,'image' ,  this.billingInformationImg);
          if(nav_progress.indexOf('billing-information') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'agreement-signature'){
          this.$set(nav,'image' ,  this.agreementSignatureInformationImg);
          if(nav_progress.indexOf('agreement-signature') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        if(nav.name == 'review-confirm'){
          this.$set(nav,'image' , this.reviewConfirmationInformationImg);
          if(nav_progress.indexOf('review-confirm') !== -1)
          {
                  this.$set(nav,'progressed','yes');
          }
          else
          {
                  this.$set(nav,'progressed','no');
          }
        }
        this.navData.push(nav);

      });
      }

    },
    listener:{
      'FromDirect'(){
        this.navdata = JSON.parse(window.localStorage.getItem('nav_array'));
        this.currentRoute = this.$route.name;
        this.progress_bar(this.navdata);
      }
    }
  }
</script>

<style scoped>

</style>

<template>
  <div class="plan_info plan_questions">
    <div class="quest_section">
      <ul>
        <li v-for="(question, index) in questions" v-bind:key="index">
          <div class="question_list">
            <div class="qcount">
              Question {{index + 1}}
            </div>
            <div class="question">
              {{question['question_text']}}
            </div>
          </div>
          <div class="ans">
            <ul>
              <li>
                <input type="radio" v-bind:id="'yes'+index" v-bind:name="'yes'+index" v-model="answers.yes_no_flag[question.question_id]" value="1">
                <label v-bind:for="'yes'+index" required>Yes</label>
              </li>
              <li>
                <input type="radio" v-bind:id="'no'+index" v-bind:name="'no'+index" v-model="answers.yes_no_flag[question.question_id]" value="0">
                <label v-bind:for="'no'+index" required>No</label>
              </li>
            </ul>
          </div>
          <div class="related_quest" v-if="question['question_sub_type'] != null && question['question_sub_type'] == 'text_field'">
            <div class="child_block">
              <!-- <form class="" action="index.html" method="post"> -->
                <div class="input_box">
                  <input type="text" name="" value="" v-model="answers.who[question.question_id]">
                </div>
              <!-- </form> -->
              <span class="bold space_left">{{question['options'][0]['option_text']}}</span>
            </div>
          </div>
          <div class="related_quest" v-if="question['question_sub_type'] != null && question['question_sub_type'] == 'checkbox'">
            <div class="child_block" v-if="question['options'].length > 0 && question['options'][0]['option_group'] != null">
              <p class="bold">If yes, check all that apply:</p>
              <div class="block block_one">
                <h2>A. Prior to age 60</h2>
                <ul>
                  <div v-for="(option, index1) in question['options']" v-bind:key="index1"> 
                  <li  v-if="option['option_group'] == 'A. Prior to age 60:'">
                    <input type="checkbox" name="" value="" v-model="answers.checkbox_answers['' + question.question_id + option.option_id]">
                    <label>{{option['option_text']}}</label>
                  </li>
                  </div>
                </ul>
              </div>
              <div class="block block_two">
                <h2>B. Prior to age 75</h2>
                <ul>
                  <div v-for="(option, index2) in question['options']" v-bind:key="index2"> 
                  <li  v-if="option['option_group'] == 'B. Prior to age 75'">
                    <input type="checkbox" name="" value="" v-model="answers.checkbox_answers['' + question.question_id + option.option_id]">
                    <label>{{option['option_text']}}</label>
                  </li>
                  </div>
                </ul>
              </div>
            </div>

            <div class="child_block" v-else>
              <p class="bold">If Yes, check all that apply:</p>
              <div class="listing">
                <ul>
                  <li v-for="(option, index3) in question['options']" v-bind:key="index3">
                    <div class="input_box">
                      <input type="checkbox" name="" value="" v-model="answers.checkbox_answers['' + question.question_id + option.option_id]">
                      <label>{{option['option_text']}}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="related_quest" v-if="question['require_detail'] == 1">
            <div class="child_block second">
              <p v-if="question['question_sub_type'] == 'detail'">{{question['options'][0]['option_text']}}</p>
              <p v-else>Complete the following for each condition checked in question {{index + 1}}:</p>
              <div class="treat_list">
                <ul>
                  <li>
                    <div class="treat_data_form">
                      <span class="f_count">1</span>
                      <div class="input_box dtf half">
                        <label for="">Date of Treatment From</label>
                        <div class="input">
                          <input type="date" name="" value="" v-model="answers.detail[question.question_id + 'dot_from1']">
                        </div>
                      </div>
                      <div class="input_box dtt half">
                        <label for="">Date of Treatment To</label>
                        <div class="input">
                          <input type="date" name="" value="" v-model="answers.detail[question.question_id + 'dot_to1']">
                        </div>
                      </div>
                      <div class="input_box reason">
                        <label for="">Reason for Condition Diagnosis, injury, Etc.</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80" v-model="answers.detail[question.question_id + 'reason_for_condition1']"></textarea>
                        </div>
                      </div>
                      <div class="input_box recovery">
                        <label for="">Degree of Recovery</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80" v-model="answers.detail[question.question_id + 'degree_of_recovery1']"></textarea>
                        </div>
                      </div>
                      <div class="input_box ad_physicians">
                        <label for="">Name/Address of Attending Physicians</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80" v-model="answers.detail[question.question_id + 'physician_name_address1']"></textarea>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="treat_data_form">
                      <span class="f_count">2</span>
                      <div class="input_box dtf half">
                        <label for="">Date of Treatment From</label>
                        <div class="input">
                          <input type="date" name="" value="" v-model="answers.detail[question.question_id + 'dot_from2']">
                        </div>
                      </div>
                      <div class="input_box dtt half">
                        <label for="">Date of Treatment To</label>
                        <div class="input">
                          <input type="date" name="" value="" v-model="answers.detail[question.question_id + 'dot_to2']">
                        </div>
                      </div>
                      <div class="input_box reason">
                        <label for="">Reason for Condition Diagnosis, injury, Etc.</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80" v-model="answers.detail[question.question_id + 'reason_for_condition2']"></textarea>
                        </div>
                      </div>
                      <div class="input_box recovery">
                        <label for="">Degree of Recovery</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80" v-model="answers.detail[question.question_id + 'degree_of_recovery2']"></textarea>
                        </div>
                      </div>
                      <div class="input_box ad_physicians">
                        <label for="">Name/Address of Attending Physicians</label>
                        <div class="input">
                          <textarea name="name" rows="8" cols="80" v-model="answers.detail[question.question_id + 'physician_name_address2']"></textarea>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
               <!--  <div class="add_box">
                  <button class="add" type="button" name="button"><span>+</span> Add more</button>
                </div> -->
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="submit_box">
        <button class="submit" type="button" name="button" @click="saveQuestionAnswers">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "NewQuestionPlan",
  components: {
  },
  data: function () {
    return {
      tempId: '',
      answers: {
        question_id: [],
        yes_no_flag: [],
        who: [],
        checkbox_answers: [],
        detail: []
      },
      questions : [],
      final_answers : [],
    }
  },
  mounted : function () {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.getQuestions();
  },
  methods : {
    getQuestions : function () {
      let app = this;
      let pid = app.$route.params.id;
      app.questions = [];
      axios.get(process.env.VUE_APP_API_BASE+'/get-questions', {
          params: {
            enrollment_id: app.tempId,
            plan_id: pid,
            type: 'save'
          }
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            app.questions = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    saveQuestionAnswers : function () {
      let app = this;
      app.final_answers = [];
      app.$parent.errorMessage = '';
      app.$parent.errorFlag = false;
      app.questions.forEach(function (question) {
        let row = {};
        let detail = {};
        row['checkbox_answers'] = {};
        row['detail'] = {};
        if (app.answers.yes_no_flag[question.question_id] === undefined) {
          app.$parent.errorMessage = 'Please answer all questions';
          app.$parent.errorFlag = true;
          return false;
        }
        row['question_id'] = question.question_id;
        row['yes_no_flag'] = app.answers.yes_no_flag[question.question_id];
        row['who'] = (app.answers.who[question.question_id] === undefined) ? '' : app.answers.who[question.question_id];

        if (question.question_sub_type == 'checkbox') {
          let counter = 0;
          question.options.forEach( function (option) {
            if(app.answers.checkbox_answers['' + question.question_id + option.option_id] !== undefined)  {
              if (app.answers.checkbox_answers['' + question.question_id + option.option_id]) {
                row['checkbox_answers'][counter] = option.option_id;
                counter ++;
              }
          }
          })
        }

        if (question.require_detail == 1) {
          console.log('require_detail');
          detail['dot_from'] = (app.answers.detail[question.question_id + 'dot_from1'] === undefined) ? '' : app.answers.detail[question.question_id + 'dot_from1'];
          detail['dot_to'] = (app.answers.detail[question.question_id + 'dot_to1'] === undefined) ? '' : app.answers.detail[question.question_id + 'dot_to1'];
          detail['reason_for_condition'] = (app.answers.detail[question.question_id + 'reason_for_condition1'] === undefined) ? '' : app.answers.detail[question.question_id + 'reason_for_condition1'];
          detail['degree_of_recovery'] = (app.answers.detail[question.question_id + 'degree_of_recovery1'] === undefined) ? '' : app.answers.detail[question.question_id + 'degree_of_recovery1'];
          detail['physician_name_address'] = (app.answers.detail[question.question_id + 'physician_name_address1'] === undefined) ? '' : app.answers.detail[question.question_id + 'physician_name_address1'];
          row['detail'][0] = detail;

          detail = {};
          detail['dot_from'] = (app.answers.detail[question.question_id + 'dot_from2'] === undefined) ? '' : app.answers.detail[question.question_id + 'dot_from2'];
          detail['dot_to'] = (app.answers.detail[question.question_id + 'dot_to2'] === undefined) ? '' : app.answers.detail[question.question_id + 'dot_to2'];
          detail['reason_for_condition'] = (app.answers.detail[question.question_id + 'reason_for_condition2'] === undefined) ? '' : app.answers.detail[question.question_id + 'reason_for_condition2'];
          detail['degree_of_recovery'] = (app.answers.detail[question.question_id + 'degree_of_recovery2'] === undefined) ? '' : app.answers.detail[question.question_id + 'degree_of_recovery2'];
          detail['physician_name_address'] = (app.answers.detail[question.question_id + 'physician_name_address2'] === undefined) ? '' : app.answers.detail[question.question_id + 'physician_name_address2'];

          row['detail'][1] = detail;
        }
        app.final_answers.push(row);
      });
      if (!app.$parent.errorFlag) {
        let data = {
          enrollment_id: app.tempId,
          plan_id: app.$route.params.id,
          answers: app.final_answers
        };
        axios.post(process.env.VUE_APP_API_BASE+'/save-question-answers', data)
        .then(function (response) {
          if (response.data.status == 'success') {
            console.log('success');
            if (response.data.errorCode == 'decline') {
              app.$parent.errorMessage = response.data.data.error_message;
              app.$parent.errorFlag = true;
            } else {
              app.$parent.newQuestionsError = 0;
              app.$parent.successMessage = response.data.data.success_message;
              app.$parent.successFlag = true;
              app.$parent.startApp = false;
              app.$parent.getPlanPricing();
            }
          }
        })
        .catch(function (error) {
          app.$parent.successMessage = '';
          app.$parent.successFlag = false;

          console.log(error);
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
